import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";

interface CohortDefinition {
  cohort_name: string;
  start_time: number;
  end_time: number;
  current_number: number;
  max_number: number;
  feature_flags: string[]; // Changed to string[]
  chance_to_join: number;
}

const CohortDefinitionsView: React.FC = () => {
  const { getAccessToken } = useUser();
  const [cohorts, setCohorts] = useState<CohortDefinition[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingCohort, setEditingCohort] = useState<CohortDefinition | null>(
    null
  );

  useEffect(() => {
    fetchCohorts();
  }, []);

  const fetchCohorts = async () => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: "/testsuite/cohorts",
        accessToken,
      });
      if (response.ok) {
        setCohorts(response.value.cohorts);
      }
    } catch (error) {
      console.error("Failed to fetch cohorts:", error);
    }
  };

  const handleOpenDialog = (cohort?: CohortDefinition) => {
    setEditingCohort(
      cohort || {
        cohort_name: "",
        start_time: Math.floor(Date.now() / 1000),
        end_time: Math.floor(Date.now() / 1000) + 86400, // 1 day from now
        current_number: 0,
        max_number: 100,
        feature_flags: [], // Initialize as an empty array
        chance_to_join: 0.5,
      }
    );
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingCohort(null);
  };

  const handleSaveCohort = async () => {
    if (!editingCohort) return;

    try {
      const accessToken = await getAccessToken();
      const isNewCohort = !cohorts.some(
        (c) => c.cohort_name === editingCohort.cohort_name
      );
      const method = isNewCohort ? "POST" : "PUT";
      const url = isNewCohort
        ? "/testsuite/cohorts"
        : `/testsuite/cohorts/${editingCohort.cohort_name}`;

      // No need to convert feature flags, they're already a string array

      const cohortData = {
        ...editingCohort,
        // Ensure feature_flags is an array of trimmed, non-empty strings
        feature_flags: editingCohort.feature_flags
          .filter((flag) => flag.trim() !== "")
          .map((flag) => flag.trim()),
      };

      const response = await APIService.makeAPIPostRequest({
        requestString: url,
        accessToken,
        body: cohortData,
      });

      if (response.ok) {
        fetchCohorts();
        handleCloseDialog();
      }
    } catch (error) {
      console.error("Failed to save cohort:", error);
    }
  };

  const handleDeleteCohort = async (cohortName: string) => {
    if (
      window.confirm(
        `Are you sure you want to delete the cohort "${cohortName}"?`
      )
    ) {
      try {
        const accessToken = await getAccessToken();
        const response = await APIService.makeAPIPostRequest({
          requestString: `/testsuite/delete_cohort/${cohortName}`,
          accessToken,
        });

        if (response.ok) {
          fetchCohorts();
        }
      } catch (error) {
        console.error("Failed to delete cohort:", error);
      }
    }
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom>
        Cohort Definitions
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenDialog()}
        sx={{ mb: 2 }}
      >
        Add New Cohort
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Cohort Name</TableCell>
              <TableCell>Start Time</TableCell>
              <TableCell>End Time</TableCell>
              <TableCell>Current / Max</TableCell>
              <TableCell>Chance to Join</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cohorts.map((cohort) => (
              <TableRow key={cohort.cohort_name}>
                <TableCell>{cohort.cohort_name}</TableCell>
                <TableCell>
                  {new Date(cohort.start_time * 1000).toLocaleString()}
                </TableCell>
                <TableCell>
                  {new Date(cohort.end_time * 1000).toLocaleString()}
                </TableCell>
                <TableCell>{`${cohort.current_number} / ${cohort.max_number}`}</TableCell>
                <TableCell>{`${cohort.chance_to_join * 100}%`}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpenDialog(cohort)}>
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDeleteCohort(cohort.cohort_name)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {editingCohort?.cohort_name ? "Edit Cohort" : "Add New Cohort"}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Cohort Name"
            value={editingCohort?.cohort_name || ""}
            onChange={(e) =>
              setEditingCohort({
                ...editingCohort!,
                cohort_name: e.target.value,
              })
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Start Time"
            type="datetime-local"
            value={
              editingCohort
                ? new Date(editingCohort.start_time * 1000)
                    .toISOString()
                    .slice(0, 16)
                : ""
            }
            onChange={(e) =>
              setEditingCohort({
                ...editingCohort!,
                start_time: Math.floor(
                  new Date(e.target.value).getTime() / 1000
                ),
              })
            }
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            fullWidth
            label="End Time"
            type="datetime-local"
            value={
              editingCohort
                ? new Date(editingCohort.end_time * 1000)
                    .toISOString()
                    .slice(0, 16)
                : ""
            }
            onChange={(e) =>
              setEditingCohort({
                ...editingCohort!,
                end_time: Math.floor(new Date(e.target.value).getTime() / 1000),
              })
            }
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            fullWidth
            label="Max Number"
            type="number"
            value={editingCohort?.max_number || ""}
            onChange={(e) =>
              setEditingCohort({
                ...editingCohort!,
                max_number: parseInt(e.target.value),
              })
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Chance to Join"
            type="number"
            inputProps={{ min: 0, max: 1, step: 0.1 }}
            value={editingCohort?.chance_to_join || ""}
            onChange={(e) =>
              setEditingCohort({
                ...editingCohort!,
                chance_to_join: parseFloat(e.target.value),
              })
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Feature Flags"
            value={editingCohort?.feature_flags.join(", ") || ""}
            onChange={(e) =>
              setEditingCohort({
                ...editingCohort!,
                feature_flags: e.target.value
                  .split(",")
                  .map((flag) => flag.trim()),
              })
            }
            margin="normal"
            helperText="Enter feature flags as comma-separated values"
          />
          {/* Add more fields for feature flags if needed */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSaveCohort} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CohortDefinitionsView;
