import React from "react";
import { Box } from "@mui/material";

export const GreenBubble: React.FC = () => (
  <Box
    sx={{
      width: 10,
      height: 10,
      borderRadius: "50%",
      backgroundColor: "success.main",
      mr: 1,
    }}
  />
);

export const RedBubble: React.FC = () => (
  <Box
    sx={{
      width: 10,
      height: 10,
      borderRadius: "50%",
      backgroundColor: "error.main",
      mr: 1,
    }}
  />
);

export const YellowBubble: React.FC = () => (
  <Box
    sx={{
      width: 10,
      height: 10,
      borderRadius: "50%",
      backgroundColor: "warning.main",
      mr: 1,
    }}
  />
);
