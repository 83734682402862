import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import UserPicker from "./UserPicker";
import APIService from "../../../services/APIService";
import { useUser } from "../../../context/user";
import { formatDate } from "../../../utils/utils";

interface User {
  id: string;
  name: string;
}

export interface NoteProcessingSession {
  note_processing_session_id: string;
  user_id: string;
  transcription_session_id: string | string[];
  analysis_session_id?: string;
  template: any;
  user_custom_prompts?: { [key: string]: { [key: string]: string } | string };
  note_title: string;
  note_id: string;
  job_status: string;
  start_timestamp?: number;
  end_timestamp?: number;
  transcription_start_timestamp?: number;
  transcription_end_timestamp?: number;
  errors?: Array<{ [key: string]: any }>;
  error_message?: string;
  processing_tasks?: Array<{
    name: string;
    status: string;
    progress?: number;
  }>;
  app_info?: { [key: string]: any };
  demo_info?: any;
  provided_context_string?: string;
  retry_attempts?: number;
  patient_id?: string;
  encounter_id?: string;
}

interface NoteProcessingSessionPickerProps {
  onSessionSelect: (session: NoteProcessingSession) => void;
}

const NoteProcessingSessionPicker: React.FC<
  NoteProcessingSessionPickerProps
> = ({ onSessionSelect }) => {
  const { getAccessToken } = useUser();
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [sessions, setSessions] = useState<NoteProcessingSession[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    fetchSessions();
  }, [selectedUserId, page, rowsPerPage]);

  const fetchUsers = async () => {
    try {
      const response = await fetch("/listUsers");
      if (!response.ok) {
        throw new Error("Failed to fetch users");
      }
      const data = await response.json();
      setUsers(data.users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchSessions = async () => {
    setLoading(true);
    try {
      const response = await APIService.makeAPIGetRequest({
        requestString: `/noteProcessingSession/list?user_id=${selectedUserId}&page=${
          page + 1
        }&per_page=${rowsPerPage}`,
        accessToken: await getAccessToken(),
      });

      if (response.ok) {
        setSessions(response.value.note_processing_sessions);
        setTotalCount(response.value.total);
      } else {
        throw new Error("Failed to fetch note processing sessions");
      }
    } catch (error) {
      console.error("Error fetching note processing sessions:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUserChange = (user_id: string) => {
    setSelectedUserId(user_id);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSessionClick = (session: NoteProcessingSession) => {
    onSessionSelect(session);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Note Processing Session Picker
      </Typography>

      <UserPicker onUserSelect={handleUserChange} />

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>User ID</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sessions.map((session) => (
                  <TableRow
                    key={session.note_processing_session_id}
                    onClick={() => handleSessionClick(session)}
                    hover
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell>{session.user_id}</TableCell>
                    <TableCell>{session.job_status}</TableCell>
                    <TableCell>
                      {formatDate(session.start_timestamp!)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Container>
  );
};

export default NoteProcessingSessionPicker;
