import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { TextField, Autocomplete, CircularProgress, Typography, Box, ListItem } from '@mui/material';
import APIService from '../../services/APIService';

interface Payer {
  id: string;
  name: string;
  eligibility: string;
}

interface PayerSearchProps {
  onPayerSelect: (payer: Payer | null) => void;
  value: Payer | null;
  disabled?: boolean;
}

const PayerSearch: React.FC<PayerSearchProps> = ({ onPayerSelect, value, disabled = false }) => {
  const [inputValue, setInputValue] = useState('');
  const [payers, setPayers] = useState<Payer[]>([]);
  const [loading, setLoading] = useState(false);
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    const fetchPayers = async () => {
      setLoading(true);
      try {
        const response = await APIService.makeAPIGetRequest({
          requestString: '/billing/payers/search',
          accessToken: accessToken,
        });

        if (response.ok) {
          const payers = response.value.payers.map((payer: Payer) => ({
            id: payer.id,
            name: payer.name,
            eligibility: payer.eligibility,
          }));
          setPayers(payers);
        }
      } catch (error) {
        console.error('Error fetching payers:', error);
      }
      setLoading(false);
    };
    fetchPayers();
  }, [accessToken]);

  const filteredPayers = useMemo(() => {
    if (inputValue.length === 0) return payers;
    return payers.filter((payer) => payer.name.toLowerCase().includes(inputValue.toLowerCase()) || payer.id.toLowerCase().includes(inputValue.toLowerCase()));
  }, [payers, inputValue]);

  const handleInputChange = useCallback((event: React.ChangeEvent<{}>, newInputValue: string) => {
    setInputValue(newInputValue);
  }, []);

  const renderOption = useCallback((props: React.HTMLAttributes<HTMLLIElement>, option: Payer) => {
    return <li {...props}>{option.name} ({option.id})</li>;
  }, []);

  const getOptionLabel = useCallback((option: Payer) => {
    return option.name;
  }, []);

  const isOptionEqualToValue = useCallback((option: Payer, value: Payer) => {
    return option.id === value.id;
  }, []);

  return (
    <Box>
      <Autocomplete
        options={filteredPayers}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        onInputChange={handleInputChange}
        onChange={(event, newValue) => {
          if (newValue) {
            onPayerSelect(newValue);
          }
        }}
        value={value}
        isOptionEqualToValue={isOptionEqualToValue}
        filterOptions={(x) => x}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search Payers"
            variant="outlined"
            disabled={disabled}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        loading={loading}
        loadingText="Searching..."
        noOptionsText="No payers found"
      />
    </Box>
  );
};

export default PayerSearch;