import { FC, createContext, useContext, useState, ReactNode } from "react";
import { Card, SxProps, Theme } from "@mui/material";
import { Header } from "./Header";
import { Body } from "./Body";
import { Extension, ExtensionProps } from "./Extension";

interface CollapsibleContentCardContext {
  isCollapsed: boolean;
  toggleCollapse: () => void;
}

const CardContext = createContext<CollapsibleContentCardContext | undefined>(
  undefined
);

export const useCardContext = () => {
  const context = useContext(CardContext);
  if (!context) {
    throw new Error(
      "Card components must be used within CollapsibleContentCard"
    );
  }
  return context;
};

interface CollapsibleContentCardProps {
  children: ReactNode;
  defaultCollapsed?: boolean;
  sx?: SxProps<Theme>;
}

const CollapsibleContentCard: FC<CollapsibleContentCardProps> & {
  Header: typeof Header;
  Body: typeof Body;
  TopExtension: typeof Extension;
  BottomExtension: typeof Extension;
} = ({ children, defaultCollapsed = false, sx = {} }) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

  return (
    <CardContext.Provider
      value={{
        isCollapsed,
        toggleCollapse: () => setIsCollapsed((prev) => !prev),
      }}
    >
      <Card
        sx={{
          borderRadius: 3,
          bgcolor: "backgroundColors.primary",
          borderColor: "borderColors.secondary",
          border: "0.5px solid",
          boxShadow: "none",
          width: "fit-content",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
          ...sx,
        }}
      >
        {children}
      </Card>
    </CardContext.Provider>
  );
};

CollapsibleContentCard.Header = Header;
CollapsibleContentCard.Body = Body;
CollapsibleContentCard.TopExtension = (props: ExtensionProps) => (
  <Extension {...props} position="top" />
);
CollapsibleContentCard.BottomExtension = (props: ExtensionProps) => (
  <Extension {...props} position="bottom" />
);

export default CollapsibleContentCard;
