/**
 * @file src/components/ViewGroupInvite.tsx
 * @description This file contains the GroupInvite React component - used to display a group invite and allow the user to join the group.
 * 
 * Author: Soham Dave
 * Date: 2024-09-30
 */
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Paper,
  CircularProgress,
  Grid,
  Divider,
  IconButton,
  Snackbar,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import RefreshIcon from "@mui/icons-material/Refresh";
import APIService from "../../services/APIService";
import { getUserInfoFromLocalStorage } from "../../utils/LocalStorageUtils";
import { useUser } from "../../context/user/context";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import EmailVerificationPrompt from "./EmailVerificationPrompt";
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GroupInvite: React.FC = () => {
  const { getAccessToken, userState } = useUser();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [groupData, setGroupData] = useState<any>(null);
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [joinSuccess, setJoinSuccess] = useState<boolean>(false);
  const [joinError, setJoinError] = useState<boolean>(false);
  const [joiningGroup, setJoiningGroup] = useState<boolean>(false);
  const [noInvitesPending, setNoInvitesPending] = useState<boolean>(false);
  const [emailNotVerified, setEmailNotVerified] = useState<boolean>(false);

  useEffect(() => {
    const fetchGroupInvite = async () => {
      if (!userState?.emailVerified) {
        setEmailNotVerified(true);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const accessToken = await getAccessToken();
        const response = await APIService.makeAPIGetRequest({
          requestString: `/group/getGroupInvite`,
          accessToken: accessToken,
        });

        if (response.ok && response.value.data) {
          const data = response.value;
          setGroupData(data.data);
        } else {
          console.error("No pending group invitations found.");
          setNoInvitesPending(true);
        }
      } catch (error) {
        console.error("Error fetching group invite:", error);
        setNoInvitesPending(true);
      } finally {
        setLoading(false);
      }
    };

    fetchGroupInvite();
  }, [userState?.emailVerified]);

  useEffect(() => {
    if (groupData) {
      const expirationDate = new Date(groupData.expires_at * 1000);
      setIsExpired(expirationDate < new Date());
    }
  }, [groupData]);


  const handleJoinGroup = async () => {
    try {
      setJoinError(false);
      setEmailNotVerified(false);
      setJoiningGroup(true);
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: `/group/groupInviteAccept`,
        accessToken: accessToken,
        body: {
          group_id: groupData.group_id, // Use group_id from groupData
        },
      });

      if (response.ok) {
        console.log(response.value);
        if (response.value.status === 200) {
          console.log("Successfully joined the group.");
          setJoinSuccess(true);
        } else if (
          response.value.status === 403 &&
          response.value.message ===
            "Email must be verified to accept group invitations."
        ) {
          console.error("Email not verified.");
          setEmailNotVerified(true);
        } else {
          console.error("Failed to join the group.");
          setJoinError(true);
        }
      } else {
        console.error("Failed to join the group.");
        setJoinError(true);
      }
    } catch (error) {
      console.error("Error joining the group:", error);
      setJoinError(true);
    } finally {
      setJoiningGroup(false);
    }
  };

  const handleGoToHome = () => {
    navigate("/");
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box sx={{ backgroundColor: "#f4f6f8", minHeight: "100vh", pt: 4 }}>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="80vh"
        >
          <CircularProgress />
        </Box>
      ) : emailNotVerified ? (
        <EmailVerificationPrompt
          textToDisplay="You need to verify your email before you can view or accept group invitations."
        />
      ) : noInvitesPending ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="80vh"
        >
          <Typography variant="h6" color="textSecondary" gutterBottom>
            No pending group invitations found.
          </Typography>
          <Button
            onClick={handleGoBack}
            startIcon={<ArrowBackIcon fontSize="small" />}
            sx={{
              textDecoration: "underline",
              color: "primary.main",
              "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
                cursor: "pointer",
              },
            }}
          >
            Go back to previous page
          </Button>
        </Box>
      ) : groupData ? (
        <Box minHeight="80vh">
          <Box justifyContent="center" alignItems="center">
            {joinSuccess && (
              <Alert
                severity="success"
                icon={<CheckCircleIcon />}
                action={
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={handleGoToHome}
                  >
                    <HomeIcon />
                  </IconButton>
                }
                sx={{
                  width: "80%",
                  maxWidth: "500px",
                  margin: "0 auto 20px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" sx={{ flexGrow: 1 }}>
                  You have joined the group successfully!
                </Typography>
              </Alert>
            )}

            {joinError && (
              <Alert
                severity="error"
                icon={<ErrorIcon />}
                action={
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={handleRefresh}
                  >
                    <RefreshIcon />
                  </IconButton>
                }
                sx={{
                  width: "80%",
                  maxWidth: "500px",
                  margin: "0 auto 20px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" sx={{ flexGrow: 1 }}>
                  Error occurred while joining the group.
                </Typography>
              </Alert>
            )}

            {isExpired && (
              <Alert
                severity="warning"
                icon={<WarningIcon />}
                sx={{
                  width: "80%",
                  maxWidth: "500px",
                  margin: "0 auto 20px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" sx={{ flexGrow: 1 }}>
                  This invitation has expired. Please request a new invitation.
                </Typography>
              </Alert>
            )}

            {emailNotVerified && (
              <Alert
                severity="warning"
                icon={<WarningIcon />}
                sx={{
                  width: "80%",
                  maxWidth: "500px",
                  margin: "0 auto 20px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" sx={{ flexGrow: 1 }}>
                  You must verify your email first before joining the group.
                </Typography>
              </Alert>
            )}
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Paper
              sx={{
                p: 4,
                width: "500px",
                borderRadius: "10px",
                boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography
                variant="h4"
                gutterBottom
                align="center"
                sx={{ fontWeight: "bold", color: "#3f51b5" }}
              >
                Group Invitation
              </Typography>
              <Typography
                variant="body1"
                align="center"
                sx={{ mb: 1, color: "textSecondary" }}
              >
                You have been invited to join the group
              </Typography>
              <Typography
                variant="h6"
                align="center"
                sx={{ mb: 4, color: "textSecondary" }}
              >
                {groupData.group_name}
              </Typography>

              <Divider sx={{ mb: 2 }} />

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    Group Name:
                  </Typography>
                  <Typography variant="body2">
                    {groupData.group_name}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    Invited By:
                  </Typography>
                  <Typography variant="body2">
                    {groupData.billing_admin_user_name} (
                    {groupData.billing_admin_user_email})
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    Role Assigned:
                  </Typography>
                  <Typography variant="body2">
                    {groupData.role_to_be_assigned}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      color: isExpired ? "error.main" : "inherit",
                    }}
                  >
                    {isExpired ? "Invitation Expired:" : "Invitation Expires:"}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: isExpired ? "error.main" : "inherit" }}
                  >
                    {new Date(groupData.expires_at * 1000).toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>

              <Divider sx={{ my: 3 }} />

              <Box display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleJoinGroup}
                  disabled={
                    isExpired || joinSuccess || joinError || joiningGroup
                  }
                  sx={{
                    textTransform: "none",
                    borderRadius: "25px",
                    px: 4,
                    opacity:
                      isExpired || joinSuccess || joinError || joiningGroup
                        ? 0.5
                        : 1,
                    minWidth: "150px", // Ensure consistent button width
                  }}
                >
                  {joiningGroup ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : joinSuccess ? (
                    "Joined"
                  ) : (
                    "Join Group"
                  )}
                </Button>
              </Box>
            </Paper>
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="80vh"
        >
          <Typography variant="h6" color="error" gutterBottom>
            Invitation not found or expired.
          </Typography>
          <Button
            onClick={handleGoBack}
            startIcon={<ArrowBackIcon fontSize="small" />}
            sx={{
              textDecoration: "underline",
              color: "primary.main",
              "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
                cursor: "pointer",
              },
            }}
          >
            Go back to previous page
          </Button>
        </Box>
      )}

    </Box>
  );
};

export default GroupInvite;
