// Add this type declaration at the top of the file
declare global {
  interface Window {
    gtag?: (...args: any[]) => void;
    fbq?: (...args: any[]) => void;
  }
}

interface GtagEvent {
  event: string;
  platform?: string;
  [key: string]: any;
}

export const trackEvent = (event: GtagEvent): void => {
  const win: Window = window;

  // Google Analytics
  if (win.gtag) {
    win.gtag("event", event.event, {
      platform: event.platform,
      ...event,
    });
  }

  // Facebook Pixel
  if (win.fbq) {
    //@ts-ignore
    win.fbq("track", event.event, {
      eventName: event.event,
      platform: event.platform,
      ...event,
    });
  }

  // Google Ads conversion tracking
  if (event.event === "WebApp_SignupConfirm") {
    if (win.gtag) {
      //@ts-ignore
      win.gtag("event", "conversion", {
        send_to: "AW-11416945570/JtXkCLqC-roZEKKHg8Qq",
      });
    }
  }
};
