import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Tooltip,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import Logo from "../Icons/Logo";
import { AvailableModule } from "../../types/types";
import { HoverContent } from "../Shared/HoverContent";
import { Visibility } from "@mui/icons-material";
import { useThemeContext } from "../../context/theme/context";
import { Avatar } from "@mui/material";
import { getUserInfoFromLocalStorage } from "../../utils/LocalStorageUtils";

interface NewModuleBlockProps {
  module: AvailableModule;
  handleClick?: () => void;
}

const NewModuleBlock = ({ module, handleClick }: NewModuleBlockProps) => {
  const [showDetails, setShowDetails] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const { brandLanguage } = useThemeContext();
  const userInfo = getUserInfoFromLocalStorage();

  return (
    <Card
      sx={{
        my: 1.5,
        borderColor: "borderColors.primary",
        border: "0.5px solid",
        borderRadius: "0.4cm",
        "&:hover": {
          boxShadow: 3,
        },
      }}
      onClick={(e) => {
        if (!e.defaultPrevented) {
          setShowPreview(false);
        }
      }}
    >
      <CardContent
        sx={{ py: 1, "&.MuiCardContent-root:last-child": { pb: 1 } }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box
            id="module-text"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              flexGrow: 1,
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography
                fontStyle="normal"
                fontWeight="700"
                sx={{
                  cursor: "pointer",
                  display: "inline-block",
                }}
              >
                {module.name}
              </Typography>
              {showDetails && (
                <Tooltip title="Preview" placement="top" arrow>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPreview(!showPreview);
                    }}
                    size="small"
                  >
                    <Visibility fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            {showDetails && (
              <>
                <Typography
                  fontSize=".8rem"
                  fontWeight="300"
                  sx={{ color: "text.secondary" }}
                >
                  {module.description}
                </Typography>
                <HoverContent
                  title={module.name ?? ""}
                  content={module?.example || "No example provided."}
                  sx={{
                    opacity: showPreview ? 1 : undefined,
                    visibility: showPreview ? "visible" : "hidden",
                  }}
                />
              </>
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            {module.is_custom_user_module ? (
              <Tooltip
                title="This is your customized section."
                placement="top"
                arrow
              >
                <Avatar
                  variant="circular"
                  src={userInfo?.img}
                  alt="user photo"
                  sx={{ width: 25, height: 25 }}
                />
              </Tooltip>
            ) : (
              <Tooltip
                title={`This is a ${brandLanguage.brandName} section.`}
                placement="top"
                arrow
              >
                <Box>
                  <Logo size={22} />
                </Box>
              </Tooltip>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleClick}
              size="small"
              sx={{
                borderRadius: 2,
                px: 2,
              }}
            >
              Add
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setShowDetails(!showDetails);
            }}
            size="small"
          >
            {showDetails ? "Show less" : "Learn more"}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default NewModuleBlock;
