import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardContent,
  SxProps,
  Theme,
  Typography,
  Box,
} from "@mui/material";

interface ContentCardProps {
  content: React.ReactNode;
  sx?: SxProps<Theme>;
  icon: React.ReactNode;
  title: string;
  extension?: React.ReactNode;
  hideContent?: boolean;
}

export const ContentCard: React.FC<ContentCardProps> = ({
  content,
  sx = {},
  icon,
  title,
  extension,
  hideContent = false,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <Card
      sx={{
        borderRadius: 3,
        bgcolor: "backgroundColors.primary",
        borderColor: "borderColors.secondary",
        border: "0.5px solid",
        boxShadow: "none",
        width: "fit-content",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        ...sx,
      }}
    >
      {extension}
      <CardContent
        ref={contentRef}
        sx={{
          pb: 0,
          display: "flex",
          flexDirection: "column",
          maxHeight: hideContent ? 0 : "500px",
          opacity: hideContent ? 0 : 1,
          transform: hideContent ? "translateY(-30px)" : "translateY(0)",
          transition: `
            max-height 0.4s cubic-bezier(0.4, 0, 0.2, 1),
            opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1),
            transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
            padding 0.4s cubic-bezier(0.4, 0, 0.2, 1)
          `,
          overflow: "hidden",
          "&:last-child": { pb: hideContent ? 0 : 2 },
          padding: hideContent ? "0 16px" : undefined,
          margin: hideContent ? 0 : undefined,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
            color: "newDesignColors.eggplant700",
            display: "flex",
            alignItems: "center",
            mb: 2,
          }}
        >
          {icon}
          {title}
        </Typography>
        <Box
          sx={{
            overflowY: "auto",
            pr: 1,
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#bbb",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#999",
            },
          }}
        >
          {content}
        </Box>
      </CardContent>
    </Card>
  );
};
