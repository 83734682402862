import { FC, ReactNode } from "react";
import { Box, Typography, IconButton, Skeleton } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useCardContext } from "./CollapsibleContentCard";

interface HeaderProps {
  icon?: ReactNode;
  title: string;
  subtitle?: string | null;
  action?: ReactNode;
  loading?: boolean;
  onTitleClick?: () => void;
}

export const Header: FC<HeaderProps> = ({
  icon,
  title,
  subtitle,
  action,
  loading = false,
  onTitleClick,
}) => {
  const { isCollapsed, toggleCollapse } = useCardContext();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        p: 2,
        borderBottom: isCollapsed ? "none" : "0.5px solid",
        borderColor: "borderColors.secondary",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {icon && <Box sx={{ mr: 2 }}>{icon}</Box>}
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
            color: "newDesignColors.eggplant700",
            cursor: onTitleClick ? "pointer" : "default",
            "&:hover": {
              textDecoration: onTitleClick ? "underline" : "none",
            },
          }}
          onClick={onTitleClick}
        >
          {title}
        </Typography>
        {loading ? (
          <Skeleton width={150} height={20} />
        ) : (
          subtitle && (
            <Typography
              variant="body2"
              sx={{
                color: "newDesignColors.eggplant500",
              }}
            >
              {subtitle}
            </Typography>
          )
        )}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {action}
        <IconButton onClick={toggleCollapse} size="small">
          {isCollapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </IconButton>
      </Box>
    </Box>
  );
};
