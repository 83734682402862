import { useNavigate } from "react-router-dom";
import { AdminNote } from "../../types/types";
import { formatDate } from "../../utils/utils";

interface NoteRowProps {
  note: AdminNote;
}

export const NoteRow = ({ note }: NoteRowProps) => {
  const navigate = useNavigate();

  return (
    <tr
      className={`hover:bg-blue-100 cursor-pointer`}
      onClick={() => {
        navigate(`/admin/note/${note.note_id}`);
      }}
    >
      <td className="px-4 py-2 border-2 border-gray-400">{note.note_id}</td>
      <td className="px-4 py-2 border-2 border-gray-400">{note.status}</td>
      <td className="px-4 py-2 border-2 border-gray-400">
        {note.issuing_user}
      </td>
      <td className="px-4 py-2 border-2 border-gray-400">
        {formatDate(note.time_uploaded)}
      </td>
      <td className="px-4 py-2 border-2 border-gray-400">
        {note.has_feedback ? "True" : ""}
      </td>
    </tr>
  );
};
