// globalStyles.ts
export const modifiedScrollBar = {
  height: "100%",
  overflow: "auto",
  paddingRight: "10px", // Add right padding
  // Styles for WebKit browsers (Chrome, Safari, newer versions of Edge)
  "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: "5px",
    border: "2px solid transparent",
    backgroundClip: "padding-box",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  // Styles for Firefox
  scrollbarWidth: "thin",
  scrollbarColor: "rgba(0, 0, 0, 0.2) transparent",
  // Styles for Internet Explorer and legacy Edge
  "-ms-overflow-style": "none",
  // Custom property for JavaScript detection
  "--scrollbar-width": "10px",
};

export const removeScrollBar = {
  height: "100%",
  overflow: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "-ms-overflow-style": "none",
  "scrollbar-width": "none",
};

const boxStyle = {
  display: "flex",
  flexDirection: "column",
  padding: 2,
  color: "textColors.brand",
  height: "200px",
};
