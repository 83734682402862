import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Card,
  CardContent,
  CircularProgress,
  Button,
} from "@mui/material";
import { useUser } from "../../context/user";
import APIService from "../../services/APIService";

interface PDFUploadSession {
  _id: { $oid: string };
  pdf_generation_session_id: string;
  issuing_user: string;
  s3_object_id: string;
  start_timestamp: number;
  end_timestamp: number;
  status: string;
  errors: any[];
}

interface SimplifiedPDFUpload {
  issuingUser: string;
  fileName: string;
  uploadTime: Date;
  sessionId: string;
}

const UserUploadedTemplates: React.FC = () => {
  const { getAccessToken } = useUser();
  const [pdfUploads, setPdfUploads] = useState<SimplifiedPDFUpload[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchPDFUploads = async () => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: "/pdfUploads/recent",
        accessToken: accessToken,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch PDF uploads");
      }

      const result: PDFUploadSession[] = response.value.pdf_uploads;
      const simplifiedUploads: SimplifiedPDFUpload[] = result.map((upload) => ({
        issuingUser: upload.issuing_user,
        fileName: upload.s3_object_id.split("/").pop() || "Unknown",
        uploadTime: new Date(upload.start_timestamp * 1000),
        sessionId: upload.pdf_generation_session_id,
      }));
      setPdfUploads(simplifiedUploads);
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPDFUploads();
  }, []);

  const handleDownload = async (sessionId: string) => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/pdfUploads/download?session_id=${sessionId}`,
        accessToken: accessToken,
      });

      if (!response.ok) {
        throw new Error("Failed to get download URL");
      }

      const downloadUrl = response.value.download_url;
      window.open(downloadUrl, "_blank");
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "An error occurred while downloading"
      );
    }
  };

  const renderContent = () => {
    if (loading) {
      return <CircularProgress />;
    }

    if (error) {
      return <Typography color="error">{error}</Typography>;
    }

    return (
      <>
        {pdfUploads.map((upload, index) => (
          <Card key={index} variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {upload.fileName}
              </Typography>
              <Typography variant="body2">
                <strong>Issuing User:</strong> {upload.issuingUser}
              </Typography>
              <Typography variant="body2">
                <strong>Upload Time:</strong>{" "}
                {upload.uploadTime.toLocaleString()}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleDownload(upload.sessionId)}
                sx={{ mt: 1 }}
              >
                Download PDF
              </Button>
            </CardContent>
          </Card>
        ))}
      </>
    );
  };

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            User Uploaded Templates
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
        {renderContent()}
      </Container>
    </>
  );
};

export default UserUploadedTemplates;
