import React, { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Typography,
  Button,
  SelectChangeEvent,
  useTheme,
  CircularProgress,
  Checkbox,
  ListItemText,
  Stack,
} from "@mui/material";
import { useUser } from "../context/user";
import APIService from "../services/APIService";
import { RoundedButton } from "../styles/CustomButtons";

export const assemblyLanguageOptions = {
  en: "English",
  es: "Spanish",
  fr: "French",
  de: "German",
  it: "Italian",
  pt: "Portuguese",
  nl: "Dutch",
  hi: "Hindi",
  ja: "Japanese",
  zh: "Chinese",
  fi: "Finnish",
  ko: "Korean",
  pl: "Polish",
  ru: "Russian",
  tr: "Turkish",
  uk: "Ukrainian",
  vi: "Vietnamese",
};

export const deepgramLanguageOptions = {
  en: "English",
  es: "Spanish",
  fr: "French",
  de: "German",
  it: "Italian",
  pt: "Portuguese",
  zh: "Chinese",
  hi: "Hindi",
  ja: "Japanese",
  ru: "Russian",
  uk: "Ukranian",
  sv: "Swedish",
  nl: "Dutch",
  tr: "Turkish",
  id: "Indonesian",
  ko: "Korean",
};

interface LanguageAndNotePreferencesFormProps {
  onSuccessfulFormSubmission: (subjectLanguage: string) => void;
}

const LanguageAndNotePreferencesForm = ({
  onSuccessfulFormSubmission,
}: LanguageAndNotePreferencesFormProps) => {
  const theme = useTheme(); // Access the theme
  const { getAccessToken, userState } = useUser();

  const [languages, setLanguages] = useState<string[]>(["en"]);
  const [subjectLanguage, setSubjectLanguage] = useState<string>("patient");
  const [loading, setLoading] = useState<boolean>(false);

  let languageNames = userState?.featureFlags?.deepgram
    ? deepgramLanguageOptions
    : assemblyLanguageOptions;

  const handleLanguageChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    setLanguages(typeof value === "string" ? value.split(",") : value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    const accessToken = await getAccessToken();

    if (!accessToken) {
      setLoading(false);
      return;
    }

    const result = await APIService.makeAPIPostRequest({
      requestString: "/user/languageAndPreferencesForm",
      accessToken: accessToken,
      body: {
        languages: languages,
        subject_language: subjectLanguage,
      },
    });

    if (result.ok) {
      onSuccessfulFormSubmission(subjectLanguage);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Typography
          variant="h4"
          gutterBottom
          component="div"
          fontWeight={700}
          align="center"
          sx={{ mb: 4 }}
        >
          Basic Settings
        </Typography>

        <FormControl fullWidth margin="normal">
          <Typography variant="body1" sx={{ pb: 1 }}>
            Which languages do you normally use during encounters?
          </Typography>
          <Select
            labelId="language-select-label"
            id="language-select"
            multiple
            value={languages}
            onChange={handleLanguageChange}
            renderValue={(selected) =>
              selected
                .map(
                  (code) => languageNames[code as keyof typeof languageNames]
                )
                .join(", ")
            }
          >
            {Object.entries(languageNames).map(([code, name]) => (
              <MenuItem key={code} value={code}>
                <Checkbox checked={languages.indexOf(code) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl component="fieldset" margin="normal">
          <Typography variant="body1" sx={{ pb: 1 }}>
            What word do you typically use to refer to the subject of your
            encounters?
          </Typography>
          <RadioGroup
            aria-label="style"
            name="style"
            value={subjectLanguage}
            onChange={(e) => setSubjectLanguage(e.target.value)}
            row
          >
            <FormControlLabel
              value="patient"
              control={<Radio />}
              label="Patient"
            />
            <FormControlLabel
              value="client"
              control={<Radio />}
              label="Client"
            />
          </RadioGroup>
        </FormControl>
        <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
          <RoundedButton
            type="submit"
            variant="contained"
            color="primary"
            sx={{ px: 4, py: 1.5 }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <Typography variant="body1" fontWeight={700}>
                Next
              </Typography>
            )}
          </RoundedButton>
        </Stack>
      </Box>
    </Box>
  );
};

export default LanguageAndNotePreferencesForm;
