/**
 * @file src/web-app/src/components/Shared/ManageCustomSections.tsx
 * @description This file contains the ManageCustomSections React component: It is used to show all customized sections for the user.
 *
 * Author: Soham Dave
 * Date: 2024-10-03
 */

import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Card,
  CardContent,
  Snackbar,
  Alert,
} from "@mui/material";
import { DeleteOutlined as DeleteIcon } from "@mui/icons-material";
import { AvailableModule } from "../../types/types";
import { HoverContent } from "./HoverContent";
import { BoxColumn } from "../layout/BoxColumn";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user/context";

interface ManageCustomSectionsProps {
  availableModules: AvailableModule[] | undefined;
  setAvailableModules: React.Dispatch<
    React.SetStateAction<AvailableModule[] | undefined>
  >;
}

const ManageCustomSections: React.FC<ManageCustomSectionsProps> = ({
  availableModules,
  setAvailableModules,
}) => {
  const { getAccessToken } = useUser();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });

  const onDeleteModule = async (moduleId: string) => {
    const accessToken = await getAccessToken();
    if (!accessToken) {
      console.error("Access token not found");
      setSnackbar({
        open: true,
        message: "Access token not found",
        severity: "error",
      });
      return;
    }
    try {
      const response = await APIService.makeAPIPostRequest({
        requestString: "/template/deactivateCustomModule",
        accessToken: accessToken,
        body: {
          custom_module_id: moduleId,
        },
      });

      if (response.ok) {
        console.log("Module deactivated successfully");
        setAvailableModules((prevModules) =>
          prevModules?.map((module) =>
            module.module_id === moduleId
              ? { ...module, deactivated: true }
              : module
          )
        );
        setSnackbar({
          open: true,
          message: "Custom section deleted successfully",
          severity: "success",
        });
      } else {
        console.error("Failed to deactivate module");
        setSnackbar({
          open: true,
          message: "Failed to delete custom section",
          severity: "error",
        });
      }
    } catch (error) {
      console.error("Error deactivating module", error);
      setSnackbar({
        open: true,
        message: "Error deleting custom section",
        severity: "error",
      });
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <Box
        className="flex-1"
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <BoxColumn flex={1}>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              padding: 2,
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Box
              id="scroll box"
              sx={{
                width: "95%",
                height: "100%",
                margin: "0 auto",
                position: "relative",
              }}
            >
              <Typography variant="h4" fontWeight="bold">
                Manage Customized Sections
              </Typography>
              <Typography variant="body2">
                Customized sections are sections that have been modified by the
                AI editor. You can remove them from your library and it won't
                affect your notes or existing templates.
              </Typography>
              <Box sx={{ py: 2 }}>
                {availableModules &&
                  availableModules
                    .filter(
                      (module) =>
                        module.is_custom_user_module &&
                        (!module.deactivated || module.deactivated !== true)
                    )
                    .map((module, index) => (
                      <Card
                        key={index}
                        sx={{
                          my: 1.5,
                          borderColor: "borderColors.primary",
                          border: "0.5px solid",
                          borderRadius: "0.4cm",
                          "&:hover": {
                            boxShadow: 3, // Adjust shadow on hover
                          },
                        }}
                      >
                        <CardContent sx={{}}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <Box
                              id="module-text"
                              sx={{
                                flexDirection: "column",
                                justifyContent: "center",
                                flexGrow: 1,
                              }}
                            >
                              <Typography
                                fontStyle="normal"
                                fontWeight="700"
                                sx={{
                                  cursor: module.example
                                    ? "pointer"
                                    : "default",
                                  display: "block",
                                  "&:hover + .hover-content": {
                                    cursor: module.example
                                      ? "pointer"
                                      : "default",
                                    display: "block",
                                    opacity: 1,
                                    visibility: "visible",
                                    transform: "translateY(0%)",
                                  },
                                }}
                              >
                                {module.name}
                              </Typography>
                              {/* Hover content */}
                              {module && module.example && (
                                <HoverContent
                                  title={module.name || ""}
                                  content={module.example || ""}
                                />
                              )}
                              <Typography fontSize=".8rem" fontWeight="300">
                                {module.description}
                              </Typography>
                            </Box>
                            <Box>
                              <IconButton
                                onClick={() => onDeleteModule(module.module_id)}
                                sx={{
                                  color: "error.main",
                                  alignSelf: "center",
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    ))}
              </Box>
            </Box>
          </Box>
        </BoxColumn>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ManageCustomSections;
