import { useEffect, useRef, useState } from "react";
import { json, Params, useLoaderData, useParams } from "react-router-dom";
import { NoteSection } from "../components/NoteSection";
import { Sidebar } from "../components/layout/Sidebar";
import { SVGIcon } from "../components/SVGIcon";
import dummydata from "../dummydata/data.json";
import { UserNote } from "../components/UserNote";
import { MainContainer } from "../components/layout/MainContainer";
import { UserNotesList } from "../components/UserNotesList";
import { UserNoteTypeII } from "../types/types";
import {
  ArchivedNotesLoader,
  ForReviewNotesLoader,
} from "../loaders/NotesLoader";
import { UnauthorizedError } from "../components/errors/UnathorizedError";
import { getUserInfoFromLocalStorage } from "../utils/LocalStorageUtils";
import { UserInfoForm } from "../components/UserInfoForm";
import { Box } from "@mui/material";
import APIService from "../services/APIService";
import { useUser } from "../context/user";
import { GroupNotesList } from "../components/GroupNotesList";

export type UserNotesDataType = UserNoteTypeII[];

function GroupNotesView() {
  return <GroupNotesList />;
}

export default GroupNotesView;
