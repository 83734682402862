import { useEffect, useState, useCallback } from "react";
import React from "react";
import {
  Box,
  Typography,
  Skeleton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Snackbar,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  IconButton,
  Tooltip,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { styled } from "@mui/material/styles";
import MultiColumnCenterBox from "../layouts/MultiColumnCenterBox";
import { BoxColumn } from "../components/layout/BoxColumn";
import { CreateGroupForm } from "../components/CreateGroupForm";
import { useUser } from "../context/user";
import {
  AdminGroup,
  GroupPendingInvitation,
  PendingGroupInvitation,
} from "../types/types";
import { GroupDetailsLoader } from "../loaders/GroupDetailsLoader";
import { UserGroupDetailsRow } from "../components/Admin/UserGroupDetailsRow";
import { getUserInfoFromLocalStorage } from "../utils/LocalStorageUtils";
import APIService from "../services/APIService";
import { ErrorPage } from "./Shared/ErrorPage";
import StartGroupSection from "../components/Shared/StartGroupSection";
import { Refresh as RefreshIcon } from '@mui/icons-material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

// Update PendingInvitationRow component
export const PendingInvitationRow: React.FC<{
  invitation: PendingGroupInvitation;
  onRemove: (secretCode: string) => void;
  onResend: (secretCode: string) => void;
  status: string;
}> = ({ invitation, onRemove, onResend, status }) => {
  const isExpired = status === "Expired";

  return (
    <tr>
      <td style={{ padding: "10px 15px", border: "none", textAlign: "left" }}>
        <Button
          onClick={() => onRemove(invitation.secret_code)}
          color="secondary"
          style={{
            minWidth: "auto",
            padding: "0",
            color: "black",
            textDecoration: "none",
            cursor: "pointer",
            fontWeight: "bold",
            marginRight: "10px",
          }}
          onMouseOver={(e) =>
            (e.currentTarget.style.textDecoration = "underline")
          }
          onMouseOut={(e) => (e.currentTarget.style.textDecoration = "none")}
        >
          Remove
        </Button>
        {isExpired && (
          <Button
            onClick={() => onResend(invitation.secret_code)}
            color="primary"
            style={{
              minWidth: "auto",
              padding: "0",
              textDecoration: "none",
              cursor: "pointer",
              fontWeight: "bold",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.textDecoration = "underline")
            }
            onMouseOut={(e) => (e.currentTarget.style.textDecoration = "none")}
          >
            Resend
          </Button>
        )}
      </td>
      <td style={{ padding: "10px 15px", border: "none" }}></td>
      <td style={{ padding: "10px 15px", border: "none" }}>
        <i>{invitation.email}</i>
      </td>
      <td style={{ padding: "10px 15px", border: "none" }}></td>
      <td style={{ padding: "10px 15px", border: "none" }}>
        <i>{invitation.role === "member" ? "Provider" : "View Only"}</i>
      </td>
      <td style={{ padding: "10px 15px", border: "none" }}>
        <i>{status}</i>
      </td>
    </tr>
  );
};

const InviteUserModal: React.FC<{
  isInviteModalOpen: boolean;
  setInviteModalOpen: (open: boolean) => void;
  inviteEmail: string;
  setInviteEmail: (email: string) => void;
  inviteRole: string;
  setInviteRole: (role: string) => void;
  handleInvite: () => Promise<void>;
}> = ({
  isInviteModalOpen,
  setInviteModalOpen,
  inviteEmail,
  setInviteEmail,
  inviteRole,
  setInviteRole,
  handleInvite,
}) => {
  const handleClose = () => {
    setInviteModalOpen(false);
  };

  return (
    <Dialog
      open={isInviteModalOpen}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Invite New User</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
          />
          <FormControl fullWidth variant="outlined">
            <InputLabel id="role-select-label">Role</InputLabel>
            <Select
              labelId="role-select-label"
              id="role"
              value={inviteRole}
              onChange={(e) => setInviteRole(e.target.value)}
              label="Role"
            >
              <MenuItem value="member">Provider</MenuItem>
              <MenuItem value="admin">View Only</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleInvite} color="primary" variant="contained">
          Send Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const UserGroupAdministration: React.FC = () => {
  const { getAccessToken } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [groupDetails, setGroupDetails] = useState<AdminGroup | null>(null);
  const [groupId, setGroupId] = useState("");
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteRole, setInviteRole] = useState("member");
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const { userState } = useUser();
  const userInfo = getUserInfoFromLocalStorage();
  const [pendingInvites, setPendingInvites] = useState<
    PendingGroupInvitation[]
  >([]);
  const [newGroupSectionOpen, setNewGroupSectionOpen] = useState<boolean>(true);

  const [toastOpen, setToastOpen] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");

  const loadGroupDetails = useCallback(
    async (groupId: string) => {
      try {
        const details = await GroupDetailsLoader({ params: { groupId } });
        setGroupDetails(details[0]);
        setIsLoading(false);

        // Load pending invites
        const accessToken = await getAccessToken();
        const response = await APIService.makeAPIGetRequest({
          requestString: "/group/getAllPendingInvites",
          accessToken: accessToken,
        });

        if (response.ok) {
          const data = await response.value;
          setPendingInvites(data.pending_invites);
        } else {
          throw new Error("Failed to load pending invites");
        }
      } catch (e: any) {
        setError(e.message);
        console.error("Failed to load group details or pending invites:", e);
      }
    },
    [getAccessToken]
  );

  const resendPendingInvitation = useCallback(
    async (secretCode: string) => {
      try {
        const accessToken = await getAccessToken();
        const response = await APIService.makeAPIPostRequest({
          requestString: "/group/resendPendingInvite",
          accessToken: accessToken,
          body: { secret_code: secretCode },
        });

        if (response.ok) {
          // Refresh the pending invites list
          loadGroupDetails(groupDetails?.group_id || "");
        } else {
          throw new Error("Failed to resend invitation");
        }
      } catch (e: any) {
        setError(e.message);
        console.error("Failed to resend invitation:", e);
      }
    },
    [getAccessToken, groupDetails?.group_id, loadGroupDetails]
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refreshParam = urlParams.get('refresh');
  
    if (refreshParam === 'true') {
      urlParams.delete('refresh');
      const newUrl = urlParams.toString() ? `${window.location.pathname}?${urlParams.toString()}` : window.location.pathname;
      window.history.replaceState({}, '', newUrl);
      window.location.reload(); 
    }
  }, []);
  

  useEffect(() => {
    const groupIds = userState?.group_ids ?? [];
    setGroupId(groupIds[0]);
    if (groupIds.length > 0) {
      loadGroupDetails(groupIds[0]);
    }
  }, [userState, loadGroupDetails]);

  useEffect(() => {
    if (groupId) {
      loadGroupDetails(groupId);
    }
  }, [groupId, loadGroupDetails]);

  const handleUserSelect = (userId: string) => {
    setSelectedUserId((prevUserId) => (prevUserId === userId ? null : userId));
  };

  const handleInvite = async () => {
    setInviteEmail("");
    setInviteModalOpen(false);

    try {
      const accessToken = await getAccessToken();

      const response = await APIService.makeAPIPostRequest({
        requestString: "/group/addUserByEmail",
        accessToken: accessToken,
        body: {
          email: inviteEmail,
          group_id: groupId,
          role: inviteRole,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to add user to group");
      }

      await loadGroupDetails(groupId);
      setSelectedUserId(null);
    } catch (error) {
      console.error("Failed to add user:", error);
      setError("Failed to add user to group");
    }
  };

  const removeUserFromGroup = async () => {
    if (!selectedUserId || !groupId) return;

    try {
      const accessToken = await getAccessToken();

      const response = await APIService.makeAPIPostRequest({
        requestString: "/group/removeUserFromGroup",
        accessToken: accessToken,
        body: {
          user_id: selectedUserId,
          group_id: groupId,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to remove user from group");
      }

      await loadGroupDetails(groupId);
      setSelectedUserId(null);
    } catch (error) {
      console.error("Failed to remove user:", error);
      setError("Failed to remove user from group");
    }
  };

  const removePendingInvitation = async (secretCode: string) => {
    try {
      const accessToken = await getAccessToken();

      const response = await APIService.makeAPIPostRequest({
        requestString: "/group/removePendingInvite",
        accessToken: accessToken,
        body: {
          secret_code: secretCode,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to remove pending invitation");
      }

      // Refresh the group details to update the pending invitations list
      await loadGroupDetails(groupId);
      
      // Show a success message
      setToastMessage("Pending invitation removed successfully");
      setToastOpen(true);
    } catch (error) {
      console.error("Failed to remove pending invitation:", error);
      setError("Failed to remove pending invitation");
      
      // Show an error message
      setToastMessage("Failed to remove pending invitation");
      setToastOpen(true);
    }
  };

  const changeUserRole = async (userId: string, newRole: string) => {
    try {
      const accessToken = await getAccessToken();

      const response = await APIService.makeAPIPostRequest({
        requestString: "/group/changeUserRole",
        accessToken: accessToken,
        body: {
          user_id: userId,
          group_id: groupId,
          new_role: newRole,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to change user role");
      }

      await loadGroupDetails(groupId);
    } catch (error) {
      console.error("Failed to change user role:", error);
      setError("Failed to change user role");
    }
  };

  const userIsBillingAdmin =
    userState?.group_user_roles?.includes("billing_admin");
  const userIsInGroup = (userState?.group_ids ?? []).length > 0;

  const handleGroupCreated = async (groupId: string) => {
    setGroupId(groupId);
    await loadGroupDetails(groupId);
    window.location.reload();
  };

  const getInvitationStatus = (expiresAt: number | undefined): string => {
    if (!expiresAt) return "Pending";
    return Date.now() > expiresAt * 1000 ? "Expired" : "Pending";
  };

  if (isLoading)
    return <Skeleton variant="rectangular" width="100%" height="100%" />;
  if (error) return <ErrorPage error_message={error} />;

  return (
    <Box sx={{ p: 3, maxWidth: 1200, margin: "auto" }}>
      <Typography variant="h4" gutterBottom>
        Group Administration
      </Typography>
      
      {userIsBillingAdmin ? (
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h5" gutterBottom>
            {groupDetails?.group_info?.name}
          </Typography>
          
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
            <Chip
              label={`${groupDetails?.members?.length ?? 0} active subscription${groupDetails?.members?.length !== 1 ? 's' : ''}`}
              color="primary"
            />
            <Box>
              <Button
                variant="contained"
                startIcon={<ReceiptIcon />}
                onClick={() => window.open(`https://billing.stripe.com/p/login/7sIdRo5NjdyN1QA8ww?prefilled_email=${encodeURIComponent(userInfo?.email || "")}`, "_blank")}
                sx={{ mr: 1 }}
              >
                Billing Portal
              </Button>
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={removeUserFromGroup}
                disabled={!selectedUserId}
              >
                Remove User
              </Button>
              <Button
                variant="contained"
                color="success"
                startIcon={<PersonAddIcon />}
                onClick={() => setInviteModalOpen(true)}
                sx={{ ml: 1 }}
              >
                Invite User
              </Button>
            </Box>
          </Box>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell padding="checkbox"></StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell align="right">Total Notes</StyledTableCell>
                  <StyledTableCell>Role</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[...(groupDetails?.admins ?? []), ...(groupDetails?.members ?? [])].map((user) => (
                  <StyledTableRow key={user.user_id} selected={user.user_id === selectedUserId}>
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        checked={user.user_id === selectedUserId}
                        onChange={() => handleUserSelect(user.user_id)}
                      />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {user?.user_info?.name}
                    </StyledTableCell>
                    <StyledTableCell>{user?.user_info?.email}</StyledTableCell>
                    <StyledTableCell align="right">{user?.user_metrics?.notes_created}</StyledTableCell>
                    <StyledTableCell>
                      <Select
                        value={user.role}
                        onChange={(e) => changeUserRole(user.user_id, e.target.value)}
                        size="small"
                      >
                        <MenuItem value="member">Provider</MenuItem>
                        <MenuItem value="admin">View Only</MenuItem>
                      </Select>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Chip label="Active" color="success" size="small" />
                    </StyledTableCell>
                    <StyledTableCell>
                      {/* Empty cell for consistency */}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                {pendingInvites.map((invitation) => {
                  const isExpired = getInvitationStatus(invitation.expires_at) === "Expired";
                  return (
                    <StyledTableRow key={invitation.email}>
                      <StyledTableCell padding="checkbox"></StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        Pending Invitation
                      </StyledTableCell>
                      <StyledTableCell>{invitation.email}</StyledTableCell>
                      <StyledTableCell align="right">-</StyledTableCell>
                      <StyledTableCell>
                        <Chip
                          label={invitation.role === "member" ? "Provider" : "View Only"}
                          color={invitation.role === "member" ? "primary" : "secondary"}
                          size="small"
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Chip
                          label={isExpired ? "Expired" : "Pending"}
                          color={isExpired ? "error" : "warning"}
                          size="small"
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <Tooltip title="Remove Invitation">
                            <IconButton
                              size="small"
                              onClick={() => removePendingInvitation(invitation.secret_code)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          {isExpired && (
                            <Tooltip title="Resend Invitation">
                              <IconButton
                                size="small"
                                onClick={() => resendPendingInvitation(invitation.secret_code)}
                              >
                                <RefreshIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <StartGroupSection
          setToastOpen={setToastOpen}
          setToastMessage={setToastMessage}
          setShowShareSection={() => {}}
        />
      )}
      

      <InviteUserModal
        isInviteModalOpen={isInviteModalOpen}
        setInviteModalOpen={setInviteModalOpen}
        inviteEmail={inviteEmail}
        setInviteEmail={setInviteEmail}
        inviteRole={inviteRole}
        setInviteRole={setInviteRole}
        handleInvite={handleInvite}
      />

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={toastOpen}
        autoHideDuration={6000}
        onClose={() => setToastOpen(false)}
        message={toastMessage}
      />
    </Box>
  );
};