import { Box, Card, CardContent, Typography } from "@mui/material";
import { UserTemplateItem } from "../../types/types";
import LockIcon from "@mui/icons-material/Lock";
import { Draggable } from "react-beautiful-dnd";
import Logo from "../Icons/Logo";
import Tooltip from "@mui/material/Tooltip";
import {
  AccountCircle,
  Groups,
  DragIndicator,
  Person,
  Person2,
  PersonPinCircle,
  PersonRounded,
} from "@mui/icons-material";
import { useThemeContext } from "../../context/theme/context";

interface TemplateListItemProps {
  template: UserTemplateItem;
  canModify: boolean;
  handleClick: () => void;
  isSelected: boolean;
}

export const TemplateListItem = ({
  template,
  canModify,
  handleClick,
  isSelected,
}: TemplateListItemProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flex: "1 1 0%",
        bgcolor: isSelected
          ? "backgroundColors.tertiary"
          : "backgroundColors.primary",
        height: "min-content",
        maxHeight: "100%",
        padding: "1.5rem",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "0.5rem",
        "&:hover": {
          cursor: "pointer",
          bgcolor: "backgroundColors.secondary",
        },
        "&:not(:first-child)": {
          borderTop: 1,
          borderColor: "borderColors.primary",
        },
      }}
      onClick={handleClick}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          fontSize="0.9375rem"
          fontStyle="normal"
          fontWeight="700"
          lineHeight="1.40625rem"
          color="textColors.lightHeader"
        >
          {template.display_name}
        </Typography>
        {!canModify && <LockIcon />}
      </Box>
    </Box>
  );
};

interface DraggableTemplateListItemProps {
  template: UserTemplateItem;
  index: number;
  canModify: boolean;
  handleClick: () => void;
  isSelected: boolean;
}

export const DraggableTemplateListItem = ({
  template,
  index,
  canModify,
  handleClick,
  isSelected,
}: DraggableTemplateListItemProps) => {
  const { brandLanguage } = useThemeContext();

  return (
    <Draggable
      key={template.template_id}
      draggableId={template.template_id}
      index={index}
    >
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          sx={{
            border: "1px solid",
            bgcolor: isSelected
              ? "backgroundColors.secondary"
              : "backgroundColors.primary",
            "&:hover": {
              boxShadow: 3, // Adjust shadow on hover
              cursor: "pointer",
            },
            borderRadius: '0.5cm',
            borderColor: "borderColors.brand"
          }}
          onClick={handleClick}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              p: 2
            }}
          >
            <Box
              {...provided.dragHandleProps}
              sx={{ marginLeft: -1, marginRight: 1 }}
            >
              <DragIndicator sx={{color: 'backgroundColors.brand'}}/>
            </Box>
            <Typography
              fontStyle="normal"
              fontWeight="700"
              color="textColors.brand"
              sx={{ flexGrow: 1 }}
            >
              {template.display_name}
            </Typography>
            {canModify && template.type !== "group" && (
              <Tooltip
                title="This is your template. You can do what you'd like with it!"
                placement={"top"}
                arrow
              >
                <Box>
                  <AccountCircle />
                </Box>
              </Tooltip>
            )}
            {!canModify && template.type !== "group" && (
              <Tooltip
                title={`This is a ${brandLanguage.brandName} template. You can make a copy, but you cannot edit it directly.`}
                placement={"top"}
                arrow
              >
                <Box>
                  <Logo size={22} />
                </Box>
              </Tooltip>
            )}
            {template.type === "group" && (
              <Tooltip
                title="This is a group template. Only group admins can edit it."
                placement={"top"}
                arrow
              >
                <Box>
                  <Groups />
                </Box>
              </Tooltip>
            )}
          </Box>
        </Box>
      )}
    </Draggable>
  );
};
