import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  CircularProgress,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import { Patient } from "../../types/types";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";
import PayerSearch from "./PayerSearch";
import InfoIcon from "@mui/icons-material/Info";

// Define required fields for eligibility and billing
const requiredEligibilityFields = ["first_name", "last_name", "payer_id"];
const requiredBillingFields = [
  "dob",
  "gender",
  "address",
  "city",
  "state",
  "zip",
  "phone",
  "email",
  "insurance_policy_number",
  "payer_relationship",
];

// Function to check if a field is required for eligibility or billing
const isFieldRequired = (field: string) => {
  if (requiredEligibilityFields.includes(field)) return "*"; // Eligibility field
  if (requiredBillingFields.includes(field)) return "**"; // Billing field
  return "";
};

interface PatientInformationViewProps {
  patientId: string;
  highlightedFields?: string[];
  onPatientSave?: (updatedPatient: Patient) => void;
}

const PatientInformationView: React.FC<PatientInformationViewProps> = ({
  patientId,
  highlightedFields = [],
  onPatientSave,
}) => {
  const [patient, setPatient] = useState<Patient | null>(null);
  const [loading, setLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [selectedPayer, setSelectedPayer] = useState<{
    id: string;
    name: string;
    eligibility: string;
  } | null>(null);
  const [payerRel, setPayerRel] = useState<string>("");

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });
  const { getAccessToken } = useUser();

  useEffect(() => {
    fetchPatient();
  }, [patientId]);

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setPatient((prev) => {
      if (!prev) return null;
      return { ...prev, [name]: value as string };
    });
    setHasChanges(true);
  };

  const handlePayerSelect = (
    payer: { id: string; name: string; eligibility: string } | null
  ) => {
    if (payer) {
      setSelectedPayer(payer);
      setPatient((prev) => {
        if (!prev) return null;
        return {
          ...prev,
          payer_id: payer.id,
          payer_name: payer.name,
          eligibility: payer.eligibility,
        };
      });
    } else {
      setSelectedPayer(null);
      setPatient((prev) => {
        if (!prev) return null;
        return { ...prev, payer_id: "", payer_name: "", eligibility: "" };
      });
    }
    setHasChanges(true);
  };

  const fetchPatient = async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/patient/get?patient_id=${patientId}&origin=PatientInformationView`,
        accessToken,
      });

      if (response.ok) {
        const data = await response.value;
        setPatient(data.patient);
        // Initialize selectedPayer with the payer information from the backend
        if (data.patient.payer_id && data.patient.payer_name) {
          setSelectedPayer({
            id: data.patient.payer_id,
            name: data.patient.payer_name,
            eligibility: data.patient.eligibility,
          });
        }
      } else {
        throw new Error("Failed to fetch patient");
      }
    } catch (error) {
      console.error("Error fetching patient:", error);
      setSnackbar({
        open: true,
        message: "Failed to load patient data",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name !== "payer_id") {
      if (name === "state") {
        // Only allow letters, convert to uppercase, and limit to 2 characters
        const stateValue = value
          .replace(/[^A-Za-z]/g, "")
          .toUpperCase()
          .slice(0, 2);
        setPatient((prev) => {
          if (!prev) return null;
          const updatedPatient = { ...prev, [name]: stateValue };
          setHasChanges(true);
          return updatedPatient;
        });
      } else {
        setPatient((prev) => {
          if (!prev) return null;
          const updatedPatient = { ...prev, [name]: value };
          setHasChanges(true);
          return updatedPatient;
        });
      }
    }
  };

  const handleSave = async () => {
    if (!patient) return;

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient/update",
        accessToken,
        body: {
          patient_id: patientId,
          updated_fields: patient,
        },
      });

      if (response.ok) {
        setSnackbar({
          open: true,
          message: "Patient updated successfully",
          severity: "success",
        });
        setHasChanges(false);
        if (onPatientSave) onPatientSave(patient);
      } else {
        throw new Error("Failed to update patient");
      }
    } catch (error) {
      console.error("Error updating patient:", error);
      setSnackbar({
        open: true,
        message: "Failed to update patient",
        severity: "error",
      });
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!patient) {
    return <Typography>No patient data available.</Typography>;
  }

  const fields = [
    { name: "first_name", label: "First Name" },
    { name: "middle_name", label: "Middle Name" },
    { name: "last_name", label: "Last Name" },
    { name: "identifier", label: "Identifier (PRN)" },
    { name: "dob", label: "Date of Birth", type: "date" },
    { name: "gender", label: "Gender", type: "select" }, // Add type 'select' for gender
    { name: "race", label: "Race" },
    { name: "address", label: "Address" },
    { name: "city", label: "City" },
    { name: "state", label: "State", maxLength: 2 },
    { name: "zip", label: "Zip", type: "number" },
    { name: "insurance_policy_number", label: "Insurance Policy Number" },
  ];

  return (
    <Paper elevation={1} sx={{ p: 3, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        Patient Information
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {fields
          .filter((field) => field.name !== "payer_id") // Exclude payer_id from the main fields
          .map((field) => (
            <Box key={field.name} sx={{ flexGrow: 1, minWidth: "150px" }}>
              <Box
                sx={{ height: "1.5em", display: "flex", alignItems: "center" }}
              >
                <Typography variant="caption" color="red">
                  {isFieldRequired(field.name)}
                </Typography>
              </Box>
              {field.type === "date" ? (
                <TextField
                  label={field.label}
                  name={field.name}
                  type="date"
                  value={patient[field.name as keyof Patient] || ""}
                  onChange={handleInputChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: new Date().toISOString().split("T")[0],
                  }}
                  sx={{
                    flexGrow: 1,
                    minWidth: "150px",
                    ...(highlightedFields.includes(field.name) && {
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "primary.main",
                          borderWidth: 2,
                        },
                      },
                    }),
                  }}
                />
              ) : field.type === "select" ? (
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    ...(highlightedFields.includes(field.name) && {
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "primary.main",
                          borderWidth: 2,
                        },
                      },
                    }),
                  }}
                >
                  <InputLabel id={`${field.name}-label`}>
                    {field.label}
                  </InputLabel>
                  <Select
                    labelId={`${field.name}-label`}
                    name={field.name}
                    value={
                      (patient[field.name as keyof Patient] as string) || ""
                    }
                    onChange={handleSelectChange}
                    label={field.label}
                    fullWidth
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  label={field.label}
                  name={field.name}
                  value={patient[field.name as keyof Patient]}
                  onChange={handleInputChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputLabelProps={
                    field.type === "date" ? { shrink: true } : undefined
                  }
                  inputProps={
                    field.type === "date"
                      ? { max: new Date().toISOString().split("T")[0] }
                      : undefined
                  }
                  InputProps={{
                    readOnly: field.name === "payer_id" ? true : false,
                    endAdornment:
                      field.name === "payer_id" ? (
                        <Tooltip title="Select a payer using the Search Payers above. This field will be automatically filled.">
                          <InfoIcon
                            sx={{ fontSize: 20, color: "action.active" }}
                          />
                        </Tooltip>
                      ) : null,
                  }}
                  sx={{
                    flexGrow: 1,
                    minWidth: "150px",
                    ...(highlightedFields.includes(field.name) && {
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "primary.main",
                          borderWidth: 2,
                        },
                      },
                    }),
                  }}
                />
              )}
            </Box>
          ))}
      </Box>
      <Box sx={{ mt: 2 }}>
        <PayerSearch
          onPayerSelect={handlePayerSelect}
          disabled={false}
          value={selectedPayer}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <TextField
          label="Payer ID"
          name="payer_id"
          value={patient.payer_id || ""}
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            readOnly: true,
            endAdornment: (
              <Tooltip title="Select a payer using the Search Payers above. This field will be automatically filled.">
                <InfoIcon sx={{ fontSize: 20, color: "action.active" }} />
              </Tooltip>
            ),
          }}
          sx={{
            flexGrow: 1,
            minWidth: "150px",
            ...(highlightedFields.includes("payer_id") && {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "primary.main",
                  borderWidth: 2,
                },
              },
            }),
          }}
        />
      </Box>
      <Box>
        <FormControl
          fullWidth
          sx={{
            mt: 2,
            mb: 2,
            ...(highlightedFields.includes("payer_relationship") && {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "primary.main",
                  borderWidth: 2,
                },
              },
            }),
          }}
        >
          <InputLabel id="payer-rel-label">PATIENT RELATIONSHIP</InputLabel>
          <Select
            labelId="payer-rel-label"
            name="payer_relationship"
            value={patient.payer_relationship || payerRel}
            onChange={handleSelectChange}
            label="PAYER RELATIONSHIP"
            fullWidth
          >
            <MenuItem value="self">Self</MenuItem>
            <MenuItem value="spouse">Spouse</MenuItem>
            <MenuItem value="child">Child</MenuItem>
            <MenuItem value="employee">Employee</MenuItem>
            <MenuItem value="unknown">Unknown</MenuItem>
            <MenuItem value="life_partner">Life Partner</MenuItem>
            <MenuItem value="dependent">Dependent</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Typography variant="body2" sx={{ mt: 2 }}>
        <span style={{ color: "red" }}>*</span> Required for eligibility |{" "}
        <span style={{ color: "red" }}>**</span> Required for billing
      </Typography>
      {hasChanges && (
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Box>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default PatientInformationView;
