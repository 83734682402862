import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import PatientPicker from "./Patient/PatientPicker";
import { Patient } from "../types/types";
import APIService from "../services/APIService";
import { useUser } from "../context/user";
import { Info, InfoOutlined } from "@mui/icons-material";
import AssignPatientToNoteDialog from "./Patient/AssignPatientToNoteDialog";

interface AssignNoteToPatientProps {
  noteId: string;
  onAssignSuccess: () => void;
}

const AssignNoteToPatient: React.FC<AssignNoteToPatientProps> = ({
  noteId,
  onAssignSuccess,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="body1"
          onClick={handleOpen}
          sx={{
            textDecoration: "underline",
            cursor: "pointer",
            color: "primary.main",
            marginRight: 1,
          }}
        >
          Assign Note to Patient
        </Typography>
        <Tooltip title="Assign this note to a patient so JotPsych can pull forward information from this encounter next time you see this patient.">
          <InfoOutlined sx={{ color: "grey.500", fontSize: "1.2rem" }} />
        </Tooltip>
      </Box>
      <AssignPatientToNoteDialog
        open={open}
        onClose={handleClose}
        noteId={noteId}
        onAssignSuccess={onAssignSuccess}
      />
    </>
  );
};

export default AssignNoteToPatient;
