import { FC, ReactNode } from "react";
import { Box } from "@mui/material";

export interface ExtensionProps {
  children: ReactNode;
  position?: "top" | "bottom";
}

export const Extension: FC<ExtensionProps> = ({
  children,
  position = "top",
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...(position === "bottom" && {
          borderTop: "0.5px solid",
          borderColor: "borderColors.secondary",
          py: 1,
        }),
      }}
    >
      {children}
    </Box>
  );
};
