export const Colors = {
  jotpsych_main: "#36229e", //Eggplant[700]
  jotpsych_dark: "#1f0862", // also button hovers
  brand_main: "#36229e", //Eggplant[700]
  brand_dark: "#1f0862", // also button hovers
  brand_white: "#ffffff", // Pure White
  brand_black: "#000000", // Pure Black
  //Primary Palette is - Eggplant:
  primary: "#ffffff", //Eggplant[700]
  primary900: "#2a2348",
  primary800: "#291d69",
  primary700: "#36229e",
  primary600: "#4d32d7",
  primary500: "#644ce0",
  primary400: "#836eed",
  primary300: "#ada1f3",
  primary200: "#e6e1ff",
  primary100: "#f5f4fc",
  //All greys below are eggplant-neutrals. ~Do not use regular greys!~
  grey900: "#030115",
  grey800: "#2a2533",
  grey700: "#4d4757",
  grey600: "#857f93",
  grey500: "#a39eae",
  grey400: "#bcbac9",
  grey300: "#d5d3e0",
  grey200: "#ebeaf1",
  grey100: "#f5f4f8",
  grey50: "#faf9fc",
  sucess: "",
  danger: "#eb4a26",
  dangerLight: "#faf6f5",
  body_bg: "",
  border: "",
  inverse: "",
  link: "#2196F3",
  linkHover: "#1976D2",
  eggplant900: "#2A2349",
  eggplant800: "#291D69",
  eggplant700: "#36229E",
  eggplant600: "#4D32D7",
  eggplant500: "#644CEO",
  eggplant400: "#836EED",
  eggplant300: "#AEA1F3",
  eggplant200: "#E6E1FF",
  eggplant100: "#F5F4FB",
  orange100: "#FFC79E",
  yellow200: "#FFDDB",
  eggplantNeutral900: "#040116",
  eggplantNeutral800: "#2A2533",
  eggplantNeutral700: "#4D4758",
  eggplantNeutral600: "#857F92",
  eggplantNeutral500: "#A39DAF",
  eggplantNeutral400: "#BCBACA",
  eggplantNeutral300: "#D5D3EO",
  eggplantNeutral200: "#EBEAFO",
  eggplantNeutral100: "#F5F4F8",
  eggplantNeutral50: "#FAF9FC",
};
