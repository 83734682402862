import {
  Box,
  Button,
  SlideProps,
  Modal,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";

import MultiColumnCenterBox from "../layouts/MultiColumnCenterBox";
import { BoxColumn } from "../components/layout/BoxColumn";
import { useUser } from "../context/user";
import { UserInfoForm } from "../components/UserInfoForm";
import { useEffect, useState } from "react";
import { Slide } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CircleIcon from "@mui/icons-material/Circle";
import { alpha } from "@mui/material/styles";
import DownloadCompanionApp from "../components/DownloadCompanionApp";
import LanguageAndNotePreferencesForm from "../components/LanguageAndNotePreferencesForm";
import { useNavigate } from "react-router-dom";
import { clarity } from "../services/ClarityService";
import { LogoutButton } from "../components/Buttons/LogoutButton";
import { useThemeContext } from "../context/theme/context";
import { useUIState } from "../context/uiState";
import OnboardingCompleted from "../components/OnboardingCompleted";
import { TestNoteRecordingView } from "./TestNoteRecordingView";
import AdditionalFactorsForm from "../components/ModalitiesDiagnosesForm";
import ModalitiesDiagnosesForm from "../components/ModalitiesDiagnosesForm";
import { trackEvent } from "../utils/analytics_utils";

const OnboardingViewRevamp: React.FC = () => {
  const { getAccessToken, completeSignup, userState } = useUser();
  const { state } = useUIState();
  const { platform } = state;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<number>(
    userState?.userInfo ? 1 : 0
  );
  const [contentKey, setContentKey] = useState<number>(0);

  const { brandLanguage } = useThemeContext();

  const maxSteps = 3; // Adjust the number of steps as needed

  const win: Window = window;

  const [subjectLanguage, setSubjectLanguage] = useState<string>();

  const handleNext = (): void => {
    if (activeStep < maxSteps - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setContentKey((prevKey) => prevKey + 1); // Trigger re-render with new key
    } else {
      onCompleteOnboarding();
    }
  };

  const handleSkip = (): void => {
    onCompleteOnboarding();
  };

  const onCompleteOnboarding = (): void => {
    // Implement your onComplete callback logic here
    completeSignup();
    navigate("dashboard");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Conditional rendering based on activeStep
  const getStepContent = (step: number): JSX.Element => {
    switch (step) {
      case 0:
        return (
          <UserInfoForm
            onSuccessfulFormSubmission={() => {
              trackEvent({
                event: "WebApp_SignupConfirm",
                platform: platform,
              });
              trackEvent({
                event: "user_info_form_completed",
                platform: platform,
              });
              handleNext();
            }}
          />
        );
      //   case 1:
      //     return (
      //       <ModalitiesDiagnosesForm
      //         onSuccessfulFormSubmission={() => {
      //           handleNext();
      //         }}
      //         onNext={handleNext}
      //       />
      //     );
      case 1:
        return (
          <LanguageAndNotePreferencesForm
            onSuccessfulFormSubmission={(subjectLanguage: string) => {
              trackEvent({
                event: "onboarding_language_preferences_screen_complete",
                platform: platform,
              });
              setSubjectLanguage(subjectLanguage);
              handleNext();
            }}
          />
        );

      case 2:
        return (
          <TestNoteRecordingView
            onNext={handleNext}
            subjectLanguage={subjectLanguage}
          />
        );

      default:
        return <div>Unknown step</div>;
    }
  };

  useEffect(() => {
    if (clarity.hasStarted()) {
      clarity.upgrade("onboarding");
      clarity.setTag("sessionType", "onboarding");
    }
  }, []);
  const HorizontalTimeline = () => {
    const steps = [
      "Get Started",
      "Basic Settings",
      `Start Using ${brandLanguage?.brandName}`,
    ];
    return (
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(128, 0, 128, 0.4)", // Example: Purple with 50% opacity
          zIndex: -1,
        }}
      />

      <Modal
        open={true}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
            justifyContent: "flex-start", // Changed from 'center'
            width: { md: "50vw", xs: "95%", sm: "80%" },
            maxWidth: "800px",
            maxHeight: "90vh", // Added max height
            alignItems: "center",
            backgroundColor: "white",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            outline: "none",
            overflowY: "auto", // Added scroll
          }}
        >
          <Box
            sx={{
              width: "100%",
              flexShrink: 0,
              display: { xs: "none", sm: "block" }, // Hide on extra small screens (mobile)
            }}
          >
            <HorizontalTimeline />
          </Box>
          <Slide
            id="slide"
            direction="left"
            in={true}
            mountOnEnter
            unmountOnExit
            key={contentKey}
            style={{ transformOrigin: "right" }}
          >
            <Box sx={{ width: "100%", flexGrow: 1, overflowY: "auto" }}>
              {getStepContent(activeStep)}
            </Box>
          </Slide>
          {/* <Box sx={{mt: -2 }}>
            <LogoutButton />
          </Box> */}
        </Box>
      </Modal>
    </>
  );
};

export default OnboardingViewRevamp;
