import { access } from "fs";
import APIService from "../../services/APIService";
import { NoteInformation } from "@shared/Types/RecordingTypes";

export const startNoteProcessingSession = async (
  transcriptionSessionId: string,
  currentNoteInformation: NoteInformation,
  accessToken?: string | null,
  onNetworkDelay?: () => void,
  onNetworkAbsoluteTimeout?: () => void
): Promise<any | false> => {
  const noteProcessingSessionResponse = await APIService.makeAPIPostRequest({
    requestString: "/noteProcessingSession/startNoteProcessing",
    accessToken,
    body: {
      transcription_session_id: transcriptionSessionId,
      note_title: currentNoteInformation.noteTitle,
      template_id: currentNoteInformation.templateId,
      patient_id: currentNoteInformation.patient?.patient_id,
      demo_info: {
        pronouns: currentNoteInformation.pronouns,
        age: currentNoteInformation.age,
      },
      provided_context_string: currentNoteInformation.additionalInfo,
    },
    onNetworkDelay: onNetworkDelay,
    onNetworkAbsoluteTimeout: onNetworkAbsoluteTimeout,
  });

  if (noteProcessingSessionResponse.ok) {
    return noteProcessingSessionResponse.value;
  } else {
    return false;
  }
};
