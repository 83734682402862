import {
  AdminGroup,
  AdminSpecialty,
  AdminTemplate,
  AdminUser,
} from "../../types/types";
import {
  SelectedUserProvider,
  useSelectedUser,
} from "../../context/selectedUserContext.tsx";
import { UserList } from "../../components/Admin/UsersList";
import { UserInfo } from "../../components/Admin/UserInfo";
import {
  PaginatedUsersLoader,
  UsersPaginationParams,
  UsersResponse,
} from "../../loaders/UsersLoader";
import { Snackbar } from "@mui/material";
import { UserGroups } from "../../components/Admin/UserGroups";
import { LegacyUserTemplates } from "../../components/Admin/LegacyUserTemplates";
import { useCallback, useEffect, useState } from "react";
import { TemplatesModal } from "../../components/Admin/TemplatesModal";
import { UserNotificationModal } from "../../components/Admin/UserNotificationModal";
import { AllUserNotificationModal } from "../../components/Admin/AllUserNotificationModal";
import { TemplatesLoader } from "../../loaders/TemplatesLoader";
import { GroupsModal } from "../../components/Admin/GroupsModal";
import { GroupsLoader } from "../../loaders/GroupsLoader";
import { UserRecentNotes } from "../../components/Admin/UserRecentNotes";
import { UserSpecialties } from "../../components/Admin/UserSpecialties";
import { SpecialtiesModal } from "../../components/Admin/SpecialtiesModal";
import { SpecialtiesLoader } from "../../loaders/SpecialtiesLoader";
import { UniversalCustomPromptSection } from "../../components/Admin/CustomPromptSections";
import { UserCustomPrompts } from "../../components/Admin/UserCustomPrompts";
import { UserTemplates } from "../../components/Admin/UserTemplates";
import { UserNotifications } from "../../components/Admin/UserNotifications";
import { UserFeatures } from "../../components/Admin/UserFeatures";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { debounce } from "lodash"; // Make sure to import lodash or implement your own debounce function

export const UserOverview: React.FC = () => {
  const { getAccessToken } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const { selectedUser, selectUser } = useSelectedUser();
  const [users, setUsers] = useState<AdminUser[] | null>();
  const [featuresList, setFeaturesList] = useState<string[]>([]);
  const [templates, setTemplates] = useState<AdminTemplate[] | null>();
  const [templatesModalVisible, setTemplatesModalVisible] = useState(false);
  const [addNotificationModalVisible, setAddNotificationModalVisible] =
    useState(false);
  const [
    addAllUserNotificationModalVisible,
    setAddAllUserNotificationModalVisible,
  ] = useState(false);
  const [groups, setGroups] = useState<AdminGroup[] | null>();
  const [groupsModalVisible, setGroupsModalVisible] = useState(false);
  const [specialties, setSpecialties] = useState<AdminSpecialty[] | null>();
  const [specialtiesModalVisible, setSpecialtiesModalVisible] = useState(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [totalCount, setTotalCount] = useState(0);
  const [paginationParams, setPaginationParams] =
    useState<UsersPaginationParams>({
      page: 1,
      pageSize: 25,
      sortBy: "user_role",
      sortOrder: "asc",
      searchQuery: "",
      featureFilter: "",
      cohortFilter: "",
    });
  const [searchInput, setSearchInput] = useState("");
  const [selectedFeature, setSelectedFeature] = useState<string>("");

  const fetchUsersData = useCallback(async (params: UsersPaginationParams) => {
    setIsLoading(true);
    try {
      const response = await PaginatedUsersLoader({
        ...params,
        featureFilter: params.featureFilter,
        cohortFilter: params.cohortFilter,
      });
      setUsers(response.users);
      setTotalCount(response.totalCount);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
    setIsLoading(false);
  }, []);

  const debouncedFetchUsers = useCallback(
    debounce((params: UsersPaginationParams) => {
      fetchUsersData(params);
    }, 300),
    []
  );

  const fetchTemplatesData = async () => {
    const response = await TemplatesLoader();
    if (response) {
      setTemplates(response);
    }
  };

  const fetchGroupsData = async () => {
    const response = await GroupsLoader();
    if (response) {
      setGroups(response);
    }
  };

  const fetchSpecialtiesData = async () => {
    const response = await SpecialtiesLoader();
    if (response) {
      setSpecialties(response);
    }
  };

  const fetchFeaturesList = async () => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIGetRequest({
      requestString: "/testsuite/getFeaturesList",
      accessToken: accessToken,
    });

    if (response.ok) {
      const data = response.value;
      setFeaturesList(data.features_list);
    }
  };

  const onUpdateSuccess = async () => {
    fetchUsersData(paginationParams);
  };

  const handleRemoveTemplateFromUser = async (
    template_id: string,
    user_id: string
  ) => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/template/removeTemplate",
      accessToken: accessToken,
      body: {
        assign_target: "user",
        id: user_id,
        template_id: template_id,
      },
    });

    if (!response.ok) {
      console.error("there was a problem with the network request");
    } else {
      onUpdateSuccess();
    }
  };

  const handleRemoveUserFromGroup = async (
    group_id: string,
    user_id: string
  ) => {
    console.log({
      group_id,
      user_id,
    });
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/group/removeUserFromGroup",
      accessToken: accessToken,
      body: {
        group_id,
        user_id,
      },
    });

    if (!response.ok) {
      console.error("there was a problem with the network request");
    } else {
      onUpdateSuccess();
    }
  };

  const handleRemoveUserFromSpecialty = async (
    specialty_id: string,
    user_id: string
  ) => {
    console.log({
      specialty_id,
      user_id,
    });
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/specialties/removeUserFromSpecialty",
      accessToken: accessToken,
      body: {
        specialty_id,
        user_id,
      },
    });

    if (!response.ok) {
      console.error("there was a problem with the network request");
    } else {
      onUpdateSuccess();
    }
  };

  const handleAddTemplateToUser = async (
    template_id: string,
    user_id: string,
    display_name: string
  ) => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/template/assignTemplate",
      accessToken: accessToken,
      body: {
        assign_target: "user",
        id: user_id,
        template_id: template_id,
        display_name: display_name,
      },
    });

    if (!response.ok) {
      console.error("there was a problem with the network request");
    } else {
      onUpdateSuccess();
    }
  };

  const handleAddNotificationToUser = async (
    title: string,
    externalUrl: string,
    targetUserid: string
  ) => {
    const accessToken = await getAccessToken();
    const response = await APIService.makeAPIPostRequest({
      requestString: "/user/addNotificationToUser",
      accessToken: accessToken,
      body: {
        targetUserid: targetUserid,
        title: title,
        externalUrl: externalUrl,
      },
    });

    if (!response.ok) {
      console.error("There was a problem with the network request");
      setToastMessage(
        response.error
          ? response.error.toString()
          : "There was a problem with the network request"
      );
      setOpenToast(true);
    } else {
      setToastMessage(response.value.message);
      setOpenToast(true);
      onUpdateSuccess();
    }
  };

  const handleAddNotificationToAllUser = async (
    title: string,
    externalUrl: string
  ) => {
    const accessToken = await getAccessToken();
    const response = await APIService.makeAPIPostRequest({
      requestString: "/user/addNotificationToAllUser",
      accessToken: accessToken,
      body: {
        title: title,
        externalUrl: externalUrl,
      },
    });

    if (!response.ok) {
      console.error("There was a problem with the network request");
      setToastMessage(
        response.error
          ? response.error.toString()
          : "There was a problem with the network request"
      );
      setOpenToast(true);
    } else {
      setToastMessage(response.value.message);
      setOpenToast(true);
      onUpdateSuccess();
    }
  };

  const handleAddUserToGroup = async (
    group_id: string,
    user_id: string,
    role: string
  ) => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/group/assignUserToGroup",
      accessToken: accessToken,
      body: {
        group_id,
        user_id,
        role,
      },
    });

    if (!response.ok) {
      console.error("there was a problem with the network request");
      setToastMessage("There was a problem with the network request");
      setOpenToast(true);
    } else {
      onUpdateSuccess();
    }
  };

  const handleAddUserToSpecialty = async (
    specialty_id: string,
    user_id: string
  ) => {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/specialties/assignUserToSpecialty",
      accessToken: accessToken,
      body: {
        specialty_id,
        user_id,
      },
    });

    if (!response.ok) {
      console.error("there was a problem with the network request");
    } else {
      onUpdateSuccess();
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPaginationParams((prev) => ({ ...prev, page: value }));
  };

  const handleSortChange = (event: SelectChangeEvent) => {
    setPaginationParams((prev) => ({
      ...prev,
      sortBy: event.target.value as "user_role" | "logins" | "note_count",
    }));
  };

  const handleSortOrderChange = () => {
    setPaginationParams((prev) => ({
      ...prev,
      sortOrder: prev.sortOrder === "asc" ? "desc" : "asc",
    }));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchQuery = event.target.value;
    setSearchInput(newSearchQuery);
    setPaginationParams((prev) => ({
      ...prev,
      searchQuery: newSearchQuery,
      page: 1,
    }));
  };

  const handleFeatureChange = (event: SelectChangeEvent) => {
    const newFeature = event.target.value as string;
    setSelectedFeature(newFeature);
    setPaginationParams((prev) => ({
      ...prev,
      page: 1,
      featureFilter: newFeature,
    }));
  };

  const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
    const newPageSize = Number(event.target.value);
    setPaginationParams((prev) => ({
      ...prev,
      pageSize: newPageSize,
      page: 1, // Reset to first page when changing page size
    }));
  };

  useEffect(() => {
    fetchTemplatesData();
    fetchGroupsData();
    fetchSpecialtiesData();
    fetchFeaturesList();
  }, []);

  useEffect(() => {
    debouncedFetchUsers(paginationParams);
  }, [paginationParams, debouncedFetchUsers]);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="flex flex-col">
      <div
        className="mb-4"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          label="Search"
          variant="outlined"
          value={searchInput}
          onChange={handleSearchChange}
          className="mr-4"
          style={{ flexGrow: 1, maxWidth: 300 }}
        />
        <FormControl
          variant="outlined"
          className="mr-4"
          style={{ minWidth: 200 }}
        >
          <InputLabel id="feature-select-label">Filter by Feature</InputLabel>
          <Select
            labelId="feature-select-label"
            value={selectedFeature}
            onChange={handleFeatureChange}
            label="Filter by Feature"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {featuresList.map((feature) => (
              <MenuItem key={feature} value={feature}>
                {feature}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Filter by Cohort"
          variant="outlined"
          value={paginationParams.cohortFilter}
          onChange={(event) =>
            setPaginationParams((prev) => ({
              ...prev,
              cohortFilter: event.target.value,
            }))
          }
          style={{ minWidth: 120, marginLeft: 16 }}
        />
        <FormControl
          variant="outlined"
          style={{ minWidth: 120, marginLeft: 16 }}
        >
          <InputLabel id="page-size-select-label">Records per page</InputLabel>
          <Select
            labelId="page-size-select-label"
            value={paginationParams.pageSize}
            onChange={handlePageSizeChange}
            label="Records per page"
          >
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={500}>500</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          style={{ marginLeft: "auto" }}
          onClick={() => setAddAllUserNotificationModalVisible(true)}
        >
          Send notifications to all users
        </Button>
      </div>

      <div className="flex">
        <div className="w-3/4 overflow-x-auto h-screen">
          <UserList usersList={users!} />
        </div>
        <div className="w-1/4 right-0 border-2 border-gray-400 overflow-y-auto">
          <div className="">
            <UserInfo usersList={users!} />
            <UserFeatures usersList={users!} featuresList={featuresList} />
            <UserRecentNotes />
            <UserTemplates />
            <LegacyUserTemplates
              usersList={users!}
              removeTemplateFromUser={handleRemoveTemplateFromUser}
              addTemplateToUser={() => {
                setTemplatesModalVisible(true);
              }}
            />
            <UserNotifications
              usersList={users!}
              addNotificationToUser={() => {
                setAddNotificationModalVisible(true);
              }}
            />
            <UserSpecialties
              usersList={users!}
              specialties={specialties!}
              addUserToSpecialty={() => {
                setSpecialtiesModalVisible(true);
              }}
              removeUserFromSpecialty={handleRemoveUserFromSpecialty}
            />
            <UserGroups
              usersList={users!}
              addUserToGroup={() => {
                setGroupsModalVisible(true);
              }}
              removeUserFromGroup={handleRemoveUserFromGroup}
            />
            <UserCustomPrompts />
          </div>
        </div>
        {templates && (
          <TemplatesModal
            isOpen={templatesModalVisible}
            onClose={() => setTemplatesModalVisible(false)}
            onConfirm={(template_id, display_name) => {
              handleAddTemplateToUser(template_id, selectedUser, display_name);
              setTemplatesModalVisible(false);
            }}
            templates={templates}
          />
        )}
        {selectedUser && (
          <UserNotificationModal
            isOpen={addNotificationModalVisible}
            onClose={() => setAddNotificationModalVisible(false)}
            onConfirm={(title: string, externalUrl: string) => {
              handleAddNotificationToUser(title, externalUrl, selectedUser);
              setAddNotificationModalVisible(false);
            }}
          />
        )}
        <AllUserNotificationModal
          isOpen={addAllUserNotificationModalVisible}
          onClose={() => setAddAllUserNotificationModalVisible(false)}
          onConfirm={(title: string, externalUrl: string) => {
            handleAddNotificationToAllUser(title, externalUrl);
            setAddAllUserNotificationModalVisible(false);
          }}
        />
        {specialties && (
          <SpecialtiesModal
            isOpen={specialtiesModalVisible}
            onClose={() => setSpecialtiesModalVisible(false)}
            onConfirm={(specialty_id) => {
              handleAddUserToSpecialty(specialty_id, selectedUser);
              setSpecialtiesModalVisible(false);
            }}
            specialties={specialties}
          />
        )}
        {groups && (
          <GroupsModal
            isOpen={groupsModalVisible}
            onClose={() => setGroupsModalVisible(false)}
            onConfirm={(group_id, role) => {
              handleAddUserToGroup(group_id, selectedUser, role);
              setGroupsModalVisible(false);
            }}
            groups={groups}
          />
        )}
      </div>
      <Pagination
        count={Math.ceil(totalCount / paginationParams.pageSize)}
        page={paginationParams.page}
        onChange={handlePageChange}
        className="mt-4"
      />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={openToast}
        autoHideDuration={5000}
        onClose={() => setOpenToast(false)}
        color="blue"
        message={toastMessage}
      />
    </div>
  );
};
