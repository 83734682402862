import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Paper,
  useTheme,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Patient } from "../../types/types";
import PayerSearch from "./PayerSearch";

interface PatientInformationBlockProps {
  patient: Patient;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PatientInformationBlock: React.FC<PatientInformationBlockProps> = ({
  patient,
  onInputChange,
}) => {
  const theme = useTheme();
  const [patientInfo, setPatientInfo] = useState<Patient>(patient);

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setPatientInfo({ ...patientInfo, [name]: value });
    onInputChange({
      target: { name: name, value: value },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const [selectedPayer, setSelectedPayer] = useState<{
    id: string;
    name: string;
    eligibility: string;
  } | null>(() => {
    // Initialize selectedPayer with the patient's existing payer data
    return patient.payer_id && patient.payer_name
      ? {
          id: patient.payer_id,
          name: patient.payer_name,
          eligibility: patient.eligibility || "",
        }
      : null;
  });

  const handlePayerSelect = (
    payer: { id: string; name: string; eligibility: string } | null
  ) => {
    if (payer) {
      setSelectedPayer(payer);
      // Simulate input change events for payer_id and payer_name
      onInputChange({
        target: { name: "payer_id", value: payer.id },
      } as React.ChangeEvent<HTMLInputElement>);
      onInputChange({
        target: { name: "payer_name", value: payer.name },
      } as React.ChangeEvent<HTMLInputElement>);
      onInputChange({
        target: { name: "eligibility", value: payer.eligibility },
      } as React.ChangeEvent<HTMLInputElement>);
    } else {
      setSelectedPayer(null);
      onInputChange({
        target: { name: "payer_id", value: "" },
      } as React.ChangeEvent<HTMLInputElement>);
      onInputChange({
        target: { name: "payer_name", value: "" },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <Paper
      elevation={1}
      sx={{
        p: 2,
        mb: 2,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Patient Information
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        <TextField
          label="First Name"
          name="first_name"
          value={patient.first_name || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, minWidth: "150px" }}
        />
        <TextField
          label="Middle Name"
          name="middle_name"
          value={patient.middle_name || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, minWidth: "150px" }}
        />
        <TextField
          label="Last Name"
          name="last_name"
          value={patient.last_name || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, minWidth: "150px" }}
        />
        <TextField
          label="Identifier (PRN)"
          name="identifier"
          value={patient.identifier || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, minWidth: "150px" }}
        />
        <TextField
          label="Date of Birth"
          name="dob"
          type="date"
          value={patient.dob || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            max: new Date().toISOString().split("T")[0], // Prevents future dates
          }}
          sx={{
            flexGrow: 2,
            minWidth: "150px", // Changed from minWidth to width
            "& input::-webkit-calendar-picker-indicator": {
              cursor: "pointer",
            },
          }}
        />
        <TextField
          label="Race"
          name="race"
          value={patient.race || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, minWidth: "150px" }}
        />
        <TextField
          label="Address"
          name="address"
          value={patient.address || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, minWidth: "150px" }}
        />
        <TextField
          label="City"
          name="city"
          value={patient.city || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, minWidth: "150px" }}
        />
        <TextField
          label="State"
          name="state"
          value={patient.state || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          inputProps={{
            maxLength: 2,
            style: { textTransform: "uppercase" },
          }}
          sx={{ flexGrow: 1, minWidth: "150px" }}
        />
        <TextField
          label="Zip"
          name="zip"
          value={patient.zip || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, minWidth: "150px" }}
        />
        <TextField
          label="Insurance Policy Number"
          name="insurance_policy_number"
          value={patient.insurance_policy_number || ""}
          onChange={onInputChange}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, minWidth: "150px" }}
        />
        <TextField
          label="Payer ID"
          name="payer_id"
          value={patient.payer_id || ""}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, minWidth: "150px" }}
          InputProps={{
            readOnly: true,
          }}
        />
        <Box sx={{ minWidth: "250px", width: "100%" }}>
          <PayerSearch
            onPayerSelect={handlePayerSelect}
            disabled={false}
            value={selectedPayer}
          />
        </Box>
        <FormControl fullWidth>
          <InputLabel id="gender-label">Gender</InputLabel>
          <Select
            labelId="gender-label"
            label="Gender"
            name="gender"
            value={patient.gender}
            onChange={handleSelectChange}
            variant="outlined"
            size="small"
            sx={{ flexGrow: 1, minWidth: "100px" }}
          >
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Paper>
  );
};

export default PatientInformationBlock;
