import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useRef,
  useMemo,
} from "react";
import {
  endTranscriptionSession,
  startTranscriptionSession,
} from "../lib/recorder/TranscriptionSessionUtils";
import { useUser } from "./user";
import { startNoteProcessingSession } from "../lib/recorder/NoteProcessingSessionUtils";
import { useLocalStorageState } from "../hooks/useLocalStorageState";
import WebRecordingService, {
  MediaStreamInfo,
} from "../services/WebRecordingService";
import { useAudioDataContext } from "./AudioDataContext";
import { indexedDBManager } from "../services/IndexDBService";
import { v4 as uuidv4 } from "uuid";
import APIService, {
  FRONTEND_RECORDING_APP_VERSION,
} from "../services/APIService";
import { NoteInformation, RecordingStatus } from "@shared/Types/RecordingTypes";
import { useUIState } from "./uiState";
import { useNavigate } from "react-router-dom";
import {
  RecordingChunk,
  RecordingSession,
} from "../lib/recorder/RecordingSession";
import SentryService from "../services/SentryService";

// Define the interface for the context
interface RecordingContextType {
  currentTranscriptionSessionId: string;
  recordingStatus: RecordingStatus;
  noteInformation: NoteInformation;
  setNoteInformation: React.Dispatch<React.SetStateAction<NoteInformation>>;
  setUseSharedMedia: React.Dispatch<React.SetStateAction<boolean>>;
  useSharedMedia: boolean;
  noAudioStatus: boolean;
  checkMicrophonePermission: () => void;
  setSelectedMicrophone: (selectedMicrophone: MediaDeviceInfo) => void;
  setNoAudioStatus: (status: boolean) => void;
  getAvailableMicrophones: () => void;
  initiateRecording: () => Promise<boolean>;
  initiateEndRecording: () => Promise<boolean>;
  sendRecording: () => Promise<string | undefined>;
  selectedMicrophone?: MediaDeviceInfo;
  availableMicrophones?: MediaDeviceInfo[];
  microphonePermissionGranted?: boolean;
  pauseRecording: () => void; // pause
  resumeRecording: () => Promise<boolean>; // resume
  initiateReupload: () => Promise<boolean>;
  sharedAudioEnabled?: boolean;
  setSharedAudioEnabled?: (enabled: boolean) => void;
  deleteRecordingHelper: () => Promise<boolean>;
  startTimer: () => void;
  stopTimer: () => void;
  resetTimer: () => void;
  elapsedTime: number;
  cleanUpSession: () => void;
  releaseMicrophone: () => void;
  lowDataMode: boolean;
  sendMessageToExtension: (message: any) => void;
}

const RECORDING_CHUNK_LENGTH_SECONDS = 90; // 90 secs
const LOW_DATA_CHUNK_LENGTH_SECTIONS = 50; // 50 secs
const MAX_RECORDING_LENGTH_SECONDS = 60 * 60 * 2; // 2 hours

// Create the Recording context
const RecordingContext = createContext<RecordingContextType | undefined>(
  undefined
);

let chunkPosition = 0;

// Custom hook to use the Recording context
export function useRecordingContext(): RecordingContextType {
  const context = useContext(RecordingContext);

  if (!context) {
    throw new Error(
      "useRecordingContext must be used within an RecordingContextProvider"
    );
  }
  return context;
}

// Define the props for the RecordingContextProvider
interface RecordingContextProviderProps {
  children: ReactNode;
}

// Create the RecordingContextProvider component
export const RecordingContextProvider: React.FC<
  RecordingContextProviderProps
> = ({ children }) => {
  const { getAccessToken, templatesList } = useUser();
  const { setAudioData } = useAudioDataContext();
  const {
    showAlertBanner,
    state: uiState,
    setState: setUIState,
  } = useUIState();
  const { platform, loadedInChromeExtension } = uiState;

  // Define state variables
  const [recordingStatus, setRecordingStatus] =
    useLocalStorageState<RecordingStatus>("recordingStatus", {
      isRecording: false,
      isReuploading: false,
      isStopping: false,
      isStarting: false,
      chunksToBeUploaded: false,
      recordingPaused: false,
      readyToRecord: true,
      readyToSubmit: false,
    });

  const webRecordingInstance = useRef<WebRecordingService>();

  const isRecordingEnding = useRef<boolean>(false);

  const [lowDataMode, setLowDataMode] = useState(false);

  const [chunkLength, setChunkLength] = useState(
    RECORDING_CHUNK_LENGTH_SECONDS
  );

  const [noteInformation, setNoteInformation] =
    useLocalStorageState<NoteInformation>("noteInformation", {});

  const [useSharedMedia, setUseSharedMedia] = useLocalStorageState<boolean>(
    "useSharedMedia",
    false
  );

  const [currentTranscriptionSessionId, setCurrentTranscriptionSessionId] =
    useLocalStorageState<string>("transcriptionSessionId", "");

  const [audioErrorMessage, setAudioErrorMessage] = useState<string>("");
  const [noAudioStatus, setNoAudioStatus] = useLocalStorageState<boolean>(
    "noAudioStatus",
    false
  );
  const [microphonePermissionGranted, setMicrophonePermissionGranted] =
    useLocalStorageState<boolean | undefined>(
      "microphonePermissionGranted",
      undefined
    );
  const [availableMicrophones, setAvailableMicrophones] = useLocalStorageState<
    MediaDeviceInfo[]
  >("availableMicrophones", []);
  const [selectedMicrophone, setSelectedMicrophoneState] = useLocalStorageState<
    MediaDeviceInfo | undefined
  >("selectedMicrophone", undefined);

  // const [elapsedTime, setElapsedTime] = useState(0);
  const elapsedTime = useRef(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [lastChunkHandled, setLastChunkHandled] = useState<boolean>(false);

  const currentRecordingSession = useRef<RecordingSession>();

  const isLoadedInChromeExtension = useRef(false);

  // Added state for appointment type
  const [appointmentType, setAppointmentType] = useLocalStorageState<string>(
    "appointmentType",
    "inPerson"
  );

  const sendMessageToExtension = (message: any) => {
    if (loadedInChromeExtension) {
      console.log("Sending message to extension...");
      window.parent.postMessage(message, "*");
    }
  };

  // Start the recording timer
  const startTimer = () => {
    if (timerRef.current) return;
    timerRef.current = setInterval(() => {
      elapsedTime.current = elapsedTime.current + 1;
    }, 1000);
  };

  // Stop the recording timer
  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  // Reset the recording timer
  const resetTimer = () => {
    stopTimer();
    elapsedTime.current = 0;
    localStorage.setItem("elapsedTime", "0");
    setAudioErrorMessage("");
  };

  useEffect(() => {
    sendMessageToExtension({
      type: "audioErrorMessage",
      data: audioErrorMessage,
    });
  }, [audioErrorMessage]);
  const enterLowDataMode = () => {
    setLowDataMode(true);

    console.log("Entering low data mode.");
    currentRecordingSession.current?.addLog("ENTERING LOW DATA MODE");
    SentryService.logEvent("Entering low data mode.", {
      level: "info",
      extra: { transcriptionSessionId: currentTranscriptionSessionId },
    });
    webRecordingInstance.current?.updateRecordingOption(
      "chunkLength",
      LOW_DATA_CHUNK_LENGTH_SECTIONS * 1000
    );
    setChunkLength(LOW_DATA_CHUNK_LENGTH_SECTIONS);
  };

  // Pause the recording
  const pauseRecording = () => {
    if (!webRecordingInstance.current) {
      webRecordingInstance.current = new WebRecordingService();
    }

    webRecordingInstance.current?.pauseRecording();
    setRecordingStatus((prev) => ({
      ...prev,
      recordingPaused: true,
    }));
  };

  // Resume the recording
  const resumeRecording = async (): Promise<boolean> => {
    try {
      if (!webRecordingInstance.current) {
        webRecordingInstance.current = new WebRecordingService();
      }

      // if the recorder isn't set up yet (e.g. on reload)
      // then create a new one
      if (!webRecordingInstance.current?.recorder) {
        const mediaRecorderCreated = await createMediaRecorder();
        if (!mediaRecorderCreated) {
          console.error("Failed to create media recorder.");
          return false;
        }
      } else {
        webRecordingInstance.current.resumeRecording();
      }

      setRecordingStatus((prev) => ({
        ...prev,
        recordingPaused: false,
      }));
      return true;
    } catch (error) {
      console.error("Error resuming recording:", error);
      SentryService.logEvent("Error resuming recording.", {
        level: "error",
        extra: { error },
      });
      setAudioErrorMessage("Failed to resume recording. Try again.");
      showAlertBanner("Failed to resume recording. Try again.", "error");
      setTimeout(() => setAudioErrorMessage(""), 3000);
      return false;
    }
  };

  const initiateReupload = async (): Promise<boolean> => {
    if (!currentRecordingSession.current || !currentTranscriptionSessionId) {
      return false;
    }

    try {
      const chunksToBeUploaded =
        currentRecordingSession.current.getFailedChunks();

      await reuploadChunks(chunksToBeUploaded, currentTranscriptionSessionId);

      return true;
    } catch (e) {
      console.error("Couldn't reupload chunks");
      return false;
    }
  };

  const checkMicrophonePermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log("Microphone access granted");
      setMicrophonePermissionGranted(true);

      // Stop the tracks immediately to release the microphone
      stream.getTracks().forEach((t) => t.stop());

      // After permission is granted, enumerate devices
      await getAvailableMicrophones();
    } catch (error: any) {
      console.error("Error requesting microphone permission", error);
      SentryService.logEvent("Error requesting microphone permission.", {
        level: "error",
        extra: { error },
      });

      if (error.name === "NotAllowedError") {
        console.log("Permission explicitly denied");
        setMicrophonePermissionGranted(false);
        const errorMessage =
          "Microphone access is denied. You'll need to enable it to use the recorder.";
        setAudioErrorMessage(errorMessage);
        setTimeout(() => setAudioErrorMessage(""), 3000);
        showAlertBanner(errorMessage, "error");
      } else if (error.name === "NotFoundError") {
        console.log("No microphone found");
        setMicrophonePermissionGranted(false);
        const errorMessage =
          "No microphone found. Please connect a microphone to use the recorder.";
        setAudioErrorMessage(errorMessage);
        setTimeout(() => setAudioErrorMessage(""), 3000);
        showAlertBanner(errorMessage, "error");
      } else {
        console.log("Other error", error.name);
        const errorMessage = `Error accessing microphone: ${error.message}`;
        setAudioErrorMessage(errorMessage);
        setTimeout(() => setAudioErrorMessage(""), 3000);
        showAlertBanner(errorMessage, "error");
      }
    }
  };

  const getAvailableMicrophones = async () => {
    try {
      // Request microphone permission first
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // Enumerate devices after permission is granted
      const devices = await navigator.mediaDevices.enumerateDevices();
      const mics = devices.filter((device) => device.kind === "audioinput");

      // Log available microphones for debugging
      console.log("Available microphones:", mics);
      setAvailableMicrophones(mics);

      // Check if a previously selected microphone is stored in localStorage
      const storedMicrophoneId = localStorage.getItem("selectedMicrophoneId");
      if (storedMicrophoneId) {
        const storedMicrophone = mics.find(
          (mic) => mic.deviceId === storedMicrophoneId
        );

        if (storedMicrophone) {
          console.log("Setting previously selected microphone");
          setSelectedMicrophone(storedMicrophone);
          return;
        }
      }

      // If no stored microphone or stored microphone not available, set the first microphone as default
      if (mics.length > 0 && !selectedMicrophone) {
        console.log("Setting default selected microphone");
        setSelectedMicrophone(mics[0]);
      }

      // Stop the tracks immediately to release the microphone
      stream.getTracks().forEach((t) => t.stop());
    } catch (error) {
      console.error("Error getting available microphones:", error);
      SentryService.logEvent("Error getting available microphones.", {
        level: "error",
        extra: { error },
      });
      throw error;
    }
  };

  // Set the selected microphone and store it in localStorage
  const setSelectedMicrophone = (selectedMicrophone: MediaDeviceInfo) => {
    setSelectedMicrophoneState(selectedMicrophone);

    // Store the selected microphone ID in localStorage
    localStorage.setItem("selectedMicrophoneId", selectedMicrophone.deviceId);
  };

  const releaseMicrophone = () => {
    if (webRecordingInstance.current) {
      webRecordingInstance.current.stopMediaStreamRecording();
    }
  };

  const createMediaRecorder = async (transcriptionSessionId?: string) => {
    console.log(
      "useSharedMedia",
      useSharedMedia,
      isLoadedInChromeExtension.current
    );
    currentRecordingSession?.current?.addLog("CreateMediaRecorder", {
      useSharedMedia,
      selectedMicrophoneId: selectedMicrophone?.deviceId,
      selectedMicrophoneLabel: selectedMicrophone?.label,
      chunkLength,
      lowDataMode,
      platform,
      browserInfo: navigator?.userAgent,
      screenWidth: window?.screen?.width,
      screenHeight: window?.screen?.height,
      devicePixelRatio: window?.devicePixelRatio,
      transcriptionSessionId,
    });
    try {
      const mediaStreamResponse =
        await webRecordingInstance.current?.startMediaRecorder(
          {
            getSharedMedia: useSharedMedia,
            getTabMedia: useSharedMedia && isLoadedInChromeExtension.current,
            selectedMicrophoneId: selectedMicrophone?.deviceId,
            chunkLength: chunkLength * 1000,
          },
          {
            recorder: {
              onChunkReady: async (chunkId: string) => {
                console.log(
                  `${chunkId} chunk ready to be pulled from indexdb and uploaded`
                );
                await handleChunk(
                  chunkId,
                  transcriptionSessionId ?? currentTranscriptionSessionId
                );
              },
              onChunkError: async (chunkId: string, error: unknown) => {
                await handleChunkError(chunkId, error);
              },
            },
            audioStream: {
              onAudioImpulse: (averageLevel) => {
                setAudioData((averageLevel / 255) * 100 * 2); // Normalize to percentage and exaggerate
              },
              onNoAudio: () => {
                const errorMessage =
                  "No audio detected. Please check your microphone.";
                setAudioErrorMessage(errorMessage);
                setTimeout(() => setAudioErrorMessage(""), 3000);
                if (!loadedInChromeExtension) {
                  showAlertBanner(errorMessage, "error");
                }
                SentryService.logEvent("No audio detected.", {
                  level: "warning",
                  extra: {
                    selectedMicrophone,
                  },
                });
                currentRecordingSession.current?.addLog("NoAudioDetected", {
                  selectedMicrophone,
                });
              },
              onSharedMediaError: () => {
                const errorMessage =
                  "No audio from screen capture. Did you have 'Also share tab audio' enabled?";
                setAudioErrorMessage(errorMessage);
                setTimeout(() => setAudioErrorMessage(""), 3000);
                showAlertBanner(errorMessage, "warning");
                SentryService.logEvent("No audio from screen capture.", {
                  level: "warning",
                  extra: {
                    selectedMicrophone,
                  },
                });
                currentRecordingSession.current?.addLog("SharedMediaError", {
                  selectedMicrophone,
                });
              },
            },
          }
        );

      if (
        !mediaStreamResponse ||
        !currentRecordingSession.current ||
        !webRecordingInstance.current
      ) {
        throw new Error("Failed to create media recorder");
      }

      const mediaStreamInfo = webRecordingInstance.current.getMediaStreamInfo();
      if (mediaStreamInfo) {
        currentRecordingSession.current?.addMediaStreamInfo({
          streamCreatedAt: new Date().toISOString(),
          ...mediaStreamInfo,
        });
      } else {
        console.warn("Media stream info not available");
        currentRecordingSession.current.addLog(
          "MediaStreamInfoUnavailable",
          {}
        );
      }

      // Log successful creation of media recorder
      currentRecordingSession.current.addLog("MediaRecorderCreated", {
        success: true,
      });

      return true;
    } catch (error) {
      console.error("Error creating media recorder:", error);
      SentryService.logEvent("Error creating media recorder.", {
        level: "error",
        extra: { error },
      });
      currentRecordingSession.current?.addLog("MediaRecorderCreationError", {
        error: error instanceof Error ? error.message : String(error),
      });
      return false;
    }
  };

  const reuploadChunks = async (
    chunksToRetry: RecordingChunk[],
    transcriptionSessionId: string
  ) => {
    setRecordingStatus((prev) => ({
      ...prev,
      isReuploading: true,
    }));
    for (const chunk of chunksToRetry) {
      try {
        await uploadChunk(chunk, transcriptionSessionId);
      } catch (e) {
        setRecordingStatus((prev) => ({
          ...prev,
          isReuploading: false,
          chunksToBeUploaded: true,
        }));
        const errorMessage = "Some audio could not be reuploaded. Try again.";
        setAudioErrorMessage(errorMessage);
        setTimeout(() => setAudioErrorMessage(""), 3000);
        showAlertBanner(errorMessage, "error");
        console.error("One or more chunks could not be reuploaded.");
        throw new Error("One or more chunks could not be reuploaded.");
      }
    }

    console.log("All chunks have been successfully reuploaded.");
    showAlertBanner(
      "All remaining audio has been successfully reuploaded.",
      "success"
    );
    setRecordingStatus((prev) => ({
      ...prev,
      isReuploading: false,
      chunksToBeUploaded: false,
    }));
    finalizeTranscriptionSession(transcriptionSessionId);
  };

  const uploadChunk = async (
    chunk: RecordingChunk,
    transcriptionSessionId: string
  ) => {
    try {
      if (!chunk || chunk.position === undefined) {
        throw new Error("Not a valid chunk");
      }

      // Step 1: Read the chunk from IndexedDB
      const chunkBlob = await indexedDBManager.readBlob(chunk.id);
      if (!chunkBlob) {
        console.error(`Chunk with ID ${chunk.id} not found in IndexedDB`);
        throw new Error(`Chunk with ID ${chunk.id} not found in IndexedDB`);
      }

      const chunkFileExtension = chunkBlob.type.split("/")[1].split(";")[0];
      const chunkType = chunkBlob.type.split(";")[0];

      console.log(
        `Successfully read chunk ${chunk.id} from IndexedDB. File type: ${chunkType}. ${chunkFileExtension}`
      );

      // Step 3: Create the upload URL for the chunk
      console.log(
        `Creating upload URL for chunk ${chunk.id} at position ${chunk.position}`
      );

      const fileName = `${transcriptionSessionId}/chunk${chunk.position}.${chunkFileExtension}`;

      const getUploadURLResponse = await APIService.makeAPIPostRequest({
        requestString: `/transcriptionSession/createUploadURL`,
        accessToken: await getAccessToken(),
        body: {
          object_id: fileName,
          content_type: chunkType,
        },
        onNetworkDelay: () => {
          enterLowDataMode();
        },
      });
      if (!getUploadURLResponse.ok) {
        currentRecordingSession.current?.updateChunkUploadStatus(
          chunk.id,
          undefined,
          false
        );
        const getUploadURLError = new Error("Getting upload URL failed.");
        getUploadURLError.name = "GetUploadURLError";
        throw getUploadURLError;
      }

      const s3_object_id = getUploadURLResponse.value.s3_object_id;

      // Step 4: Upload the chunk to S3
      console.log(`Uploading chunk ${chunk.id} to S3`);
      const uploadResponse = await uploadAudioAsync(
        chunkBlob,
        getUploadURLResponse.value.upload_url,
        chunkType
      );
      if (!uploadResponse.success) {
        currentRecordingSession.current?.updateChunkUploadStatus(
          chunk.id,
          s3_object_id,
          false
        );

        enterLowDataMode();

        const s3UploadError = new Error(
          `Uploading chunk to S3 failed. ${uploadResponse.error}`
        );
        s3UploadError.name = "S3UploadError";
        throw s3UploadError;
      } else {
        console.log(`Successfully uploaded to S3`);
      }

      // Step 5: Add chunk to Session
      console.log(
        `Adding chunk ${chunk.id} to transcription session ${transcriptionSessionId}`
      );
      const success = await addChunkToTranscriptionSession(
        transcriptionSessionId,
        chunk.id,
        chunk.position,
        s3_object_id,
        await getAccessToken()
      );

      if (!success) {
        currentRecordingSession.current?.updateChunkUploadStatus(
          chunk.id,
          s3_object_id,
          false
        );
        const addChunkToSessionError = new Error(
          "Failed to add chunk to transcription session"
        );
        addChunkToSessionError.name = "addChunkToSessionError";
        throw addChunkToSessionError;
      } else {
        // update upload status on chunk
        console.log(
          `Chunk ${chunk.id} added to transcription session successfully`
        );
      }

      // If everything succeeded, update chunk as successful
      currentRecordingSession.current?.updateChunkUploadStatus(
        chunk.id,
        s3_object_id,
        success
      );
    } catch (error) {
      console.error(`Upload Chunk Error: ${error}`);
      SentryService.logEvent("Upload Chunk Error", {
        level: "error",
        extra: { error, transcriptionSessionId: currentTranscriptionSessionId },
      });
      throw new Error(`Upload Chunk Error: ${error}`);
    }
  };

  // Handle a recording chunk
  const handleChunk = async (
    chunkId: string,
    transcriptionSessionId: string
  ) => {
    try {
      if (!transcriptionSessionId) {
        console.error("No transcription session id");
        throw new Error(`No transcription session id`);
      }

      chunkPosition++;
      console.log(`handleChunk called with chunkId: ${chunkId}`);

      // Step 1: Read the chunk from IndexedDB
      const chunkBlob = await indexedDBManager.readBlob(chunkId);
      if (!chunkBlob) {
        console.error(`Chunk with ID ${chunkId} not found in IndexedDB`);
        throw new Error(`Chunk with ID ${chunkId} not found in IndexedDB`);
      }

      let newChunk;
      try {
        if (!currentRecordingSession.current) {
          throw new Error("No current recording session.");
        }

        newChunk = currentRecordingSession.current.addChunkToRecordingSession({
          id: chunkId,
          start_timestamp_seconds: Math.max(
            0,
            elapsedTime.current - RECORDING_CHUNK_LENGTH_SECONDS
          ),
          end_timestamp_seconds: elapsedTime.current,
          upload_status: "pending",
          chunk_data: {
            chunk_size: chunkBlob.size,
            chunk_type: chunkBlob.type,
          },
          position: chunkPosition,
        });
      } catch (error) {
        SentryService.logEvent("Chunk creation error", {
          level: "error",
          extra: { error, transcriptionSessionId },
        });
        throw new Error(`Chunk creation error: ${error}`);
      }

      await uploadChunk(newChunk, transcriptionSessionId);

      // Check if this was the last chunk to process
      if (isRecordingEnding.current) {
        console.log("Last chunk handled, attempting to end recording...");
        await attemptEndRecording(transcriptionSessionId);
      }
    } catch (error) {
      console.error("Error handling chunk", error);
      currentRecordingSession.current?.addLog("Chunk Error", {
        chunk_id: chunkId,
        error: error,
      });
      SentryService.logEvent("Error handling chunk.", {
        level: "error",
        extra: {
          chunkId,
          error,
          transcriptionSessionId,
        },
      });
      // Check if this was the last chunk to process
      if (isRecordingEnding.current) {
        console.log(
          "Last chunk failed to be handled, attempting to end recording..."
        );
        await attemptEndRecording(transcriptionSessionId);
      }
    }
  };

  // Handle a chunk error
  const handleChunkError = async (chunkId: string, error: unknown) => {
    const errorMessage = `Recording error. Please try again.`;
    setAudioErrorMessage(errorMessage);
    setTimeout(() => setAudioErrorMessage(""), 3000);
    showAlertBanner(errorMessage, "error");
    SentryService.logEvent("Chunk error.", {
      level: "error",
      extra: { error, transcriptionSessionId: currentTranscriptionSessionId },
    });

    // Check if this was the last chunk to process
    if (isRecordingEnding.current) {
      console.log(
        "Last chunk failed to be handled, attempting to end recording..."
      );
      await attemptEndRecording(currentTranscriptionSessionId);
    }

    // need to do some specific "cleanup" here. stop recording and allow user to restart.

    // just putting cleanUpSession for now, temporary placeholder
    // cleanUpSession();
  };

  // Finalize the transcription session
  const finalizeTranscriptionSession = async (
    transcrptionId: string
  ): Promise<void> => {
    try {
      console.log("Finalizing transcription session");
      const endTranscriptionSessionStatus = await endTranscriptionSession(
        transcrptionId,
        currentRecordingSession.current?.generateLogsString() || "",
        await getAccessToken()
      );
      console.log(
        "Transcription Session Has ended:",
        endTranscriptionSessionStatus
      );

      // Clear IndexedDB
      await indexedDBManager.clearAll();
      console.log("IndexedDB cleared");

      // Reset state variables
      setRecordingStatus((prev) => ({
        ...prev,
        isRecording: false,
        readyToRecord: false,
        readyToSubmit: true,
        isStopping: false,
      }));

      setAudioData(0);
      isRecordingEnding.current = false;
      chunkPosition = 0;
    } catch (error) {
      console.error("Error finalizing transcription session", error);
      SentryService.logEvent("Error finalizing transcription session", {
        level: "error",
        extra: { error, transcriptionSessionId: currentTranscriptionSessionId },
      });
      setRecordingStatus((prev) => ({ ...prev, isStopping: false }));
    }
  };

  const attemptEndRecording = async (transcriptionSessionId: string) => {
    const endSessionStatus =
      await currentRecordingSession.current?.endSession();
    console.log("Recording Session End Status:", endSessionStatus);

    try {
      // If there are failed chunks, move into `chunksToBeUploaded` state
      if (
        endSessionStatus?.failedChunks &&
        endSessionStatus.failedChunks.length > 0
      ) {
        setRecordingStatus((prev) => ({
          ...prev,
          chunksToBeUploaded: true,
        }));

        try {
          // Try to reupload chunks
          await reuploadChunks(
            endSessionStatus.failedChunks,
            transcriptionSessionId
          );
        } catch (e) {
          console.error("Couldn't finish reupload.");
        }

        setRecordingStatus((prev) => ({
          ...prev,
          isStopping: false,
        }));
      } else {
        // If everything was successfully uploaded, finalize
        finalizeTranscriptionSession(transcriptionSessionId);
      }
    } catch (error) {
      console.error(`Error attempting end recording: ${error}`);
      SentryService.logEvent("Error attempting end recording.", {
        level: "error",
        extra: { error, transcriptionSessionId: currentTranscriptionSessionId },
      });
      setRecordingStatus((prev) => ({ ...prev, isStopping: false }));
    }
  };

  // Set the last chunk handled flag and end the recording
  const initiateEndRecording = async (): Promise<boolean> => {
    try {
      let shortRecording;

      if (!webRecordingInstance.current) {
        webRecordingInstance.current = new WebRecordingService();
      }

      if (elapsedTime.current < 20) {
        shortRecording = true;
      }

      const recorderState = webRecordingInstance.current?.recorder?.getState();

      isRecordingEnding.current = true;
      setAudioData(0);
      setRecordingStatus((prev) => {
        const newStatus = {
          ...prev,
          isRecording: false,
          isStopping: true,
        };
        sendMessageToExtension({ type: "recordingStatus", data: newStatus });
        return newStatus;
      });
      await webRecordingInstance.current?.stopMediaStreamRecording(
        shortRecording
      );

      if (!currentTranscriptionSessionId) {
        console.error("No transcription session or recording session to end.");
        await cleanUpSession();
        return false;
      }

      currentRecordingSession.current?.addMediaStreamInfo({
        streamEndedAt: new Date().toISOString(),
      });

      if (shortRecording) {
        showAlertBanner(
          "This recording is too short to be processed. If you are just testing JotPsych, please submit a recording at least a minute in length.",
          "error"
        );
        SentryService.logEvent("Short Recording", {
          level: "info",
          extra: { elapsedTime: elapsedTime.current },
        });
        cleanUpSession();
        return true;
      }

      if (recorderState === "recording") {
        console.log(
          "Chunks pending. Waiting for the last chunk to be handled before ending the session"
        );
      } else {
        console.log("No more pending chunks, attempting to end recording...");
        await attemptEndRecording(currentTranscriptionSessionId);
      }

      return true;
    } catch (error) {
      console.error("Error initiating end recording", error);
      SentryService.logEvent("Error initiating end recording.", {
        level: "error",
        extra: { error, transcriptionSessionId: currentTranscriptionSessionId },
      });
      return false;
    }
  };

  // // Initiate a new recording session
  const initiateRecording = async (): Promise<boolean> => {
    try {
      if (!webRecordingInstance.current) {
        webRecordingInstance.current = new WebRecordingService();
      }

      if (!recordingStatus.readyToRecord) {
        console.log("Wasn't ready to record, resetting");
        throw new Error("Not ready to record");
      }

      setRecordingStatus((prev) => ({
        ...prev,
        isStarting: true,
      }));

      const newRecordingSession = new RecordingSession({});

      localStorage.setItem("currentRecordingSessionId", newRecordingSession.id);
      newRecordingSession.startSession();
      newRecordingSession.addLog("DeviceInfo", {
        platform: "web-recorder",
        version: FRONTEND_RECORDING_APP_VERSION,
      });

      // Add initial media stream info
      newRecordingSession.addMediaStreamInfo({
        useSharedMedia,
        selectedMicrophoneId: selectedMicrophone?.deviceId,
        streamCreatedAt: new Date().toISOString(),
      });

      currentRecordingSession.current = newRecordingSession;

      const newTranscriptionSessionId = await startTranscriptionSession(
        newRecordingSession.id,
        await getAccessToken()
      );

      if (!newTranscriptionSessionId) {
        console.error("Couldn't get a new transcription session id.");
        throw new Error("Failed to get transcription session ID");
      }

      // Reset elapsed time if new transcription session
      elapsedTime.current = 0;

      setCurrentTranscriptionSessionId(newTranscriptionSessionId);
      newRecordingSession.associateTranscriptionSession(
        newTranscriptionSessionId
      );

      const mediaRecorderCreated = await createMediaRecorder(
        newTranscriptionSessionId
      );
      if (!mediaRecorderCreated) {
        console.error("Failed to create media recorder.");
        throw new Error("Failed to create media recorder");
      }

      setRecordingStatus((prev) => {
        const newStatus = {
          ...prev,
          isStarting: false,
          isRecording: true,
          readyToRecord: false,
          readyToSubmit: true,
          recordingPaused: false,
        };
        sendMessageToExtension({ type: "recordingStatus", data: newStatus });
        return newStatus;
      });

      return true;
    } catch (error) {
      console.error("Error initiating recording:", error);
      SentryService.logEvent("Error initiating recording.", {
        level: "error",
        extra: { error },
      });
      showAlertBanner(
        "Failed to start recording. Check audio settings and try again.",
        "error"
      );
      await cleanUpSession();
      return false;
    }
  };

  // Clean up the recording session
  const cleanUpSession = async () => {
    console.log("Cleaning up session");
    // Clear IndexedDB if the deletion was successful
    await indexedDBManager.clearAll();
    currentRecordingSession.current?.addLog("cleaning up session");

    resetTimer();

    // return recording status state
    setRecordingStatus((prevStatus) => ({
      ...prevStatus,
      isRecording: false,
      readyToRecord: true,
      readyToSubmit: false,
      isStopping: false,
      isStarting: false,
    }));

    setLowDataMode(false);
    setChunkLength(RECORDING_CHUNK_LENGTH_SECONDS);

    isRecordingEnding.current = false;
    currentRecordingSession.current = undefined;

    setNoteInformation({
      templateId: templatesList ? templatesList[0]?.template_id : "",
    });

    // clear audio visualizers
    setAudioData(-1);
  };

  // Helper function to delete a recording session
  const deleteRecordingHelper = async (): Promise<boolean> => {
    try {
      // Attempt to delete the transcription session
      const response = await APIService.makeAPIPostRequest({
        requestString: "/transcriptionSession/deleteTranscriptionSession",
        accessToken: await getAccessToken(),
        body: { transcription_session_id: currentTranscriptionSessionId },
      });

      if (!response.ok) {
        throw new Error("Failed to delete transcription session.");
      }

      await cleanUpSession(); // call cleanup

      return true;
    } catch (error) {
      console.error("Error deleting recording:", error);
      SentryService.logEvent("Error deleting recording.", {
        level: "error",
        extra: { error, transcriptionSessionId: currentTranscriptionSessionId },
      });
      return false;
    }
  };

  // Function to send the recording
  const sendRecording = async (): Promise<string | undefined> => {
    if (!currentTranscriptionSessionId) {
      console.error("No transcription session to submit.");
      return undefined;
    }
    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        console.error("No access token available.");
        return undefined;
      }
      if (loadedInChromeExtension) {
        APIService.changeAppVersion("chrome", "2.0.0");
      }
      const startNoteProcessingSessionResponse =
        await startNoteProcessingSession(
          currentTranscriptionSessionId,
          noteInformation,
          accessToken
        );

      if (!startNoteProcessingSessionResponse) {
        throw new Error(
          `Could not start Note Processing Session. ${startNoteProcessingSessionResponse.error}`
        );
      } else {
        console.log("Note Processing Session Started.");
      }

      // show success dialog
      showAlertBanner("Your note has been successfully submitted!", "success");

      await cleanUpSession(); // call cleanup

      return startNoteProcessingSessionResponse.note_id;
    } catch (error) {
      console.error("Could not start Note Processing Session");
      SentryService.logEvent("Error submitting recording.", {
        level: "error",
        extra: { error, transcriptionSessionId: currentTranscriptionSessionId },
      });

      // show error dialog
      showAlertBanner(
        "Your recording could not be submitted at this time, please try again soon.",
        "error"
      );

      // return recording status state
      setRecordingStatus((prevStatus) => ({
        ...prevStatus,
        readyToSubmit: true,
      }));
      return undefined;
    }
  };

  const attemptRecoverRecordingSession = async () => {
    console.log("Attempting to recover a recording session.");
    const recoveredRecordingSessionId = localStorage.getItem(
      "currentRecordingSessionId"
    );
    if (recoveredRecordingSessionId) {
      const recoveredRecordingSession = await getRecordingSessionFromId(
        recoveredRecordingSessionId
      );
      if (recoveredRecordingSession) {
        console.log(
          `Successfully recovered recording session ${recoveredRecordingSession.id}`
        );
        currentRecordingSession.current = recoveredRecordingSession;
      }
    }
  };

  const getRecordingSessionFromId = async (
    recordingSessionId: string
  ): Promise<RecordingSession | null> => {
    if (recordingSessionId) {
      const recordingSession = await RecordingSession.loadFromStorage(
        recordingSessionId
      );
      if (recordingSession) {
        return recordingSession;
      }
    }

    return null;
  };

  // handle reload case
  useEffect(() => {
    const handleBeforeUnload = async (event: BeforeUnloadEvent) => {
      if (recordingStatus.isRecording) {
        localStorage.setItem("isLeaving", "true");
        setRecordingStatus((prev) => ({ ...prev, recordingPaused: true }));

        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [recordingStatus.isRecording]);

  // Initialize elapsed time and currentRecordingSession from localStorage on component mount
  useEffect(() => {
    const storedElapsedTime = localStorage.getItem("elapsedTime");
    if (storedElapsedTime) {
      elapsedTime.current = parseInt(storedElapsedTime, 10);
    }

    attemptRecoverRecordingSession();
  }, []);

  // Update localStorage when elapsed time changes
  useEffect(() => {
    if (recordingStatus.isRecording) {
      const minutes = Math.floor(elapsedTime.current / 60);
      const seconds = elapsedTime.current % 60;
      const formattedTime = `${minutes}:${String(seconds).padStart(2, "0")}`;
      sendMessageToExtension({ type: "recordingTime", data: formattedTime });
      localStorage.setItem("elapsedTime", elapsedTime.current.toString());
      if (elapsedTime.current >= MAX_RECORDING_LENGTH_SECONDS) {
        SentryService.logEvent("Max recording length exceeded.", {
          level: "info",
          extra: {
            elapsedTime: elapsedTime.current,
            transcriptionSessionId: currentTranscriptionSessionId,
          },
        });
        initiateEndRecording();
      }
    }
  }, [elapsedTime, recordingStatus.isRecording]);

  // Initiate a WebRecordingService instance on load
  useEffect(() => {
    if (!webRecordingInstance.current) {
      webRecordingInstance.current = new WebRecordingService();
    }
  }, []);

  // Set default template
  useEffect(() => {
    if (
      templatesList &&
      templatesList.length > 0 &&
      !noteInformation.templateId
    ) {
      setNoteInformation((prev) => ({
        ...prev,
        templateId: templatesList[0].template_id,
      }));
    }
  }, [templatesList]);

  // Manage timer based on recording status
  useEffect(() => {
    if (recordingStatus.isRecording && !recordingStatus.recordingPaused) {
      startTimer();
    } else {
      stopTimer();
    }

    // Cleanup timer on component unmount
    return () => {
      stopTimer();
    };
  }, [recordingStatus.isRecording, recordingStatus.recordingPaused]);

  useEffect(() => {
    console.log("Updating loadedInChromeExtension", loadedInChromeExtension);
    isLoadedInChromeExtension.current = loadedInChromeExtension;
  }, [loadedInChromeExtension]);

  // Memoize the context value
  const value = useMemo(
    () => ({
      currentTranscriptionSessionId,
      recordingStatus,
      noteInformation,
      setNoteInformation,
      setUseSharedMedia,
      useSharedMedia,
      noAudioStatus,
      selectedMicrophone,
      availableMicrophones,
      microphonePermissionGranted,
      checkMicrophonePermission,
      setSelectedMicrophone,
      setNoAudioStatus,
      getAvailableMicrophones,
      initiateRecording,
      initiateEndRecording,
      sendRecording,
      pauseRecording,
      resumeRecording,
      deleteRecordingHelper,
      startTimer,
      stopTimer,
      resetTimer,
      elapsedTime: elapsedTime.current,
      appointmentType,
      setAppointmentType,
      cleanUpSession,
      releaseMicrophone,
      initiateReupload,
      lowDataMode,
      sendMessageToExtension,
    }),
    [
      recordingStatus,
      noteInformation,
      noAudioStatus,
      selectedMicrophone,
      availableMicrophones,
      microphonePermissionGranted,
      elapsedTime.current,
      appointmentType,
      useSharedMedia,
      lowDataMode,
    ]
  );

  // Provide the context to children components
  return (
    <RecordingContext.Provider value={value}>
      {children}
    </RecordingContext.Provider>
  );
};

interface UploadResult {
  success: boolean;
  error?: string;
  chunk_data: {
    chunk_size: number;
    chunk_type: string;
  };
}

const uploadAudioAsync = async (
  blob: Blob,
  upload_url: string,
  content_type: string
): Promise<UploadResult> => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
  }, 45000);

  try {
    const options: RequestInit = {
      method: "PUT",
      body: blob,
      headers: {
        "Content-Type": content_type,
      },
      signal: controller.signal,
    };

    const uploadResponse = await fetch(upload_url, options);

    if (!uploadResponse.ok) {
      throw new Error(`HTTP error! status: ${uploadResponse.status}`);
    }

    console.log(`S3 upload response: ${uploadResponse.status}`);

    return {
      success: true,
      chunk_data: { chunk_size: blob.size, chunk_type: blob.type },
    };
  } catch (error: unknown) {
    let errorMessage: string;

    if (error instanceof Error) {
      errorMessage = error.message;
    } else {
      errorMessage = String(error);
    }

    if (error instanceof DOMException && error.name === "AbortError") {
      errorMessage = "Upload timed out after 45 seconds";
    }

    console.error("Upload failed:", errorMessage);

    return {
      success: false,
      error: errorMessage,
      chunk_data: { chunk_size: blob.size, chunk_type: blob.type },
    };
  } finally {
    clearTimeout(timeoutId);
  }
};

// Helper function to add a chunk to the transcription session
export const addChunkToTranscriptionSession = async (
  transcriptionSessionId: string,
  chunkId: string,
  chunkPosition: number,
  s3_object_id: string,
  accessToken?: string | null
) => {
  const transcriptionSessionResponse = await APIService.makeAPIPostRequest({
    requestString: "/transcriptionSession/addChunkToSession",
    accessToken,
    body: {
      chunk_id: chunkId,
      chunk_position: chunkPosition,
      transcription_session_id: transcriptionSessionId,
      s3_object_id: s3_object_id,
    },
  });

  if (transcriptionSessionResponse.ok) {
    return true;
  } else {
    return false;
  }
};
