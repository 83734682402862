import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    Typography, Box, FormControl, InputLabel, Select, MenuItem, CircularProgress, Paper,
    Grid, Fade, Alert, SelectChangeEvent, AlertTitle
  } from '@mui/material';
import APIService from '../../services/APIService';
import { useUser } from '../../context/user';
import { formatDate } from '../../utils/utils';
import { Patient, NoteDataType, ProviderBillingInfo } from '../../types/types';
import { RoundedButton } from '../../styles/CustomButtons';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { PieChart, Pie, Cell, ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, LabelList } from 'recharts';
import { ChartsLegend, PiecewiseColorLegend } from '@mui/x-charts';

interface PatientEligibilityViewProps {
  onPatientEligibilitySave: (patRel: string) => void;
  initialPatRel: string;
  patient?: Patient | null;
  note?: NoteDataType;
  providerBillingInfo?: ProviderBillingInfo;
  billingCodeInfo?: Record<string, string>;
}

// Move these outside the component to persist across mounts
const eligibilityCacheRef: Record<string, any> = {};
let lastFetchedPatRelRef:string = '';

const formatCurrencyWithCommas = (value: string | number): string => {
  const numValue = typeof value === 'string' ? parseFloat(value.replace(/[^0-9.-]+/g, "")) : value;
  if (isNaN(numValue)) return 'N/A';
  return `$${numValue.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}`;
};

const PatientEligibilityView: React.FC<PatientEligibilityViewProps> = ({
  onPatientEligibilitySave,
  initialPatRel,
  patient: initialPatient,
  note: initialNote,
  providerBillingInfo: initialProviderBillingInfo,
  billingCodeInfo: initialBillingCodeInfo,
}) => {
  const [patRel, setPatRel] = useState(initialPatRel);
  const [eligibilityData, setEligibilityData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loadingTimeout, setLoadingTimeout] = useState(false);

  const { getAccessToken } = useUser();

  const fetchEligibilityData = useCallback(async () => {
    if (!initialPatient || !initialNote || !initialProviderBillingInfo || !initialBillingCodeInfo || !patRel) {
      return;
    }   

    // Only fetch if patRel or any related information has changed
    const currentKey = `${patRel}-${initialPatient?.payer_id}-${initialNote?.time_uploaded}-${initialProviderBillingInfo?.npi_number}-${initialBillingCodeInfo?.top_code}`;
    
    // Check if we already have data for this patRel in the cache
    if (eligibilityCacheRef[currentKey]) {
        setEligibilityData(eligibilityCacheRef[currentKey]);
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 10000);
        return;
      }

    if (currentKey === lastFetchedPatRelRef) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const accessToken = await getAccessToken();
      await new Promise(resolve => setTimeout(resolve, 6000)); // 2000 ms = 2 seconds

      const response = await APIService.makeAPIPostRequest({
        requestString: '/billing/eligibility/request',
        accessToken: accessToken,
        body: {
          ins_name_l: initialPatient.last_name,
          ins_name_f: initialPatient.first_name,
          payerid: initialPatient.payer_id,
          pat_rel: patRel === 'self' ? '18' : 'G8',
          fdos: new Date(initialNote.time_uploaded * 1000).toISOString().slice(0, 10).replace(/-/g, ''),
          prov_npi: initialProviderBillingInfo.npi_number,
          prov_taxid: initialProviderBillingInfo.tax_id,
          proc_code: initialBillingCodeInfo.top_code,
        },
      });

      console.log("Response:", response);

      if (response.ok) {
        const newEligibilityData = response.value.response_data;
        setEligibilityData(newEligibilityData);
        
        // Update the cache and last fetched patRel
        eligibilityCacheRef[currentKey] = newEligibilityData;
        lastFetchedPatRelRef = currentKey;
        
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 10000);
      }
        else {
        // console.error("Error fetching eligibility data:", response);
        setError(response.error.message);
        return;
      }
    } catch (error) {
      console.error("Error fetching eligibility data:", error);
      setError("Failed to fetch eligibility data. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [initialPatient, initialNote, initialProviderBillingInfo, initialBillingCodeInfo?.top_code, patRel, getAccessToken]);

  useEffect(() => {
    fetchEligibilityData();
  }, [fetchEligibilityData]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (loading) {
      timeout = setTimeout(() => {
        setLoadingTimeout(true);
      }, 4000);
    } else {
      setLoadingTimeout(false);
    }
    return () => clearTimeout(timeout);
  }, [loading]);

  const handleSelectChange = (event: SelectChangeEvent) => {
    const newPatRel = event.target.value;
    setPatRel(newPatRel);
    
    // If we have cached data for this patRel, use it immediately
    if (eligibilityCacheRef[newPatRel]) {
      setEligibilityData(eligibilityCacheRef[newPatRel]);
    }
  };

  const handleSave = () => {
    onPatientEligibilitySave(patRel);
  };

  
  const InfoBox: React.FC<{ title: string; data: Record<string, any> }> = ({
    title,
    data,
  }) => (
    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Grid container spacing={2}>
        {Object.entries(data).map(([key, value]) => (
          <Grid item xs={6} key={key}>
            <Typography variant="subtitle2">
              {key.replace(/_/g, " ").toUpperCase()}
            </Typography>
            <Typography>
              {key.toLowerCase().includes('deductible') || key.toLowerCase().includes('maximum') || key.toLowerCase().includes('copay')
                ? formatCurrencyWithCommas(value)
                : value || "N/A"}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );

  const formatCurrency = (value: string) => {
    const numValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));
    return isNaN(numValue) ? 0 : numValue;
  };

  const renderDeductibleBarChart = (type: 'Individual' | 'Family') => {
    if (!eligibilityData) return null;

    const createData = (network: 'In-Network' | 'Out-of-Network') => ({
      name: network,
      deductible: formatCurrency(eligibilityData.General_Coverage[`${type} Deductible ${network}`]),
      outOfPocketMax: eligibilityData.General_Coverage[`${type} Out-of-Pocket Maximum ${network}`] !== "N/A" 
        ? formatCurrency(eligibilityData.General_Coverage[`${type} Out-of-Pocket Maximum ${network}`]) 
        : null,
    });

    const inNetworkData = createData('In-Network');
    const outOfNetworkData = createData('Out-of-Network');

    const maxValue = 100000;

    const renderChart = (data: any, index: number) => (
      <Box key={index} sx={{ height: 300, width: '50%', mt: 2 }}>
        <Typography variant="h6" gutterBottom>{`${type} ${data.name}`}</Typography>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={[data]}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis domain={[0, maxValue]} tickFormatter={(value) => formatCurrencyWithCommas(value)} />
            <Tooltip formatter={(value: string | number) => formatCurrencyWithCommas(value)} />
            {/* <Legend 
                payload={[
                { value: `${type} Deductible`, type: 'square', color: '#8884d8' },
                { value: `${type} Out-of-Pocket Maximum`, type: 'line', color: 'red' }
                ]}
                verticalAlign="bottom"
          /> */}
            <Bar dataKey="deductible" fill="#8884d8" name={`${type} Deductible`}>
              <LabelList dataKey="deductible" position="top" fill="8884d8" formatter={(value: string | number) => formatCurrencyWithCommas(value)} style={{ zIndex: 1 }} />
            </Bar>
            
            {data.outOfPocketMax !== null && (
              <ReferenceLine
                y={data.outOfPocketMax}
                stroke="red"
                strokeDasharray="3 3"
                ifOverflow="extendDomain"
                label={{
                  position: 'insideTopRight',
                  fill: 'red',
                  value: `${formatCurrencyWithCommas(data.outOfPocketMax)}`,
                  fontSize: 12,
                  dy: -15 
                }}
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </Box>
    );

    return (
      <Box >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          {renderChart(inNetworkData, 0)}
          {renderChart(outOfNetworkData, 1)}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1vh 0' , mt: 2}}>
        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '2vh' }}>
          <Box sx={{ width: 20, height: 20, backgroundColor: '#8884d8', marginRight: '0.5vh' }} />
          <Typography>{`${type} Deductible`}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: 20, height: 2, backgroundColor: 'red', marginRight: '0.5vh' }} />
          <Typography>{`${type} Out-of-Pocket Maximum`}</Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderCoinsuranceDonutChart = (type: 'In-Network' | 'Out-of-Network') => {
    if (!eligibilityData) return null;

    const coinsurance = parseInt(eligibilityData.General_Coverage[`Coinsurance ${type}`]) || 0;
    const patientResponsibility = coinsurance;
    const insuranceCoverage = 100 - patientResponsibility;

    const data = [
    { name: 'Insurance Coverage', value: insuranceCoverage, color: '#36A2EB' },
    { name: 'Patient Responsibility', value: patientResponsibility, color: '#FF6384' },
    
    ];

    return (
      <Box sx={{ minHeight: '200px', width: '50%', position: 'relative' }}>
        <Typography variant="h6" gutterBottom>{`${type} Coinsurance`}</Typography>
        <ResponsiveContainer height="80%">
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip formatter={(value) => `${value}%`} />
            {/* <Legend /> */}
          </PieChart>
        </ResponsiveContainer>
        <Typography
          variant="h4"
          component="div"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, 0%)', // Adjusted to center vertically
            textAlign: 'center',
            color: '#FF6384',
            fontWeight: 'bold',
          }}
        >
          {`${patientResponsibility}%`}
        </Typography>
      </Box>
    );
  };

  const renderOfficeVisitCopay = () => {
    if (!eligibilityData) return null;

    const inNetworkCopay = eligibilityData.General_Coverage["Office Visit Copay In-Network"];
    const outOfNetworkCopay = eligibilityData.General_Coverage["Office Visit Copay Out-of-Network"];

    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>Office Visit Copay</Typography>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">In-Network</Typography>
              <Typography variant="h5">{formatCurrencyWithCommas(inNetworkCopay)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Out-of-Network</Typography>
              <Typography variant="h5">{formatCurrencyWithCommas(outOfNetworkCopay)}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Alert severity="warning" sx={{ mb: 2, backgroundColor: '#fff3cd', color: '#856404' }}>
        <AlertTitle>Disclaimer</AlertTitle>
        Billing eligibility is a beta feature and JotPsych does not guarantee the completeness or accuracy of the below information. The below information represents our best understanding of your patient's insurance coverage from our third party billing partner.
      </Alert>

      <Box sx={{ flexGrow: 1, overflowY: 'auto', pb: 2 }}>
        <Typography variant="h4" gutterBottom>
          Patient Eligibility Information
        </Typography>

        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
          <InputLabel id="pat-rel-label">PATIENT RELATIONSHIP</InputLabel>
          <Select
            labelId="pat-rel-label"
            name="pat_rel"
            value={patRel}
            onChange={handleSelectChange}
          >
            <MenuItem value="self">Self</MenuItem>
            <MenuItem value="dependent">Dependent</MenuItem>
          </Select>
        </FormControl>

        {loading ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
            <CircularProgress />
            <Typography variant="body1" sx={{ mt: 2 }}>
              Getting insurance information...
            </Typography>
            {loadingTimeout && (
              <Typography variant="body2" sx={{ mt: 1, color: '#FFA500' }}>
                This is taking more time than expected.
              </Typography>
            )}
          </Box>
        ) : (
          <>
            <Fade in={showSuccessMessage}>
              <Alert
                icon={<CheckCircleOutlineIcon fontSize="inherit" />}
                severity="success"
                sx={{ mb: 2 }}
              >
                Insurance information retrieved successfully!
              </Alert>
            </Fade>

            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}

            {eligibilityData && (
              <>
                {renderDeductibleBarChart('Individual')}
                {renderDeductibleBarChart('Family')}
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  {renderCoinsuranceDonutChart('In-Network')}
                  {renderCoinsuranceDonutChart('Out-of-Network')}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1vh 0' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '2vh' }}>
                    <Box sx={{ width: 20, height: 20, backgroundColor: '#36A2EB', marginRight: '0.5vh' }} />
                    <Typography>Insurance Coverage</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: 20, height: 20, backgroundColor: '#FF6384', marginRight: '5px' }} />
                    <Typography>Patient Responsibility</Typography>
                  </Box>
                </Box>
                {renderOfficeVisitCopay()}
                
                <Typography variant="h6" sx={{ mt: 3 }} gutterBottom>General coverage:</Typography>
                <InfoBox
                  title={`Plan: ${eligibilityData.General_Coverage['Plan']}`}
                  data={{
                    // "Plan": eligibilityData.General_Coverage["Plan"],
                    "Individual Deductible In-Network": eligibilityData.General_Coverage["Individual Deductible In-Network"],
                    "Individual Deductible Out-of-Network": eligibilityData.General_Coverage["Individual Deductible Out-of-Network"],
                    "Individual Out-of-Pocket Maximum In-Network": eligibilityData.General_Coverage["Individual Out-of-Pocket Maximum In-Network"],
                    "Individual Out-of-Pocket Maximum Out-of-Network": eligibilityData.General_Coverage["Individual Out-of-Pocket Maximum Out-of-Network"],
                    "Family Deductible In-Network": eligibilityData.General_Coverage["Family Deductible In-Network"],
                    "Family Deductible Out-of-Network": eligibilityData.General_Coverage["Family Deductible Out-of-Network"],
                    "Family Out-of-Pocket Maximum In-Network": eligibilityData.General_Coverage["Family Out-of-Pocket Maximum In-Network"],
                    "Family Out-of-Pocket Maximum Out-of-Network": eligibilityData.General_Coverage["Family Out-of-Pocket Maximum Out-of-Network"],
                    "Coinsurance In-Network": eligibilityData.General_Coverage["Coinsurance In-Network"],
                    "Coinsurance Out-of-Network": eligibilityData.General_Coverage["Coinsurance Out-of-Network"],
                    "Office Visit Copay In-Network": eligibilityData.General_Coverage["Office Visit Copay In-Network"],
                    "Office Visit Copay Out-of-Network": eligibilityData.General_Coverage["Office Visit Copay Out-of-Network"],
                  }}
                />

                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>Mental Health (MH) coverage:</Typography>
                <InfoBox
                  title=""
                  data={{
                    "Status": eligibilityData.Mental_Health_Coverage["Status"],
                    "Network": eligibilityData.Mental_Health_Coverage["Network"],
                    "Specific copay/coinsurance": eligibilityData.Mental_Health_Coverage["Specific copay/coinsurance"] || "Not specified, likely follows general coverage",
                    "Visit limits": eligibilityData.Mental_Health_Coverage["Visit limits"] || "Not specified",
                    "Preauthorization requirements": eligibilityData.Mental_Health_Coverage["Preauthorization requirements"] || "Not specified",
                  }}
                />

                <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
                  Note: Verify specific MH benefits with insurer. General coverage terms likely apply.
                </Typography>
              </>
            )}
          </>
        )}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, pt: 2, borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <RoundedButton
          variant="contained"
          sx={{ fontSize: '0.85rem', fontWeight: '700', whiteSpace: 'nowrap', px: 2 }}
          color="primary"
          onClick={handleSave}
          disabled={!patRel || loading}
        >
          Save Eligibility Information
        </RoundedButton>
      </Box>
    </Box>
  );
};

export default React.memo(PatientEligibilityView);
