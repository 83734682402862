import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Checkbox,
  LinearProgress,
  Paper,
  Modal,
  IconButton,
  Stack,
  Link,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { useUser } from "../context/user";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import VideoIcon from "@mui/icons-material/Videocam"; // Use an icon from MUI
import CloseIcon from "@mui/icons-material/Close";
import YouTube from "react-player/youtube";
import { DashboardCard } from "../Views/Shared/DashboardCard";
import APIService from "../services/APIService";
import { trackEvent } from "../utils/analytics_utils";

interface Task {
  is_completed: boolean;
  task_id: string;
  title: string;
  description: string | null;
  video_link: string | null;
  navigation_link: string | null;
  target_value: number | null;
  seq: number;
}

interface OnboardingStatus {
  tasks: Task[];
  show_onboarding_tasks: boolean;
  apply_discount?: boolean;
  discount_amount?: number;
  stripe_current_period_end?: number;
}

const OnboardingTasksCard: React.FC = () => {
  const { userState, getAccessToken } = useUser();
  const [onboardingStatus, setOnboardingStatus] = useState<OnboardingStatus>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchOnboardingStatus = async () => {
      try {
        const accessToken = await getAccessToken();
        const response = await APIService.makeAPIGetRequest({
          requestString: "/user/getOnboardingStatus",
          accessToken,
        });

        if (response.ok) {
          const data = await response.value.onboarding_status;
          setOnboardingStatus(data);
        }
      } catch (error) {
        console.error("Error fetching onboarding status:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOnboardingStatus();
  }, []);

  const tasks = onboardingStatus?.tasks?.sort((a, b) => a.seq - b.seq) || [];

  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedTask, setSelectedTask] = React.useState<Task | null>(null);

  const handleOpenModal = (task: Task) => {
    trackEvent({
      event: `onboarding_task_explanation_modal_open_${task.task_id}`,
    });
    setSelectedTask(task);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedTask(null);
  };

  const taskExplanationModal = (task: Task) => {
    return (
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            maxWidth: "90%",
            maxHeight: "90%",
            bgcolor: "background.paper",
            position: "relative",
            p: 3,
            overflowY: "auto",
            borderRadius: 1,
          }}
        >
          <IconButton
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 16,
              top: 16,
              color: "grey.500",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box
            sx={{
              display: "flex",
              height: "100%",
              pt: 4,
            }}
          >
            <Box sx={{ width: "70%", pr: 2 }}>
              {task.video_link && (
                <YouTube
                  url={task.video_link}
                  width="100%"
                  height="100%"
                  controls
                  onPlay={() => {
                    trackEvent({
                      event: `onboarding_task_video_play_${task.task_id}`,
                    });
                  }}
                />
              )}
            </Box>

            <Box
              sx={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ flex: 3, overflow: "auto", pr: 2 }}>
                <Typography variant="h5" gutterBottom>
                  {task.title}
                </Typography>
                <Typography variant="body1">{task.description}</Typography>
              </Box>

              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {task.navigation_link && (
                  <Button
                    variant="contained"
                    color="primary"
                    href={task.navigation_link}
                    target="_blank"
                    sx={{ width: "100%" }}
                  >
                    Go to Task
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };

  const completedTasks = tasks.filter((task) => task.is_completed).length;
  const progressPercentage =
    tasks.length > 0 ? (completedTasks / tasks.length) * 100 : 0;

  if (isLoading) {
    return (
      <DashboardCard
        customStyles={{
          padding: 2,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
          Getting Started
        </Typography>
        <Box sx={{ flex: 1 }}>
          {[...Array(4)].map((_, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              mb={1.5}
              pb={0.5}
              borderBottom={1}
              borderColor="#111"
            >
              <Skeleton
                variant="circular"
                width={24}
                height={24}
                sx={{ mr: 2 }}
              />
              <Skeleton variant="text" width="80%" height={24} />
            </Box>
          ))}
        </Box>
        <Box sx={{ mt: "auto", pt: 2 }}>
          <Skeleton variant="rectangular" width="100%" height={24} />
        </Box>
      </DashboardCard>
    );
  }

  return (
    <DashboardCard
      customStyles={{
        padding: 2,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
          Getting Started
        </Typography>
        {!onboardingStatus ||
        !onboardingStatus.tasks ||
        !onboardingStatus.show_onboarding_tasks ? (
          <Stack spacing={2}>
            <Typography variant="body1" gutterBottom>
              Congratulations! 🎉 You're finished with the current tasks! We'll
              periodically update this section with more tasks to teach you
              about new JotPsych features and to allow you to earn additional
              discounts.
            </Typography>
            <Typography variant="body1" display="block">
              You can always revisit our tutorial videos{" "}
              <Link
                href="https://www.youtube.com/playlist?list=PLB4UiHZ17HV8cUdi3JFNWV9lSqKI-0AUt"
                target="_blank"
              >
                here
              </Link>
              .
            </Typography>
            {onboardingStatus?.stripe_current_period_end &&
              onboardingStatus?.stripe_current_period_end * 1000 >
                Date.now() && (
                <Typography variant="body2" display="block">
                  Your ${onboardingStatus?.discount_amount! / 100} discount will
                  be applied to your next billing cycle.
                </Typography>
              )}
          </Stack>
        ) : (
          <>
            <Box
              sx={{
                flex: 1,
                overflowY: "auto",
                mb: 2,
              }}
            >
              {tasks.map((task) => (
                <Box
                  key={task.task_id}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={1.5}
                  pb={0.5}
                  borderBottom={1}
                  borderColor="#111"
                >
                  <Box display="flex" alignItems="center" flexGrow={1}>
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={
                        <CheckCircleIcon sx={{ color: "#4CAF50" }} />
                      }
                      checked={task.is_completed}
                      sx={{
                        color: "white",
                        "& .MuiSvgIcon-root": { fontSize: "1.2rem" },
                      }}
                      disabled
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        flexGrow: 1,
                      }}
                    >
                      {task.title}{" "}
                      {task.task_id.includes("first_n_notes_created") &&
                        `(${userState?.userMetrics?.notes_created || 0}/${
                          task.target_value
                        })`}
                    </Typography>
                    {task.video_link && task.video_link !== "" && (
                      <Button
                        sx={{
                          color: "black",
                          marginLeft: 2,
                          mr: 2,
                          "&.Mui-disabled": {
                            color: "rgba(0, 0, 0, 0.3)",
                          },
                        }}
                        onClick={() => handleOpenModal(task)}
                      >
                        <VideoIcon />
                      </Button>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>

            {/* Progress section at bottom */}
            <Box
              sx={{
                mt: "auto",
                pt: 2,
                borderTop: 1,
                borderColor: "borderColors.primary",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box sx={{ flexGrow: 1, position: "relative" }}>
                  <LinearProgress
                    variant="determinate"
                    value={progressPercentage}
                    sx={{
                      height: 24,
                      borderRadius: 5,
                      "& .MuiLinearProgress-bar": {
                        bgcolor: "newDesignColors.brand",
                        color: "textColors.brandContrast",
                        borderRadius: 5,
                      },
                    }}
                  />
                  <Typography
                    variant="caption"
                    sx={{
                      color: "textColors.brandContrast",
                      fontWeight: "bold",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    {Math.round(progressPercentage)}%
                  </Typography>
                </Box>

                {!onboardingStatus.apply_discount &&
                  onboardingStatus.discount_amount && (
                    <Tooltip
                      title="Complete all of the above tasks to get a discount on your next month's subscription"
                      placement="top"
                      arrow
                    >
                      <Box
                        sx={{
                          textAlign: "center",
                          p: 1,
                          borderRadius: 1,
                          whiteSpace: "nowrap",
                        }}
                      >
                        ${onboardingStatus.discount_amount / 100} off next month
                      </Box>
                    </Tooltip>
                  )}
              </Box>
            </Box>
          </>
        )}
      </Box>
      {selectedTask && taskExplanationModal(selectedTask)}
    </DashboardCard>
  );
};

export default OnboardingTasksCard;
