/**
 * @file src/web-app/src/components/Shared/HoverContent.tsx
 * @description This file contains the HoverContent React component: It is used to display the module/section example.
 *
 * Author: Soham Dave
 * Date: 2024-10-03
 */

import { Box, SxProps, Typography } from "@mui/material";
import { NoteSectionDisplayBox } from "../Note/NoteSectionDisplayBox";
import { Close } from "@mui/icons-material";

// New HoverContent component
interface HoverContentProps {
  title: string;
  content: string;
  sx?: SxProps;
}
export const HoverContent = ({ title, content, sx }: HoverContentProps) => (
  <Box
    className="hover-content"
    sx={{
      position: "absolute",
      left: 0,
      width: "100%",
      opacity: 0,
      visibility: "hidden",
      transition: "opacity 0.2s, visibility 0.2s",
      zIndex: 1,
      backgroundColor: "white",
      boxShadow: "0 0.8vh 0.8vh rgba(0, 0, 0, 0.5)",
      borderRadius: "1.5vh",
      border: "0.15vh solid",
      borderColor: "divider",
      padding: "0.8vh",
      pointerEvents:
        sx?.["visibility" as keyof typeof sx] === "visible" ? "auto" : "none",
      ...sx,
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Close fontSize="large" sx={{ mr: 4, cursor: "pointer" }} />
    </Box>
    <NoteSectionDisplayBox fontSize={10}>{content}</NoteSectionDisplayBox>
  </Box>
);
