import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Logo from "../Icons/Logo";
import { AccountCircle, Visibility } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { AdminSection, AvailableModule } from "../../types/types";
import { Draggable } from "react-beautiful-dnd";

import { useThemeContext } from "../../context/theme/context";
import { Close, DragIndicator, Star } from "@mui/icons-material";
import { HoverContent } from "../Shared/HoverContent";
import { getUserInfoFromLocalStorage } from "../../utils/LocalStorageUtils";

interface SectionBlockProps {
  section: AdminSection;
  handleClick?: () => void;
  handleRevert?: () => void;
  availableModules?: AvailableModule[];
  templateModuleMap?: { [key: string]: string };
}

export const SectionBlock = ({
  section,
  handleClick,
  handleRevert,
  availableModules,
  templateModuleMap,
}: SectionBlockProps) => {
  const [showDetails, setShowDetails] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const moduleInfo = availableModules?.find(
    (module) => module.module_id === section.module_id
  );

  return (
    <Card
      sx={{
        my: 1.5,
        borderColor: "borderColors.primary",
        border: "0.5px solid",
        borderRadius: "0.4cm",
        "&:hover": {
          boxShadow: 3,
        },
      }}
      onClick={(e) => {
        if (!e.defaultPrevented) {
          setShowPreview(false);
          if (handleClick) handleClick();
        }
      }}
    >
      <CardContent
        sx={{ py: 1, "&.MuiCardContent-root:last-child": { pb: 1 } }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box
            id="module-text"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              flexGrow: 1,
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography
                fontStyle="normal"
                fontWeight="700"
                sx={{
                  cursor: "pointer",
                  display: "inline-block",
                }}
              >
                {section.display_name}
              </Typography>
              {showDetails && (
                <Tooltip title="Preview" placement="top" arrow>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPreview(!showPreview);
                    }}
                    size="small"
                  >
                    <Visibility fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            {showDetails && moduleInfo && (
              <>
                <Typography
                  fontSize=".8rem"
                  fontWeight="300"
                  sx={{ color: "text.secondary" }}
                >
                  {moduleInfo?.description || moduleInfo?.public_description}
                </Typography>
                <HoverContent
                  title={section.display_name}
                  content={
                    moduleInfo?.example || moduleInfo?.public_example || ""
                  }
                  sx={{
                    opacity: showPreview ? 1 : undefined,
                    visibility: showPreview ? "visible" : "hidden",
                  }}
                />
              </>
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            {templateModuleMap && templateModuleMap[section.module_id] && (
              <Tooltip
                title="This section has been customized."
                placement="top"
                arrow
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    "&:hover .revert-button": {
                      display: "inherit",
                    },
                  }}
                >
                  <Star fontSize="small" />
                  <Typography fontSize={12}>Customized</Typography>
                  {handleRevert && (
                    <Button
                      variant="text"
                      className="revert-button"
                      sx={{ display: "none" }}
                      onClick={handleRevert}
                    >
                      <Typography fontSize={10}>Revert?</Typography>
                    </Button>
                  )}
                </Box>
              </Tooltip>
            )}
            {moduleInfo?.is_custom_user_module ? (
              <Tooltip
                title="This is your customized section."
                placement="top"
                arrow
              >
                <Avatar
                  variant="circular"
                  src={getUserInfoFromLocalStorage()?.img}
                  alt="user photo"
                  sx={{ width: 25, height: 25 }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="This is a brand section." placement="top" arrow>
                <Box>
                  <Logo size={22} />
                </Box>
              </Tooltip>
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setShowDetails(!showDetails);
            }}
            size="small"
          >
            {showDetails ? "Show less" : "Learn more"}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

interface DraggableSectionBlockProps {
  section: AdminSection;
  index: number;
  handleClick?: () => void;
  handleRemove?: () => void;
  handleRevert?: () => void;
  availableModules?: AvailableModule[];
  templateModuleMap?: { [key: string]: string };
}

export const DraggableSectionBlock = ({
  section,
  index,
  handleClick,
  handleRemove,
  handleRevert,
  availableModules,
  templateModuleMap,
}: DraggableSectionBlockProps) => {
  const [showDetails, setShowDetails] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const moduleInfo = availableModules?.find(
    (module) => module.module_id === section.module_id
  );
  const { brandLanguage } = useThemeContext();
  const userInfo = getUserInfoFromLocalStorage();

  return (
    <Draggable
      key={section.module_id}
      draggableId={section.module_id}
      index={index}
    >
      {(provided) => (
        <Card
          ref={provided.innerRef}
          {...provided.draggableProps}
          sx={{
            my: 1.5,
            borderColor: "borderColors.primary",
            border: "0.5px solid",
            borderRadius: "0.4cm",
            "&:hover": {
              boxShadow: 3,
            },
          }}
          onClick={(e) => {
            if (!e.defaultPrevented) {
              setShowPreview(false);
              if (handleClick) handleClick();
            }
          }}
        >
          <CardContent
            sx={{ py: 1, "&.MuiCardContent-root:last-child": { pb: 1 } }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Box
                id="drag-handle"
                {...provided.dragHandleProps}
                sx={{ marginLeft: -1, marginRight: 0.5 }}
              >
                <DragIndicator />
              </Box>
              <Box
                id="module-text"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  flexGrow: 1,
                  gap: 1,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography
                    fontStyle="normal"
                    fontWeight="700"
                    sx={{
                      cursor: "pointer",
                      display: "inline-block",
                      my: 0.2,
                    }}
                  >
                    {section.display_name}
                  </Typography>
                  {showDetails && (
                    <Tooltip title="Preview" placement="top" arrow>
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          setShowPreview(!showPreview);
                        }}
                        size="small"
                      >
                        <Visibility fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
                {showDetails && moduleInfo && (
                  <>
                    <Typography
                      fontSize=".8rem"
                      fontWeight="300"
                      sx={{ color: "text.secondary" }}
                    >
                      {moduleInfo?.description ||
                        moduleInfo?.public_description}
                    </Typography>
                    <HoverContent
                      title={section.display_name}
                      content={
                        moduleInfo?.example || moduleInfo?.public_example || ""
                      }
                      sx={{
                        opacity: showPreview ? 1 : undefined,
                        visibility: showPreview ? "visible" : "hidden",
                      }}
                    />
                  </>
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                {moduleInfo?.is_custom_user_module ? (
                  <Tooltip
                    title="This is your customized section."
                    placement="top"
                    arrow
                  >
                    <Avatar
                      variant="circular"
                      src={userInfo?.img}
                      alt="user photo"
                      sx={{ width: 25, height: 25 }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={`This is a ${brandLanguage.brandName} section.`}
                    placement="top"
                    arrow
                  >
                    <Box>
                      <Logo size={22} />
                    </Box>
                  </Tooltip>
                )}
                {templateModuleMap && templateModuleMap[section.module_id] && (
                  <Tooltip
                    title="This section has been customized."
                    placement="top"
                    arrow
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        "&:hover .revert-button": {
                          display: "inherit",
                        },
                      }}
                    >
                      <Star fontSize="small" />
                      <Typography fontSize={12}>Customized</Typography>
                      {handleRevert && (
                        <Button
                          variant="text"
                          className="revert-button"
                          sx={{ display: "none" }}
                          onClick={handleRevert}
                        >
                          <Typography fontSize={10}>Revert?</Typography>
                        </Button>
                      )}
                    </Box>
                  </Tooltip>
                )}
                <IconButton onClick={handleRemove} size="small">
                  <Close fontSize="small" />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setShowDetails(!showDetails);
                }}
                size="small"
              >
                {showDetails ? "Show less" : "Learn more"}
              </Button>
            </Box>
          </CardContent>
        </Card>
      )}
    </Draggable>
  );
};
