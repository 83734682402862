import React, { useState } from "react";
import {
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Chip,
  Paper,
  Typography,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import { Add } from "@mui/icons-material";

interface BillingCodeSectionProps {
  index: number;
  billingField: {
    code: string;
    chargeAmount: string;
    units: string;
    modifier: string;
    diagnosisCodes: string[];
  };
  onFieldChange: (index: number, field: string, value: string) => void;
  onAddDiagnosisCode: (index: number, code: string) => void;
  onRemoveDiagnosisCode: (index: number, code: string) => void;
  onDeleteSection: (index: number) => void;
}

const BillingCodeSection: React.FC<BillingCodeSectionProps> = ({
  index,
  billingField,
  onFieldChange,
  onAddDiagnosisCode,
  onRemoveDiagnosisCode,
  onDeleteSection,
}) => {
  const [diagnosisCodeInput, setDiagnosisCodeInput] = useState("");

  const handleAddDiagnosisCode = () => {
    const value = diagnosisCodeInput.trim();
    if (value) {
      onAddDiagnosisCode(index, value);
      setDiagnosisCodeInput("");
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="h6" gutterBottom>
          {billingField.code || `New Billing Code`}
        </Typography>
        <IconButton onClick={() => onDeleteSection(index)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Billing Code"
            value={billingField.code}
            onChange={(e) => onFieldChange(index, "code", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Charge Amount"
            value={billingField.chargeAmount}
            onChange={(e) =>
              onFieldChange(index, "chargeAmount", e.target.value)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Units"
            value={billingField.units}
            onChange={(e) => onFieldChange(index, "units", e.target.value)}
            type="number"
            inputProps={{ min: "1" }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Modifier"
            value={billingField.modifier}
            onChange={(e) => onFieldChange(index, "modifier", e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Diagnosis Codes:</Typography>
          {billingField.diagnosisCodes.map((code) => (
            <Chip
              key={code}
              label={code}
              sx={{ mr: 1, mb: 1 }}
              onDelete={() => onRemoveDiagnosisCode(index, code)}
            />
          ))}
          <TextField
            fullWidth
            label="Add Diagnosis Code"
            value={diagnosisCodeInput}
            onChange={(e) => setDiagnosisCodeInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleAddDiagnosisCode();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleAddDiagnosisCode}>
                    <Add />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            id={`diagnosis-input-${index}`}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BillingCodeSection;
