import { Box } from "@mui/material";
import ClaimSubmittedList from "../components/Billing/ClaimSubmittedList";
import { useUser } from "../context/user";
import LayoutWrapper from "../components/layout/UILayout";
import { DismissableCard } from "./Shared/DismissableCard";

export const ClaimSubmittedView = () => {
  const { userState } = useUser();

  if (!userState?.featureFlags?.billing_feature) {
    return <>You don't have access to this feature.</>;
  }

  return (
    <LayoutWrapper>
      <LayoutWrapper.MainContent>
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <Box sx={{ width: { xs: "100%", md: "70%" } }}>
            <ClaimSubmittedList />
          </Box>
        </Box>
      </LayoutWrapper.MainContent>
      <LayoutWrapper.RightSidebar>
        <DismissableCard
          headerTitle="Who can see this information?"
          bodyText={[
            "JotPsych shows claims that have been submitted to insurance companies. This includes claims that are either accepted or rejected.",
          ]}
          learnMoreLink="https://www.jotpsych.com/security-and-privacy"
          tooltipMessage="Learn more about our security practices."
        />
        <DismissableCard
          headerTitle="Claim Submitted"
          bodyText={[
            "Here, you can view a list of all claims that have been submitted to insurance companies.",
          ]}
        />
      </LayoutWrapper.RightSidebar>
    </LayoutWrapper>
  );
};
