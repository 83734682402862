import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Skeleton,
  Button,
  Grid,
  Divider,
  Paper,
} from '@mui/material';
import { API_BASE_URL } from '../../services/APIService'; // Assuming you have an API base URL defined
import { getUserInfoFromLocalStorage } from "../../utils/LocalStorageUtils";
import { NoteSectionDisplayBox } from "../../components/Note/NoteSectionDisplayBox";
import { JotPalWordmarkLogo, JotPsychWordmarkLogo } from "../../components/Icons/Logo";
import { useThemeContext } from "../../context/theme/context";
import CustomButton from "../../styles/CustomButtons";

interface Template {
  template_id: string;
  template_name: string;
  public_name: string;
  sections: any[];
  created_by: string;
  created_on: number;
  modified_on: number;
}

const ViewTemplate: React.FC = () => {
    const navigate = useNavigate();
    const userInfo = getUserInfoFromLocalStorage();
    const { templateId } = useParams<{ templateId: string }>();
    const [template, setTemplate] = useState<Template | null>(null);
    const [createdBy, setCreatedBy] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const GLOBAL_BUTTON_TEXT = 'Add this template';
    const { brandLanguage } = useThemeContext();
    
    useEffect(() => {
        const fetchTemplate = async (id: string) => {
        try {
            setLoading(true);

            // Make the fetch request to your API
            const response = await fetch(`${API_BASE_URL}/template/getTemplateByID/${id}`);

            if (response.ok) {
            const data = await response.json();
            setTemplate(data.template);
            setCreatedBy(data.created_by_user);

            } else {
            console.error("Failed to fetch template, status code:", response.status);
            }
        } catch (error) {
            console.error('Error fetching template:', error);
        } finally {
            setLoading(false);
        }
        };

        if (templateId) {
        fetchTemplate(templateId);
        }
    }, [templateId]);

    const handleButtonClick = () => {
        localStorage.setItem('sharedTemplateId', templateId ? templateId: "");
        if (userInfo) {
            navigate('/templates');
        } else if (localStorage.getItem("preferred_brand")){
            navigate('/signup?signin=1');
        } else {
            navigate('/signup');
        }
    };
    return (
        <>
            
            <Box sx={{ height: '100vh', width: '100vw', boxSizing: 'border-box' }}>
            <CustomButton
                onClick={() => {
                navigate("/");
                }}
                size="large"
                aria-label="logo-home-button"
                sx={{height: "5%", px: 2, pt: 2}}
            >
                {brandLanguage.brandName === "JotPsych" && (
                <JotPsychWordmarkLogo size={150} />
                )}
                {brandLanguage.brandName === "JotPal" && (
                <JotPalWordmarkLogo size={150} />
                )}
            </CustomButton>
            {loading ? (
                <Box>
                    <Skeleton variant="text" width={"60%"} height={40} sx={{ mb: 2 }} />
                    <Skeleton variant="rectangular" height={300} sx={{ mb: 2 }} />
                    <Skeleton variant="rectangular" height={300} />
                </Box>
            ) : template ? (
                <Paper elevation={0} sx={{ padding: 2, borderRadius: 2, height: '95%', display: 'flex', flexDirection: 'column' }}>
                    
                    <Box sx={{ mb: 1 }}>
                    
                        <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold', mb: 1 }}>
                        {template?.public_name ? template?.public_name : template?.template_name}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                        Created by: <b>{createdBy}</b> on {new Date(template.created_on * 1000).toLocaleDateString()}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1, position: 'relative' }}>
                        <Typography variant="subtitle2" color="textSecondary">
                            Last modified on: {new Date(template.modified_on * 1000).toLocaleDateString()}
                        </Typography>
                        <Button
                            component={Link}
                            to={`/share/templates/created-by/${encodeURIComponent(template.created_by)}`}
                            variant="outlined"
                            sx={{ position: 'absolute', right: 0, bottom: 0, textTransform: 'none' }}
                        >
                            View All Templates by {createdBy}
                        </Button>
                        </Box>
                    </Box>

                    <Divider />

                    <Grid container justifyContent="center" spacing={3} sx={{ overflowY: 'auto', height: '50vh', flexGrow: 2, my: 1}}>
                        {template.sections.map((section, index) => (
                        <Grid item md={8} sm={6} key={index}>
                                <NoteSectionDisplayBox>
                                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                                    {section.display_name}
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary">
                                    {section.module_info?.description || section.module_info?.public_description || section.module_info?.user_module_description}
                                    </Typography>
                                </NoteSectionDisplayBox>
                        </Grid>
                        ))}
                    </Grid>

                    <Divider />

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            sx={{ mt: 1, borderRadius: 2 }}
                            onClick={handleButtonClick}
                        >
                            {userInfo ? GLOBAL_BUTTON_TEXT : 
                            localStorage.getItem("preferred_brand") ? `Sign In & ${GLOBAL_BUTTON_TEXT}` :
                            `Sign up & ${GLOBAL_BUTTON_TEXT}`}
                        </Button>
                    </Box>
                </Paper>
            ) : (
                <Typography variant="h6" color="error">
                Template not found.
                </Typography>
            )}
            </Box>
        </>
    );
};

export default ViewTemplate;
