import { Params, json } from "react-router-dom";
import {
  AdminModule,
  AdminUser,
  NoteCounts,
  UserNoteTypeII,
} from "../types/types";
import { API_BASE_URL } from "../services/APIService";

export const UserInfoLoader = async () => {
  const accessToken = localStorage.getItem("accessToken");
  const user_information = await fetch(`${API_BASE_URL}/user/getUserInfo`, {
    method: "get",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
  })
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then(async (data: any) => {
      let userInfo = data.user_info;
      userInfo = {
        ...userInfo,
        featureFlags: data.feature_flags,
        payment_status: data.payment_status,
        user_metrics: data.user_metrics,
        gamification: data.gamification,
        referral_info: data.referral_info,
        user_id: data.user_id,
      };
      return userInfo;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return user_information;
};

export const NotesLoader = async (
  category: "reviewing" | "completed" | "archived" | "all",
  limit: number = 20,
  skip: number = 0,
  sort_order: number = -1,
  search: string = "",
  group_id?: string,
  startDate?: string | null,
  endDate?: string | null,
): Promise<{ notes: UserNoteTypeII[]; totalCount: number }> => {
  const accessToken = localStorage.getItem("accessToken");

  const params = new URLSearchParams();
  if (group_id) params.append("group_id", group_id);
  params.append("category", category);
  params.append("limit", limit.toString());
  params.append("skip", skip.toString());
  params.append("sort_order", sort_order.toString());
  if (search) params.append("search", search);
  if (startDate) {
    params.append("start_date", startDate.toString());
  }
  if (endDate) {
    params.append("end_date", endDate.toString());
  }

  const response = await fetch(
    `${API_BASE_URL}/user/getNotes?${params.toString()}`,
    {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  )
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }
      return res.json();
    })
    .then((data: { notes: UserNoteTypeII[]; total_count: number }) => {
      return { notes: data.notes, totalCount: data.total_count };
    })
    .catch((e) => {
      console.error(e);
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return response;
};

export const ForReviewNotesLoader = async (
  limit: number = 20,
  skip: number = 0,
  sort_order: number = -1,
  search: string = ""
): Promise<any> => {
  const accessToken = localStorage.getItem("accessToken");
  const notes = await fetch(
    `${API_BASE_URL}/user/getForReviewNotes?limit=${limit}&skip=${skip}&sort_order=${sort_order}&search=${search}`,
    {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  )
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: { for_review_notes: UserNoteTypeII[] }) => {
      return data.for_review_notes;
    })
    .catch((e) => {
      console.error(e);
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return notes;
};

export const ArchivedNotesLoader = async (
  limit: number = 20,
  skip: number = 0,
  sort_order: number = -1,
  search: string = ""
): Promise<any> => {
  const accessToken = localStorage.getItem("accessToken");
  const notes = await fetch(
    `${API_BASE_URL}/user/getArchivedNotes?limit=${limit}&skip=${skip}&sort_order=${sort_order}&search=${search}`,
    {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  )
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: { archived_notes: UserNoteTypeII[] }) => {
      return data.archived_notes;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return notes;
};

export const NoteCountsLoader = async (): Promise<any> => {
  const accessToken = localStorage.getItem("accessToken");
  const note_counts = await fetch(`${API_BASE_URL}/user/getNoteCounts`, {
    method: "get",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
  })
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: NoteCounts) => {
      return data;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return note_counts;
};
