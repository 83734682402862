import React, { useEffect, useState } from "react";
import { useSelectedUser } from "../../context/selectedUserContext.tsx";
import { AdminUser } from "../../types/types.js";
import { useUser } from "../../context/user/context.js";
import APIService from "../../services/APIService.js";
import { formatDate } from "../../utils/utils.js";

interface UserInfoProps {
  usersList: AdminUser[];
}

export const UserInfo: React.FC<UserInfoProps> = ({ usersList }) => {
  const { getAccessToken } = useUser();
  const { selectedUser } = useSelectedUser();
  const [isBetaUser, setIsBetaUser] = useState<boolean>(false);
  const [newCohort, setNewCohort] = useState<string>("");
  const [cohortUpdateSuccess, setCohortUpdateSuccess] =
    useState<boolean>(false);
  const [userToShow, setUserToShow] = useState<AdminUser | null>(null);

  useEffect(() => {
    if (selectedUser) {
      const foundUser = usersList.find((obj) => obj._id === selectedUser);
      setUserToShow(foundUser || null);
      setNewCohort(foundUser?.current_cohort || "");
      setIsBetaUser(foundUser?.featureSet === "next");
    } else {
      setUserToShow(null);
      setNewCohort("");
      setIsBetaUser(false);
    }
  }, [selectedUser, usersList]);

  const updateUserFeatureSet = async (featureSet: string | undefined) => {
    const accessToken = await getAccessToken();
    const response = await APIService.makeAPIPostRequest({
      requestString: "/user/updateUserFeatureSet",
      accessToken: accessToken,
      body: {
        user_id: selectedUser,
        feature_set: featureSet,
      },
    });
    if (response.ok) {
      window.location.reload();
    }
  };

  const updateUserBetaStatus = async (betaStatus: boolean) => {
    const accessToken = await getAccessToken();
    const response = await APIService.makeAPIPostRequest({
      requestString: "/user/updateUserBetaStatus",
      accessToken: accessToken,
      body: {
        user_id: selectedUser,
        beta_status: betaStatus,
      },
    });

    if (response.ok) {
      window.location.reload();
    }
  };

  const handleBetaToggle = async () => {
    const newBetaStatus = isBetaUser ? false : true;
    setIsBetaUser(!isBetaUser);

    updateUserBetaStatus(newBetaStatus);
  };

  const updateUserCohort = async () => {
    if (!selectedUser) return;

    const accessToken = await getAccessToken();
    const response = await APIService.makeAPIPostRequest({
      requestString: "/user/updateUserCohort",
      accessToken: accessToken,
      body: {
        target_user_id: selectedUser,
        new_cohort: newCohort,
      },
    });

    if (response.ok) {
      setCohortUpdateSuccess(true);
      setTimeout(() => setCohortUpdateSuccess(false), 3000);
    }
  };

  if (!userToShow) {
    return <div className="m-4 p-4 bg-red-200 rounded-md">Select a user</div>;
  }

  return (
    <div className="m-4 p-4 bg-slate-100 rounded-md">
      <h2 className="text-2xl font-bold mb-2">{userToShow.user_info?.name}</h2>
      <p>Id: {userToShow._id}</p>
      <p>Email: {userToShow.user_info?.email}</p>
      <p>Last Login: {formatDate(userToShow.last_login_timestamp!)}</p>
      <p>Current Cohort: {userToShow.current_cohort}</p>
      <div className="mt-4 flex items-center">
        <input
          type="text"
          value={newCohort}
          onChange={(e) => setNewCohort(e.target.value)}
          className="border rounded px-2 py-1 mr-2"
          placeholder="Enter new cohort"
        />
        <button
          onClick={updateUserCohort}
          className="bg-blue-500 text-white px-4 py-1 rounded hover:bg-blue-600"
        >
          Update Cohort
        </button>
        {cohortUpdateSuccess && (
          <span className="ml-2 text-green-600">✓ Updated</span>
        )}
      </div>
    </div>
  );
};
