import React from "react";
import {
  Drawer,
  Tabs,
  Tab,
  Box,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import {
  AnalysisSessionV2,
  ChunkDocumentBase,
  NoteSectionDocument,
} from "../../Views/Admin/AnalysisSessionInspector";

interface AnalysisDataDrawerProps {
  open: boolean;
  onClose: () => void;
  analysisData: AnalysisSessionV2["node_map"][string];
  nodes: AnalysisSessionV2["nodes"];
}

interface DocumentWithScore {
  document: ChunkDocumentBase | NoteSectionDocument;
  score: number;
}

const DocumentCard: React.FC<DocumentWithScore> = ({ document, score }) => {
  const isChunkDocument = "summary" in document;

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {isChunkDocument
            ? `Chunk ${document.chunk_position}`
            : document.section_name}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Doc ID: {document.doc_id}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Score: {score.toFixed(4)}
        </Typography>
        <Typography variant="body1" paragraph>
          {document.content}
        </Typography>
        {isChunkDocument && (
          <>
            <Typography variant="body2" color="text.secondary">
              Summary:
            </Typography>
            <Typography variant="body2" paragraph>
              {document.summary}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Key Topics:
            </Typography>
            <Typography variant="body2" paragraph>
              {document.key_topics.join(", ")}
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export const AnalysisDataDrawer: React.FC<AnalysisDataDrawerProps> = ({
  open,
  onClose,
  analysisData,
  nodes,
}) => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const renderDocuments = (
    docIdsWithScores: [string, number][],
    nodeType:
      | "transcript_docs"
      | "additional_context_docs"
      | "previous_note_docs"
  ) => {
    return docIdsWithScores.map(([docId, score]) => {
      const document = nodes[nodeType]?.find((doc) => doc.doc_id === docId);
      return document ? (
        <DocumentCard key={docId} document={document} score={score} />
      ) : null;
    });
  };

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <Box sx={{ height: "50vh", overflow: "auto" }}>
        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label="Transcript Results" />
          <Tab label="Additional Context Results" />
          <Tab label="Previous Note Results" />
        </Tabs>
        <Box sx={{ p: 2 }}>
          {tabValue === 0 &&
            renderDocuments(analysisData.transcript_results, "transcript_docs")}
          {tabValue === 1 &&
            renderDocuments(
              analysisData.additional_context_results,
              "additional_context_docs"
            )}
          {tabValue === 2 &&
            renderDocuments(
              analysisData.previous_note_results,
              "previous_note_docs"
            )}
        </Box>
      </Box>
    </Drawer>
  );
};
