import {
  Box,
  Typography,
  Link,
  Grid,
  CircularProgress,
  Alert,
  styled,
  Stack,
  CardContent,
} from "@mui/material";
import { RoundedButton } from "../styles/CustomButtons";

import { IOSQRCodeModal } from "./Modals/IOSQRCodeModal";
import { useState } from "react";
import {
  DesktopDatePicker,
  DesktopTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import SmsIcon from "@mui/icons-material/Sms";
import { useNavigate } from "react-router-dom";
import { useUser } from "../context/user";
import APIService from "../services/APIService";
import { useThemeContext } from "../context/theme/context";
import { classes } from "mui-tel-input";
import dayjs from "dayjs";
import { DismissableCard } from "../Views/Shared/DismissableCard";
import { DashboardCard } from "../Views/Shared/DashboardCard";
interface EmptyNoteListProps {
  showTitle?: boolean;
}

const CustomMuiTelInput = styled(MuiTelInput)`
  .${classes.flagButton} {
    display: none;
  }
  // Custom padding for the input
  & input {
    padding-left: 0px;
  }
`;

export const EmptyNoteList = ({ showTitle = true }: EmptyNoteListProps) => {
  const [showIOSModal, setShowIOSModal] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>();
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(
    dayjs().hour(0).minute(0)
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formErrors, setFormErrors] = useState({
    date: "",
    phoneNumber: "",
    time: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { getAccessToken } = useUser();
  const { brandLanguage } = useThemeContext();
  const navigate = useNavigate();

  const handlePhoneChange = (newValue: string) => {
    setPhoneNumber(newValue);
  };

  const handleSubmit = async () => {
    let errors = { date: "", time: "", phoneNumber: "" };
    setIsLoading(true);

    if (!selectedDate) {
      errors.date = "A date is required.";
    }

    if (!selectedTime) {
      errors.time = "A time is required.";
    }

    if (!phoneNumber) {
      errors.phoneNumber = "Phone number is required.";
    } else if (
      !matchIsValidTel(phoneNumber, {
        onlyCountries: [],
        excludedCountries: [],
        continents: [],
      })
    ) {
      errors.phoneNumber = "Invalid phone number format.";
    }

    if (errors.date || errors.time || errors.phoneNumber) {
      setFormErrors(errors);
      setIsLoading(false);
      return;
    }

    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    if (selectedDate && selectedTime && phoneNumber) {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // combine selectedDate and selectedTime for the full datetime
      const combinedDateTime = selectedDate
        .hour(selectedTime.hour())
        .minute(selectedTime.minute())
        .second(0);

      const dateString = combinedDateTime.format("YYYY-MM-DDTHH:mm:ssZ");

      try {
        const logOnboardingResponse = await APIService.makeAPIPostRequest({
          requestString: "/telemetry/logOnboardingReminder",
          accessToken: accessToken,
          body: {
            phoneNumber: phoneNumber,
            datetime: dateString,
            userTimeZone: userTimeZone,
          },
        });

        if (logOnboardingResponse.ok) {
          setIsSubmitted(true);
          setErrorMessage("");
        } else {
          setErrorMessage("Something unexpected happened. Please try again.");
        }
      } catch (error) {
        setErrorMessage("Something unexpected happened.  Please try again.");
      }
      setIsLoading(false);
    }
  };

  const ReminderSection = () => {
    const buttonStyles = {
      color: "textColors.brandContrast",
      bgcolor: "backgroundColors.brand",
      px: 2,
      fontWeight: 600,
      "&:hover": {
        bgcolor: "backgroundColors.brand",
      },
    };
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="body1" sx={{ fontWeight: "bold", my: 1 }}>
          Set a reminder for your next patient encounter
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Want a reminder to try {brandLanguage.brandName}? Enter your phone
          number and date/time of your next patient encounter, and we'll send
          you a text when the time comes.
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 2,
          }}
        >
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </Box>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          !isSubmitted && (
            <Grid
              container
              spacing={2}
              justifyContent="space-between" // Adjusted for pushing items to the outer edges
              alignItems="flex-start" // Adjusted to align items at the top
              sx={{ width: "100%", pt: 2 }}
            >
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Date"
                    value={selectedDate}
                    onChange={(value: any) => {
                      setSelectedDate(value);
                      // Reset date error when user picks a date
                      setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        date: "",
                      }));
                    }}
                    // maxDate={threeWeeksInFuture}
                    disablePast
                    onError={(error: any) => {
                      setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        date: error ? "Invalid date format." : "",
                      }));
                    }}
                    slotProps={{
                      textField: {
                        error: !!formErrors.date,
                        helperText: formErrors.date,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopTimePicker
                    label="Time"
                    value={selectedTime}
                    onChange={(value) => {
                      setSelectedTime(value);
                      setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        date: "",
                      }));
                    }}
                    formatDensity="dense"
                    onError={(error) => {
                      setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        time: error ? "Invalid time format." : "",
                      }));
                    }}
                    slotProps={{
                      textField: {
                        error: !!formErrors.time,
                        helperText: formErrors.time,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={4}>
                <CustomMuiTelInput
                  disableDropdown
                  defaultCountry="US"
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  error={!!formErrors.phoneNumber}
                  helperText={formErrors.phoneNumber}
                  inputProps={{ autoFocus: true }}
                />
              </Grid>
              <Grid item xs={12} sm={2} md={4} lg={2}>
                <RoundedButton
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  sx={buttonStyles}
                >
                  Submit
                </RoundedButton>
              </Grid>
            </Grid>
          )
        )}
        {isSubmitted && (
          <Typography
            my={0.5}
            fontWeight="bold"
            color="textColors.darkHeader"
            sx={{
              fontSize: "1rem",
              textAlign: "center",
              mt: 4,
            }}
          >
            Thank you! We will text you before your next visit.{" "}
            <SmsIcon color="success"></SmsIcon>
          </Typography>
        )}
      </Box>
    );
  };

  const GetFamiliarToRecorder = () => {
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="body1" sx={{ fontWeight: "bold", my: 1 }}>
          Get familiar with the recorder
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          You'll use one of our recorders to record your first encounter. The
          easiest way is to open our Web Recorder by selecting "Record" from the
          menu.
        </Typography>
        <Typography sx={{ my: 2 }}>
          You can also choose between our{" "}
          <Link
            href="https://chromewebstore.google.com/detail/jotpsych/hfnlanjcbamndgbakkdcenfcambecomb"
            target="_blank"
            color="primary"
          >
            Google Chrome Extension
          </Link>{" "}
          on your computer or the{" "}
          <Link
            component="button" // Makes the Link act as a button
            onClick={() => {
              setShowIOSModal(true);
            }}
            color="primary" // Use MUI theme color or any custom styling
          >
            mobile app on iOS.
          </Link>
        </Typography>
      </Box>
    );
  };

  const buttonStyles = {
    color: "textColors.brand",
    bgcolor: "backgroundColors.primary",
    px: 2,
    fontWeight: 600,
    "&:hover": {
      bgcolor: "backgroundColors.primary",
    },
  };

  const ClientConsent = () => {
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="body1" sx={{ fontWeight: "bold", my: 1 }}>
          How do I get client consent?
        </Typography>
        <Typography pb={2} color="textColor.primaryText">
          Getting your client's consent to use {brandLanguage.brandName} during
          your session is easy — check out our{" "}
          <Link
            href={`https://${brandLanguage.brandSite}/getting-consent`}
            target="_blank"
            color="primary"
          >
            client consent guide
          </Link>
          , which includes downloadable forms and phrases you can use to get
          started right away.
        </Typography>
      </Box>
    );
  };
  return (
    <>
      <IOSQRCodeModal
        isOpen={showIOSModal}
        onClose={() => {
          setShowIOSModal(false);
        }}
      />
      <Stack spacing={2}>
        {/* <DismissableCard
          headerTitle="Get Started - Explore a JotPsych note!"
          bodyText={[
            "You haven't recorded any notes yet.. To get started, we suggest you either explore our pre-made demo note or dive in and record your own.",
          ]}
          customStyles={{
            bgcolor: "newDesignColors.eggplant900",
            color: "textColors.brandContrast",
            padding: 2,
          }}
          actionButtonContent={<GetStartedActionButtons />}
        /> */}
        <DashboardCard
          customStyles={{
            bgcolor: "newDesignColors.eggplant900",
            color: "textColors.brandContrast",
            padding: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mx: 2,
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: "bold", my: 1 }}>
              Get Started - Explore a JotPsych note!
            </Typography>
          </Box>
          <CardContent sx={{ pb: 0 }}>
            <Typography variant="body2" sx={{ mb: 2 }}>
              You haven't recorded any notes yet. To get started, we suggest you
              either explore our pre-made demo note or dive in and record your
              own.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Box sx={{ pt: 2 }}>
                <RoundedButton
                  sx={buttonStyles}
                  onClick={() => {
                    navigate(`/record`);
                  }}
                >
                  Record my own note
                </RoundedButton>
                <RoundedButton
                  sx={buttonStyles}
                  onClick={() => navigate(`/demo/psychiatric-intake-demo`)}
                >
                  Explore demo note
                </RoundedButton>
              </Box>
            </Box>
          </CardContent>
        </DashboardCard>
      </Stack>
      {/* <Box my={0}>
        {showTitle && (
          <Typography
            fontWeight="bold"
            color="textColors.darkHeader"
            sx={{
              fontSize: "1.25rem",
              textAlign: "center",
            }}
          >
            How to prepare for your first note with {brandLanguage.brandName}
          </Typography>
        )}

        

        <Divider sx={{ mt: 4 }} />
        <Box px={4} my={2}>
          {" "}
          <Typography
            fontWeight="bold"
            mt={4}
            py={2}
            color="textColors.darkHeader"
            sx={{
              fontSize: "1.25rem",
              textAlign: "center",
            }}
          >
            
          </Typography>
        </Box>
        <Box px={4} my={2}>
          
        </Box>
      </Box> */}
    </>
  );
};
