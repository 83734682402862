import React, { useState, useEffect, useCallback } from "react";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
} from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useUser } from "../../context/user";
import APIService from "../../services/APIService";

const columns: GridColDef[] = [
  { field: "message", headerName: "Message", flex: 3 },
  { field: "status", headerName: "Status", flex: 1 },
  { field: "sender_name", headerName: "Sender", flex: 2 },
  { field: "note_id", headerName: "Note ID", flex: 3 },
  { field: "name", headerName: "Name", flex: 2 },
  {
    field: "claim_submission_time",
    headerName: "Submission Time",
    flex: 3,
  },
  { 
    field: "modify_claim", 
    headerName: "Modify Claim", 
    flex: 1, 
    renderCell: (params) => (
      <a href={`https://www.claim.md/`} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>
        Link
      </a>
    ) 
},
];

interface ClaimSubmittedResponse {
  claims: {
    id: string;
    message: string;
    status: string;
    sender_name: string;
    note_id: string;
    name: string;
    claim_submission_time: string;
  }[];
  total: number;
  page: number;
  perPage: number;
  totalPages: number;
}

const ClaimSubmittedList: React.FC = () => {
  const { getAccessToken } = useUser();
  const [claims, setClaims] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const [totalRows, setTotalRows] = useState(0);

  const fetchClaims = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);
      try {
        const endpoint = `/billing/claim/list?page=${page + 1}&per_page=${pageSize}`;

        const response = await APIService.makeAPIGetRequest({
          requestString: endpoint,
          accessToken: await getAccessToken(),
        });

        if (response.ok) {
          const data: ClaimSubmittedResponse = await response.value;
          console.log("Received data:", data);
          setClaims(data.claims);
          setTotalRows(data.total);
        } else {
          console.error("Failed to fetch claims:", response.error);
          throw new Error("Failed to fetch claims");
        }
      } catch (error) {
        console.error("Error fetching claims:", error);
      } finally {
        setLoading(false);
      }
    },
    [getAccessToken]
  );

  useEffect(() => {
    fetchClaims(paginationModel.page, paginationModel.pageSize);
  }, [fetchClaims, paginationModel.page, paginationModel.pageSize]);

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    setPaginationModel(newModel);
    fetchClaims(newModel.page, newModel.pageSize);
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <DataGrid
        rows={claims}
        columns={columns}
        loading={loading}
        getRowId={(row) => row.id}
        paginationMode="server"
        rowCount={totalRows}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={[10, 20, 50]}
        sx={{ height: "100%" }}
      />
    </Box>
  );
};

export default ClaimSubmittedList;
