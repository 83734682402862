import React from "react";
import { Box, Typography } from "@mui/material";
interface ActionableButtonCardProps {
  title: string;
  navigation?: string;
  Icon?: React.ReactNode;
}
import { DashboardCard } from "./DashboardCard";

const ActionableButtonCard: React.FC<ActionableButtonCardProps> = (props) => {
  const { title, navigation = undefined, Icon } = props;
  const boxStyle = {
    display: "flex",
    flexDirection: "column",
    padding: 2,
    mx: 1,
    color: "newDesignColors.eggplant700",
    height: { xs: "auto", md: "200px" },
  };
  const ChildrenComponent = () => {
    return (
      <Box sx={boxStyle}>
        {Icon}
        <Typography
          variant="h5"
          sx={{ mt: "10px", color: "newDesignColors.eggplant800" }}
        >
          {title}
        </Typography>
      </Box>
    );
  };
  return (
    <>
      <DashboardCard children={<ChildrenComponent />} navigation={navigation} />
    </>
  );
};

export default ActionableButtonCard;
