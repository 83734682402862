import { Tooltip, TooltipProps } from "@mui/material";
import { ReactElement, ReactNode } from "react";

interface ConditionalTooltipProps extends TooltipProps {
  condition: boolean;
  title: string | ReactNode;
  children: ReactElement;
}

export const ConditionalTooltip: React.FC<ConditionalTooltipProps> = ({
  condition,
  title,
  children,
  ...tooltipProps
}) => {
  return condition ? (
    <Tooltip title={title} {...tooltipProps}>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
};
