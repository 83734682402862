
/**
 * @file src/components/EmailVerificationPrompt.tsx
 * @description This file contains the EmailVerificationPrompt React component.
 * 
 * Author: Soham Dave
 * Date: 2024-09-30
 */
import React, { useState } from 'react';
import { useUser } from "../../context/user/context";
import {
  Box,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import APIService from '../../services/APIService';


interface EmailVerificationPromptProps {
  textToDisplay?: string;
  onClose?: () => void; 
}
const EmailVerificationPrompt: React.FC<EmailVerificationPromptProps> = ({ textToDisplay, onClose }) => {
  const { getAccessToken, userState } = useUser();
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [resendingEmail, setResendingEmail] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error" | "info" | "warning";
  }>({
    open: false,
    message: "",
    severity: "info",
  });
  
  const resendVerificationEmail = async () => {
    setResendingEmail(true);
    const accessToken = await getAccessToken();
    if (!accessToken) {
      setResendingEmail(false);
      return;
    }

    try {
      const response = await APIService.makeAPIPostRequest({
        requestString: "/user/resendVerificationEmail",
        accessToken: accessToken,
        body: {},
      });

      if (response.ok) {
        setSnackbar({
          open: true,
          message: "Verification email sent!",
          severity: "success",
        });
        setEmailSent(true);
      } else {
        setSnackbar({
          open: true,
          message: "Failed to send verification email.",
          severity: "error",
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: "An error occurred while sending verification email.",
        severity: "error",
      });
    } finally {
      setResendingEmail(false);
    }
  };

  const handleVerified = () => {
    window.location.reload();
  };
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
      <Paper
        sx={{
          p: 4,
          width: "500px",
          borderRadius: "10px",
          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
          position: "relative", // Add this to position the close button
        }}
      >
        {onClose && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{ fontWeight: "bold", color: "#3f51b5" }}
        >
          Email Not Verified
        </Typography>
        {emailSent ? (
          <>
            <Typography
              variant="body1"
              align="center"
              sx={{ mb: 4, color: "textSecondary" }}
            >
              A verification email has been sent to your inbox. (Please also check your spam folder.)
            </Typography>
            <Typography
              variant="body1"
              align="center"
              sx={{ mb: 4, color: "textSecondary" }}
            >
              Once you have verified by clicking the link in the email, press the button below.
            </Typography>
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleVerified}
                endIcon={<RefreshIcon />}
                sx={{
                  textTransform: "none",
                  borderRadius: "25px",
                  px: 4,
                  minWidth: "150px",
                }}
              >
                I verified my email
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography
              variant="body1"
              align="center"
              sx={{ mb: 4, color: "textSecondary" }}
            >
              {textToDisplay || "You need to verify your email in order to use all of JotPsych's features."}
            </Typography>
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={resendVerificationEmail}
                disabled={resendingEmail}
                sx={{
                  textTransform: "none",
                  borderRadius: "25px",
                  px: 4,
                  minWidth: "150px",
                }}
              >
                {resendingEmail ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Verify Email"
                )}
              </Button>
            </Box>
          </>
        )}
      </Paper>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EmailVerificationPrompt;
