import React from "react";
import { Box, SxProps, Theme } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface DashboardCardProps {
  children?: React.ReactNode;
  customStyles?: SxProps<Theme>;
  navigation?: string;
}

export const DashboardCard: React.FC<DashboardCardProps> = ({
  children,
  customStyles,
  navigation,
}) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (navigation) {
      navigate(navigation);
    }
  };

  return (
    <Box
      sx={{
        borderRadius: 3,
        bgcolor: "backgroundColors.secondary",
        color: "inherit",
        padding: "inherit",
        height: "inherit",
        borderColor: "borderColors.primary",
        boxShadow: "none",
        border: "1px",
        cursor: navigation ? "pointer" : "default",
        ...customStyles,
      }}
      onClick={navigation ? handleNavigation : undefined}
    >
      {children}
    </Box>
  );
};
