import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
  Snackbar,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";

const GenerateNoteForUser: React.FC = () => {
  const { getAccessToken } = useUser();
  const [targetUserId, setTargetUserId] = useState("");
  const [transcriptionSessionId, setTranscriptionSessionId] = useState("");
  const [noteId, setNoteId] = useState("");
  const [noteTitle, setNoteTitle] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/noteProcessingSession/startNoteProcessingForUser",
        accessToken: accessToken,
        body: {
          target_user_id: targetUserId,
          transcription_session_id: transcriptionSessionId,
          note_title: noteTitle,
          template_id: templateId,
        },
      });

      if (response.ok) {
        setSnackbarMessage("Note generation started successfully");
      } else {
        setSnackbarMessage("Failed to start note generation");
      }
    } catch (error) {
      setSnackbarMessage("An error occurred while starting note generation");
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const fetchTranscriptionSessionId = async () => {
    if (!noteId) return;

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/testsuite/getTranscriptionSessionIdFromNoteId?note_id=${noteId}`,
        accessToken: accessToken,
      });

      if (response.ok) {
        setTranscriptionSessionId(response.value.transcription_session_id);
      } else {
        setSnackbarMessage("Failed to fetch transcription session ID");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage(
        "An error occurred while fetching transcription session ID"
      );
      setSnackbarOpen(true);
    }
  };

  const isFormValid =
    targetUserId && transcriptionSessionId && noteTitle && templateId;

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>
        Generate Note for User
      </Typography>
      <form onSubmit={handleSubmit}>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <TextField
              fullWidth
              label="Target User ID"
              value={targetUserId}
              onChange={(e) => setTargetUserId(e.target.value)}
              required
              placeholder="Paste the target user ID here"
            />
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ display: "block", mt: 1 }}
            >
              THIS IS THE USER THAT WILL RECEIVE THIS NOTE. BE CAREFUL IF YOU
              ARE ASSIGNING A NOTE TO A USER OUTSIDE OF OUR TEAM FOR TESTING
              PURPOSES.
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <TextField
              fullWidth
              label="Transcription Session ID"
              value={transcriptionSessionId}
              onChange={(e) => setTranscriptionSessionId(e.target.value)}
              required
              placeholder="Enter transcription session ID or use Note ID below"
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Note ID"
              value={noteId}
              onChange={(e) => setNoteId(e.target.value)}
              placeholder="Enter Note ID to fetch Transcription Session ID"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={fetchTranscriptionSessionId}
                      edge="end"
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ display: "block", mt: 1 }}
            >
              If you don't have the transcription_session_id, you can put in the
              note_id for the transcript that you want to use, and we'll fetch
              it.
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <TextField
              fullWidth
              label="Note Title"
              value={noteTitle}
              onChange={(e) => setNoteTitle(e.target.value)}
              required
            />
          </CardContent>
        </Card>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <TextField
              fullWidth
              label="Template ID"
              value={templateId}
              onChange={(e) => setTemplateId(e.target.value)}
              required
              placeholder="Paste the template ID here"
            />
          </CardContent>
        </Card>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading || !isFormValid}
          fullWidth
          size="large"
        >
          {loading ? <CircularProgress size={24} /> : "Generate Note"}
        </Button>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default GenerateNoteForUser;
