import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useUser } from "../context/user";
import APIService from "../services/APIService";

interface ChecklistProps {
  transcriptionSessionId: string | null;
  items: { [key: string]: boolean };
}

interface ChecklistItem {
  label: string;
  completed: boolean;
}

const ChecklistContainer = styled('div')({
  border: '1px solid black',
  borderRadius: '8px',
  padding: '10px',
  width: '250px',
  maxHeight: '300px',
  overflowY: 'auto',
});

const ChecklistItemContainer = styled("div")<{ completed: boolean }>(
  ({ completed }) => ({
    color: completed ? "grey" : "black",
    textDecoration: completed ? "line-through" : "none",
    padding: "4px 0",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
  })
);

// const MOCK_CHECKLIST_DATA = {
//   "Suicidal Ideation": true,
//   "Homicidal Ideation": true,`
//   "Vital signs": false,
//   Sleep: false,
//   Appetite: false,
// };

export const Checklist: React.FC<ChecklistProps> = ({
  transcriptionSessionId,
}) => {
  const { getAccessToken } = useUser();
  const [checklistData, setChecklistData] = useState<{
    [key: string]: boolean;
  } | null>(null);
  const [checklistError, setChecklistError] = useState<string | null>(null);
  const [sortedItems, setSortedItems] = useState<ChecklistItem[]>([]);
  const [hasValidData, setHasValidData] = useState<boolean>(false);

  const fetchChecklistData = async () => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/transcriptionSession/getActiveChecklist?transcription_session_id=${transcriptionSessionId}`,
        accessToken,
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch checklist: ${response.error.message}`);
      }

      const data = response.value;
      setChecklistData(data.active_checklist || null);
      setChecklistError(null);
    } catch (error) {
      console.error("Error fetching checklist data:", error);
      setChecklistData(null);
      setChecklistError("Failed to load checklist");
    }
  };

  useEffect(() => {
    fetchChecklistData();
    const intervalId = setInterval(fetchChecklistData, 30000);
    return () => clearInterval(intervalId);
  }, [getAccessToken, transcriptionSessionId]);

  useEffect(() => {
    if (!checklistData) {
      return;
    }

    const itemsArray = Object.entries(checklistData).map(
      ([label, completed]) => ({
      label,
      completed,
      })
    );

    const sortedItemsArray = itemsArray.sort((a, b) => {
      if (a.completed === b.completed) {
        return a.label.localeCompare(b.label);
      }
      return Number(a.completed) - Number(b.completed);
    });

    setSortedItems(sortedItemsArray);
  }, [checklistData]);

  const formatLabel = (label: string): string => label.replace(/_/g, " ");

  return (
    <ChecklistContainer>
      {sortedItems.map((item) => (
        <ChecklistItemContainer key={item.label} completed={item.completed}>
          {formatLabel(item.label)}
        </ChecklistItemContainer>
      ))}
    </ChecklistContainer>
  );
};
