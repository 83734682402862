import React, { useEffect, useRef, useState } from "react";
import { AdminModule, ExtractionPrompts } from "../../types/types";
import { ModuleLoader } from "../../loaders/ModulesLoader";
import { useLoaderData } from "react-router-dom";
import { GenerationTestSection } from "../../components/Admin/GenerationTestSection";
import { ExtractionTestSection } from "../../components/Admin/ExtractionTestSection";
import { ModuleCustomPromptSection } from "../../components/Admin/CustomPromptSections";
import { GenerationOutputTestSection } from "../../components/Admin/GenerationOutputTestSection";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";

export const modelNames = {
  "gpt-4o": "GPT-4o",
  "claude-3-5-sonnet": "Claude 3.5 Sonnet",
  "gpt-4": "GPT-4",
  "gpt-3.5-turbo": "GPT-3.5-Turbo",
  "gpt-4-turbo": "GPT-4-Turbo (OpenAI)",
  "azure-gpt-4-turbo": "GPT-4-Turbo (Azure)",
  "claude-3-opus": "Claude 3 Opus",
  "claude-3-sonnet": "Claude 3 Sonnet",
};

interface ModuleEditorProps {}

export const ModuleEditor: React.FC<ModuleEditorProps> = () => {
  const { getAccessToken } = useUser();
  const module = useLoaderData() as Awaited<ReturnType<typeof ModuleLoader>>;
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [modifiedModule, setModifiedModule] = useState<AdminModule>(module);
  const [selectedModels, setSelectedModels] = useState<string[]>(
    module.models ? module.models : []
  );

  // Create refs for each field
  const moduleNameRef = useRef<HTMLInputElement>(null);
  const publicNameRef = useRef<HTMLInputElement>(null);
  const publicDescriptionRef = useRef<HTMLInputElement>(null);
  const publicCategoriesRef = useRef<HTMLInputElement>(null);
  const modelRef = useRef<HTMLInputElement>(null);
  const temperatureRef = useRef<HTMLInputElement>(null);
  const maxTokensRef = useRef<HTMLInputElement>(null);
  const OldExtractionPromptRef = useRef<HTMLTextAreaElement>(null);
  const semanticPromptRef = useRef<HTMLTextAreaElement>(null);
  const keywordPromptRef = useRef<HTMLTextAreaElement>(null);
  const generationPromptRef = useRef<HTMLTextAreaElement>(null);
  const updatePromptRef = useRef<HTMLTextAreaElement>(null);
  const publicExampleRef = useRef<HTMLTextAreaElement>(null);

  // Function to handle the onBlur event
  const handleBlur = () => {
    // Update your module state or perform any action needed
    const updatedModule = {
      module_name: moduleNameRef.current?.value!,
      public_name: publicNameRef.current?.value,
      public_description: publicDescriptionRef.current?.value,
      public_categories: publicCategoriesRef.current?.value,
      model: modelRef.current?.value,
      temperature: temperatureRef.current?.value,
      max_tokens: maxTokensRef.current?.value
        ? parseInt(maxTokensRef.current.value)
        : undefined,
      extraction_prompt: OldExtractionPromptRef.current?.value!,
      extraction_prompts: {
        semantic_prompt: semanticPromptRef.current?.value!,
        keyword_prompt: keywordPromptRef.current?.value!,
      },
      generation_prompt: generationPromptRef.current?.value!,
      update_prompt: updatePromptRef.current?.value,
      public_example: publicExampleRef.current?.value,
    };

    setModifiedModule({ ...modifiedModule, ...updatedModule });
  };

  const onModuleChange = () => {};

  const handleModelsChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    setSelectedModels(typeof value === "string" ? value.split(",") : value);
    setModifiedModule({
      ...modifiedModule,
      models: typeof value === "string" ? value.split(",") : value,
    });
  };

  const saveModule = async () => {
    setIsSaving(true);
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/template/updateModule",
      accessToken: accessToken,
      body: {
        module_id: module.module_id,
        updated_fields: modifiedModule,
      },
    });

    if (!response.ok) {
      console.error("there was a problem with the network request");
    } else {
      console.log("Successfully saved!");
    }

    setIsSaving(false);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value =
      event.target.name === "max_tokens"
        ? Number(event.target.value)
        : event.target.value;

    setModifiedModule({
      ...modifiedModule,
      [event.target.name]: value,
    });
    onModuleChange();
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModifiedModule({
      ...modifiedModule,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="m-4 p-4 bg-slate-100 rounded-md">
      <div className="flex justify-center mt-4">
        <button
          onClick={saveModule}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
        >
          Save Module
        </button>
      </div>
      <div className="flex justify-between mb-4">
        <h1 className="text-2xl font-bold">{modifiedModule.module_name}</h1>
        <div className="text-sm text-gray-500">
          {module.module_id}:{module.module_version}
        </div>
      </div>
      <div className="mb-2 bg-white p-2 rounded-md shadow">
        <div className="mb-2">
          <label className="block text-sm text-gray-500" htmlFor="module_name">
            Module Name
          </label>
          <input
            type="text"
            name="module_name"
            id="module_name"
            ref={moduleNameRef}
            defaultValue={module.module_name}
            onBlur={handleBlur}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mb-2">
          <div className=" text-gray-500 text-xs">Meta Module?</div>
          <input
            type="checkbox"
            name="meta_module"
            checked={modifiedModule.meta_module}
            onChange={handleCheckboxChange}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
          <div className=" text-gray-500 text-xs">Public?</div>
          <input
            type="checkbox"
            name="public"
            checked={modifiedModule.public}
            onChange={handleCheckboxChange}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
          <label className="block text-sm text-gray-500" htmlFor="public_name">
            Public Display Name
          </label>
          <input
            type="text"
            name="public_name"
            id="public_name"
            ref={publicNameRef}
            defaultValue={module.public_name}
            onBlur={handleBlur}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <label
            className="block text-sm text-gray-500"
            htmlFor="public_description"
          >
            Public Description
          </label>
          <input
            type="text"
            name="public_description"
            id="public_description"
            ref={publicDescriptionRef}
            defaultValue={module.public_description}
            onBlur={handleBlur}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="flex justify-between space-x-4 mb-2">
          <FormControl fullWidth margin="normal">
            <InputLabel id="models">Models</InputLabel>
            <Select
              labelId="models"
              id="models"
              multiple
              value={selectedModels}
              onChange={handleModelsChange}
              renderValue={(selected) =>
                selected
                  .map((code) => modelNames[code as keyof typeof modelNames])
                  .join(", ")
              }
            >
              {Object.entries(modelNames).map(([code, name]) => (
                <MenuItem key={code} value={code}>
                  <Checkbox checked={selectedModels.indexOf(code) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div className="w-1/3">
            <label
              className="block text-sm text-gray-500"
              htmlFor="temperature"
            >
              Temperature
            </label>
            <input
              type="text"
              name="temperature"
              id="temperature"
              ref={temperatureRef}
              defaultValue={module.temperature || 0.1}
              onBlur={handleBlur}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="w-1/3">
            <label className="block text-sm text-gray-500" htmlFor="max_tokens">
              Max Tokens
            </label>
            <input
              type="number"
              name="max_tokens"
              id="max_tokens"
              ref={maxTokensRef}
              defaultValue={module.max_tokens || 2500}
              onBlur={handleBlur}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="mb-2">
          <label
            className="block text-sm text-gray-500"
            htmlFor="semantic_prompt"
          >
            Extraction: Semantic Prompt (;-delimited)
          </label>

          <textarea
            name="semantic_prompt"
            id="semantic_prompt"
            ref={semanticPromptRef}
            defaultValue={module.extraction_prompts?.semantic_prompt}
            onBlur={handleBlur}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <label
            className="block text-sm text-gray-500"
            htmlFor="keyword_prompt"
          >
            Extraction: Keyword Prompt (;-delimited)
          </label>

          <textarea
            name="keyword_prompt"
            id="keyword_prompt"
            ref={keywordPromptRef}
            defaultValue={module.extraction_prompts?.keyword_prompt}
            onBlur={handleBlur}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mb-2">
          <label
            className="block text-sm text-gray-500"
            htmlFor="generation_prompt"
          >
            Generation Prompt
          </label>
          <textarea
            name="generation_prompt"
            id="generation_prompt"
            ref={generationPromptRef}
            defaultValue={module.generation_prompt}
            onBlur={handleBlur}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div className="mb-2">
          <label
            className="block text-sm text-gray-500"
            htmlFor="public_example"
          >
            Public Example
          </label>
          <textarea
            name="public_example"
            id="public_example"
            ref={publicExampleRef}
            defaultValue={module.public_example}
            onBlur={handleBlur}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            rows={4}
          />
        </div>
      </div>
      {isSaving && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="p-6 bg-white rounded shadow-xl text-xl font-bold">
            Saving...
          </div>
        </div>
      )}
      <ModuleCustomPromptSection module={modifiedModule} />
      <ExtractionTestSection module={modifiedModule} />
      <GenerationOutputTestSection module={modifiedModule} />
      <GenerationTestSection module={modifiedModule} />
    </div>
  );
};
