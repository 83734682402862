import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import {
  Autocomplete,
  TextField,
  Typography,
  Box,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { debounce } from "lodash";
import { Patient } from "../../types/types";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";
import NewPatientModal from "./NewPatientModal";

interface PatientOption {
  patient_id: string;
  label: string;
  patient: Patient;
}

interface PatientPickerProps {
  onPatientSelect: (patient: Patient | undefined) => void;
  initialPatient?: Patient;
  onOpen?: () => void;
}

const PatientPicker: React.FC<PatientPickerProps> = ({
  onPatientSelect,
  initialPatient,
  onOpen,
}) => {
  const { getAccessToken } = useUser();
  const [options, setOptions] = useState<PatientOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [newPatientModalOpen, setNewPatientModalOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<PatientOption | null>(
    initialPatient
      ? {
          patient_id: initialPatient.patient_id,
          label: `${initialPatient.first_name} ${initialPatient.last_name}`,
          patient: initialPatient,
        }
      : null
  );
  const [inputValue, setInputValue] = useState("");
  const [shouldSearch, setShouldSearch] = useState(true);
  const [hasInitialFetch, setHasInitialFetch] = useState(false);
  const [currentSearchText, setCurrentSearchText] = useState("");

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const shouldPickNewPatient = useRef(false);

  const fetchPatients = useCallback(
    async (searchTerm: string = "") => {
      setLoading(true);
      try {
        const accessToken = await getAccessToken();
        const endpoint = searchTerm
          ? `/patient/search?q=${encodeURIComponent(
              searchTerm
            )}&page=1&per_page=5`
          : "/patient/list?page=1&per_page=5";
        const response = await APIService.makeAPIGetRequest({
          requestString: endpoint,
          accessToken,
        });
        if (response.ok) {
          const data = await response.value;
          const patientOptions: PatientOption[] = data.patients.map(
            (patient: Patient) => ({
              patient_id: patient.patient_id,
              label: `${patient.first_name} ${patient.last_name}`,
              patient,
            })
          );

          const createNewOption = searchTerm
            ? [
                {
                  patient_id: "add_new",
                  label: `Create new patient "${searchTerm}"`,
                  patient: {} as Patient,
                },
              ]
            : [
                {
                  patient_id: "add_new",
                  label: "Add New Patient",
                  patient: {} as Patient,
                },
              ];

          setOptions([...createNewOption, ...patientOptions]);

          if (shouldPickNewPatient.current && patientOptions.length > 0) {
            const firstPatient = patientOptions[0];
            setSelectedPatient(firstPatient);
            onPatientSelect(firstPatient.patient);
            shouldPickNewPatient.current = false;
          }
        }
      } catch (error) {
        console.error("Error fetching patients:", error);
      } finally {
        setLoading(false);
      }
    },
    [getAccessToken, onPatientSelect]
  );

  const debouncedFetchPatients = useMemo(
    () =>
      debounce((searchTerm: string) => {
        if (searchTerm.trim() !== "" && shouldSearch) {
          fetchPatients(searchTerm);
        }
      }, 300),
    [fetchPatients, shouldSearch]
  );

  useEffect(() => {
    return () => {
      debouncedFetchPatients.cancel();
    };
  }, [debouncedFetchPatients]);

  useEffect(() => {
    if (open && !hasInitialFetch) {
      fetchPatients();
      setHasInitialFetch(true);
    }
  }, [open, hasInitialFetch, fetchPatients]);

  useEffect(() => {
    if (initialPatient && !selectedPatient) {
      setSelectedPatient({
        patient_id: initialPatient.patient_id,
        label: `${initialPatient.first_name} ${initialPatient.last_name}`,
        patient: initialPatient,
      });
    } else if (!initialPatient) {
      setSelectedPatient(null);
      setInputValue("");
    }
  }, [initialPatient]);

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: string
  ) => {
    setInputValue(value);
    if (reason === "input") {
      setCurrentSearchText(value);
      if (value.trim() !== "") {
        setShouldSearch(true);
        debouncedFetchPatients(value);
      } else {
        fetchPatients();
      }
    }
  };

  const handleChange = (
    event: React.ChangeEvent<{}>,
    value: PatientOption | null
  ) => {
    if (value) {
      if (value.patient_id === "add_new") {
        setNewPatientModalOpen(true);
        return;
      } else {
        setSelectedPatient(value);
        onPatientSelect(value.patient);
        setInputValue(value.label);
        setCurrentSearchText("");
        setShouldSearch(false);
      }
    } else {
      setSelectedPatient(null);
      onPatientSelect(undefined);
      setInputValue("");
      setCurrentSearchText("");
      fetchPatients();
    }
  };

  const handleNewPatientCreation = async (patientId: string) => {
    shouldPickNewPatient.current = true;
    setNewPatientModalOpen(false);
    await fetchPatients();
  };

  return (
    <>
      <Autocomplete
        id="patient-picker"
        open={open}
        onOpen={() => {
          setOpen(true);
          if (onOpen) {
            onOpen();
          }
        }}
        onClose={() => setOpen(false)}
        options={options}
        loading={loading}
        value={selectedPatient}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleChange}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%", // Ensure the Box takes full width
              }}
            >
              <Typography sx={{ flexGrow: 1, marginRight: 2 }}>
                {option.label}
              </Typography>
              {option.patient.identifier && (
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{
                    flexShrink: 0,
                    maxWidth: isMobile ? "100px" : "none",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {isMobile
                    ? option.patient.identifier.substring(0, 10) + "..."
                    : option.patient.identifier}
                </Typography>
              )}
            </Box>
          </Box>
        )}
        renderInput={(params) => (
          <TextField {...params} label="Select Patient" />
        )}
        isOptionEqualToValue={(option, value) =>
          option?.patient_id === value?.patient_id
        }
      />
      <NewPatientModal
        open={newPatientModalOpen}
        onClose={() => setNewPatientModalOpen(false)}
        onSuccessfulSubmission={handleNewPatientCreation}
        simplifiedView={true}
        initialName={currentSearchText}
      />
    </>
  );
};

export default PatientPicker;
