import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
} from "@mui/material";
import PatientPicker from "./PatientPicker";
import { Patient } from "../../types/types";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";

interface AssignNoteDialogProps {
  open: boolean;
  onClose: () => void;
  noteId: string;
  onAssignSuccess: () => void;
}

const AssignPatientToNoteDialog: React.FC<AssignNoteDialogProps> = ({
  open,
  onClose,
  noteId,
  onAssignSuccess,
}) => {
  const [selectedPatient, setSelectedPatient] = useState<Patient | undefined>(
    undefined
  );
  const { getAccessToken } = useUser();

  const handlePatientSelect = (patient: Patient | undefined) => {
    setSelectedPatient(patient);
  };

  const handleAssign = async () => {
    if (!selectedPatient) return;

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient/assign-note",
        accessToken,
        body: {
          patient_id: selectedPatient.patient_id,
          note_id: noteId,
        },
      });

      if (response.ok) {
        onAssignSuccess();
        onClose();
      } else {
        console.error("Failed to assign note to patient");
      }
    } catch (error) {
      console.error("Error assigning note to patient:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Assign Note to Patient</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2, minWidth: 300 }}>
          <PatientPicker onPatientSelect={handlePatientSelect} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleAssign} disabled={!selectedPatient}>
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignPatientToNoteDialog;
