import { UserNoteType, UserNoteTypeII } from "../types/types";

interface NotesCacheData {
  notes: UserNoteTypeII[];
  totalCount: number;
  timestamp: number;
  filters: {
    category: "all" | "reviewing" | "completed";
    sortOrder: number;  // -1 for newest first, 1 for oldest first
    searchTerm: string;
    itemsPerPage: number;
    currentPage: number;
    groupId?: string;
  };
}

const generateCacheKey = (filters: NotesCacheData['filters']): string => {
  const {
    category,
    sortOrder,
    itemsPerPage,
    currentPage,
    groupId,
    searchTerm
  } = filters;

  // Create a cache key that includes sort order
  const keyParts = [
    groupId ? `group_${groupId}` : 'notes',
    category,
    `sort_${sortOrder === -1 ? 'newest' : 'oldest'}`,
    `page_${currentPage}`,
    `count_${itemsPerPage}`,
    searchTerm ? `search_${searchTerm}` : null
  ].filter(Boolean);

  return keyParts.join('_');
};

export const getCachedNotes = (filters: NotesCacheData['filters']): NotesCacheData | null => {
  try {
    const cacheKey = generateCacheKey(filters);
    const cached = localStorage.getItem(cacheKey);
    if (!cached) return null;
    return JSON.parse(cached) as NotesCacheData;
  } catch (error) {
    console.error('Error reading notes cache:', error);
    return null;
  }
};

export const setCachedNotes = (data: NotesCacheData): void => {
  try {
    const cacheKey = generateCacheKey(data.filters);
    localStorage.setItem(cacheKey, JSON.stringify({
      ...data,
      timestamp: Date.now()
    }));
  } catch (error) {
    console.error('Error setting notes cache:', error);
  }
};

export const isCacheValid = (cache: NotesCacheData | null, currentFilters: NotesCacheData['filters']): boolean => {
  if (!cache) return false;
  
  // Cache expires after a week
  const CACHE_TTL = 7 * 24 * 60 * 60 * 1000;
  if (Date.now() - cache.timestamp > CACHE_TTL) return false;
  
  // Check if filters match
  return Object.entries(currentFilters).every(
    ([key, value]) => cache.filters[key as keyof typeof currentFilters] === value
  );
};

// Add a utility function to clear all note caches
export const clearAllNotesCaches = () => {
  try {
    const keys = Object.keys(localStorage);
    keys.forEach(key => {
      if (key.startsWith('notes') || key.startsWith('group_')) {
        localStorage.removeItem(key);
      }
    });
  } catch (error) {
    console.error('Error clearing notes caches:', error);
  }
};

interface DashboardCacheData {
  notes: UserNoteTypeII[];
  totalCount: number;
  timestamp: number;
  filters: {
    category: "reviewing";
    limit: number;
    offset: number;
  };
}

export const getDashboardCache = (): DashboardCacheData | null => {
  try {
    const cached = localStorage.getItem('dashboard_notes_cache');
    if (!cached) return null;
    return JSON.parse(cached) as DashboardCacheData;
  } catch (error) {
    console.error('Error reading dashboard cache:', error);
    return null;
  }
};

export const setDashboardCache = (data: DashboardCacheData): void => {
  try {
    localStorage.setItem('dashboard_notes_cache', JSON.stringify({
      ...data,
      timestamp: Date.now()
    }));
  } catch (error) {
    console.error('Error setting dashboard cache:', error);
  }
};

export const isDashboardCacheValid = (cache: DashboardCacheData | null): boolean => {
  if (!cache) return false;
  
  // Cache expires after 5 minutes for dashboard
  const CACHE_TTL =  7 * 24 * 60 * 60 * 1000;
  return Date.now() - cache.timestamp <= CACHE_TTL;
};
