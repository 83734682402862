import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useLoaderData, useParams } from "react-router-dom";
import { UserProvider } from "../../context/user";
import { Nav } from "../../components/Nav";
import { AudioDataProvider } from "../../context/AudioDataContext";
import { RecordingContextProvider } from "../../context/RecordingContext";
import { useSearchParams } from "react-router-dom";

export type UserDataType = any;

export const AuthenticationSignupWrapper = () => {
  // production
  const domain = "auth.smartscribe.health";
  const clientId = "RG4FwiozZWwAAe83xQxv2metpgJaPe0j";
  const redirectUri = window.location.origin + "/callback";
  const audience = "https://api.smartscribe.health";

  // development
  // const domain = "smartscribe-development.us.auth0.com";
  // const clientId = "ohWExKeDUG8L1LY5oRJXPseSfaqN1mid";
  // const redirectUri = window.location.origin + "/callback";
  // const audience = "https://api.smartscribe.health/development";

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri,
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <UserProvider>
        <AudioDataProvider>
          <RecordingContextProvider>
            <Nav />
            <UserSignUp />
          </RecordingContextProvider>
        </AudioDataProvider>
      </UserProvider>
    </Auth0Provider>
  );
};

const UserSignUp = () => {
  const { loginWithRedirect } = useAuth0();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("signin");
  const secret_p = searchParams.get("p");
  const action = searchParams.get("action");
  const referralCode = searchParams.get("ref");
  useEffect(() => {
    // Capture and store URL parameters
    const params = new URLSearchParams(window.location.search);
    const paramsObj: { [key: string]: string } = {};
    params.forEach((value: string, key: string) => {
      paramsObj[key] = value;
    });
    localStorage.setItem("urlParams", JSON.stringify(paramsObj));

    // Store Group Invite parameters in localStorage
    localStorage.setItem("secret_p", secret_p ? secret_p : "");
    localStorage.setItem("action", action ? action : "");

    // Store referral code in localStorage
    localStorage.setItem("referralCode", referralCode ? referralCode : "");
    loginWithRedirect({
      authorizationParams: {
        screen_hint: id ? "" : "signup",
        redirect_uri: window.location.origin + "/callback",
      },
    });
  }, []);

  return <div className="max-w-screen-lg mx-auto">Redirecting...</div>;
};

export default UserSignUp;
