// /src/components/RootWrapper.tsx
import { Button, Grid, Divider, Slider, Typography } from "@mui/material";
import React from "react";
import { Box } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useThemeContext } from "../context/theme/context";
import APIService from "../services/APIService";
import { useUser } from "../context/user";
import { useLocation, useNavigate } from "react-router-dom";
import LayoutWrapper from "./layout/UILayout";

interface LockoutProps {
  user_email?: string;
  payment_status?: string;
}

export const Lockout: React.FC<LockoutProps> = ({
  user_email,
  payment_status,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userState, getAccessToken } = useUser();
  const isRecorderPage = location.pathname.includes("/record");
  const { logout } = useAuth0();
  const [stripeLink, setStripeLink] = useState("");
  const [showBillingButton, setShowBillingButton] = useState(true); // New state to control the visibility of the button
  const notesCreated = userState?.userMetrics?.notes_created || 0;
  const firstLoginTime = userState?.firstLoginTime;
  const currentTimeUTC = Date.now() / 1000;
  const daysOfUsage = firstLoginTime ? Math.ceil((currentTimeUTC - firstLoginTime)/86400) : 10;
  const [timeSavedSlider, setTimeSavedSlider] = useState(25);
  const [patientEncounterPerct, setPatientEncounterPerct] = useState(30);
  const { brandLanguage } = useThemeContext();

  const calculateHoursSaved = () => {
    const hoursSaved =
      (notesCreated *
        timeSavedSlider *
        (100 / patientEncounterPerct) *
        (365 / daysOfUsage)) /
      60;
    return Math.round(hoursSaved);
  };

  const stripeBillingLink = `https://billing.stripe.com/p/login/7sIdRo5NjdyN1QA8ww?prefilled_email=${encodeURIComponent(
    user_email || ""
  )}`;

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userInfo");
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  useEffect(() => {
    const setStripeButtonLink = async () => {
      if (payment_status === "canceled") {
        try {
          const accessToken = await getAccessToken();

          const response = await APIService.makeAPIPostRequest({
            requestString: "/user/createOrRetrieveCheckoutSession",
            accessToken: accessToken,
            body: {}, // Assuming no body is needed based on the original code
          });

          if (response.ok) {
            const data = response.value;
            console.log(data);

            if (!(data && data.checkout_session_url)) {
              setShowBillingButton(false);
              setStripeLink("");
            } else {
              setStripeLink(data.checkout_session_url);
              setShowBillingButton(true);
            }
          }
        } catch (error) {
          setShowBillingButton(false);
          setStripeLink("");
        }
      } else {
        // Set stripe link to default billing portal if payment_status is not canceled
        setStripeLink(stripeBillingLink);
      }
    };

    setStripeButtonLink();
  }, [payment_status]);

  if (isRecorderPage) {
    return null; // Don't show lockout on recorder page
  }

  return (
    <Grid container spacing={2} sx={{ marginTop: 4, maxWidth: "100%" }}>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            minWidth: "350px",
            bgcolor: "backgroundColors.secondary",
            border: 1,
            borderColor: "borderColors.primary",
            borderRadius: "0.75rem",

            alignItems: "flex-start",
            padding: "1rem 1.5rem",
            margin: "0.5rem",
            width: "100%",
          }}
        >
          {/* <h3 style={{ fontWeight: "bold" }} className="mb-4 text-lg">
            Action Required: Update Your Subscription
          </h3> */}
          {(function () {
            switch (payment_status) {
              case "paused":
                return (
                  <>
                    <LockoutHeaderText message="Begin Your Paid Subscription" />
                    <p style={{ marginBottom: "20px" }}>
                      Hi there! 👋{" "}
                      <strong>
                        It looks like your free trial has just wrapped up.
                      </strong>{" "}
                    </p>
                    <p style={{ marginBottom: "20px" }}>
                      By subscribing, you'll be back to spending less time
                      charting and more time focusing on patient care, family,
                      friends, or literally anything else. We'd love to have you
                      onboard.
                    </p>
                    <p style={{ marginBottom: "20px" }}>
                      If you can't get to this right away, you can continue to
                      record notes with your companion app. However, you won't
                      be able to view any notes until your subscription is
                      active.
                    </p>
                    <p style={{ marginBottom: "20px" }}>
                      Click the button below to head over to our billing portal
                      and begin your subscription!
                    </p>
                    {/* <p style={{ marginBottom: "20px" }}>
                      Don't let the momentum stop! Click the button
                      below to head over to our billing portal and begin your
                      subscription!
                    </p> */}
                    <ContactUsText />
                    <BillingLinkButton
                      stripeLink={stripeLink}
                      message={"Billing Portal"}
                    />
                    <p style={{ marginTop: "20px", marginBottom: "20px" }}>
                      <span style={{ fontWeight: "bold" }}>
                        Looking to extend your trial?
                      </span>{" "}
                      Earn 10 more days by signing up for a 10 minute user
                      interview with us.
                    </p>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ width: "100%", margin: "4px 0 20px 0" }}
                      onClick={() =>
                        window.open(
                          "https://calendly.com/d/cptr-vyp-dpz/jotpsych-trial-feedback-and-extension",
                          "_blank"
                        )
                      }
                    >
                      Schedule user interview to extend trial
                    </Button>
                  </>
                );
              case "past_due":
              case "unpaid":
                return (
                  <>
                    <LockoutHeaderText message="Update Your Payment Information" />
                    <p style={{ marginBottom: "20px" }}>
                      Oops! 🤔{" "}
                      <strong>
                        {" "}
                        We've noticed there's been an issue with your{" "}
                        {brandLanguage.brandName} subscription payment.
                      </strong>
                    </p>
                    <p style={{ marginBottom: "20px" }}>
                      To get back on track, simply click the billing portal
                      button below. It's a quick process to update your payment
                      details, and then you'll be all set to continue enjoying
                      the benefits of {brandLanguage.brandName}.
                    </p>
                    <p style={{ marginBottom: "20px" }}>
                      If you can't get to this right away, you can continue to
                      record notes with your companion app. However, you won't
                      be able to view any notes until this issue is resolved.
                    </p>
                    <p style={{ marginBottom: "20px" }}>
                      You can still access the recorder to create new notes.
                      Click the button below to go to the recorder.
                    </p>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ width: "100%", margin: "4px 0 20px 0" }}
                      onClick={() => navigate("/record")}
                    >
                      Go to Recorder
                    </Button>
                    <ContactUsText />
                    <BillingLinkButton
                      stripeLink={stripeLink}
                      message={"Billing Portal"}
                    />
                  </>
                );
              case "canceled":
                return (
                  <>
                    <LockoutHeaderText message="Did you mean to cancel?" />
                    <p style={{ marginBottom: "20px" }}>
                      <strong>
                        It looks like your {brandLanguage.brandName}{" "}
                        subscription has been canceled.
                      </strong>{" "}
                      😢
                    </p>
                    <p style={{ marginBottom: "20px" }}>
                      By resubscribing, you'll be back to spending less time
                      charting and more time focusing on patient care, family,
                      friends, or literally anything else. We'd love to have you
                      back.
                    </p>
                    <p style={{ marginBottom: "20px" }}>
                      You can still access the recorder to create new notes.
                      Click the button below to go to the recorder.
                    </p>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ width: "100%", margin: "4px 0 20px 0" }}
                      onClick={() => navigate("/record")}
                    >
                      Go to Recorder
                    </Button>
                    {showBillingButton ? (
                      <>
                        <ContactUsText />
                        <BillingLinkButton
                          stripeLink={stripeLink}
                          message={"Reactivate your Subscription"}
                        />
                      </>
                    ) : (
                      <>
                        <p style={{ marginBottom: "20px" }}>
                          <strong>
                            There has been an issue and more information is
                            required to begin your subscription.
                          </strong>{" "}
                        </p>
                        <div style={{ marginBottom: "20px" }}>
                          Please{" "}
                          <a
                            href={`https://${brandLanguage.brandSite}/contact`}
                            target="_blank"
                            className="text-blue-500"
                          >
                            get in contact with us{" "}
                          </a>
                          as soon as possible.
                        </div>
                      </>
                    )}
                  </>
                );
              default:
                return (
                  <>
                    <LockoutHeaderText message="Please Contact Our Team" />
                    <p style={{ marginBottom: "20px" }}>
                      <strong>
                        There has been a technical issue with your{" "}
                        {brandLanguage.brandName} account or payment.
                      </strong>{" "}
                      🤔
                    </p>
                    <div style={{ marginBottom: "20px" }}>
                      Please{" "}
                      <a
                        href={`https://${brandLanguage.brandSite}/contact`}
                        target="_blank"
                        className="text-blue-500"
                      >
                        get in contact with us{" "}
                      </a>
                      as soon as possible.
                    </div>
                  </>
                );
            }
          })()}
        </Box>

        <div style={{ alignSelf: "flex-end", marginTop: "auto" }}>
          <a
            href="#"
            onClick={handleLogout}
            style={{
              textDecoration: "underline", // Makes it look like a link
              fontSize: "14px", // Smaller text
              cursor: "pointer", // Changes cursor to pointer on hover
            }}
          >
            Logout of {brandLanguage.brandName}
          </a>
        </div>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box
          sx={{
            minWidth: "350px",
            bgcolor: "backgroundColors.secondary",
            border: 1,
            borderColor: "borderColors.primary",
            borderRadius: "0.75rem",

            alignItems: "flex-start",
            padding: "1rem 1.5rem",
            margin: "0.5rem",
            width: "100%",
          }}
        >
          <Box
            sx={{
              m: 2,
            }}
          >
            <h2 style={{ fontWeight: "bold" }} className="mb-4 text-lg">
              Does JotPsych make sense for me?
            </h2>
          </Box>
          <Divider />
          <Box
            sx={{
              m: 2,
              color: "textColors.primaryText",
            }}
          >
            <Typography variant="body2" sx={{ pb: 1 }}>
              JotPsych saved me{" "}
              <b>
                {timeSavedSlider}
                {timeSavedSlider == 60 ? "+" : ""}
              </b>{" "}
              mins per note
            </Typography>
            <Slider
              value={timeSavedSlider}
              min={2}
              max={60}
              aria-label="Default"
              valueLabelDisplay="auto"
              onChange={(e, newValue: number | number[]) =>
                setTimeSavedSlider(
                  Array.isArray(newValue) ? newValue[0] : newValue
                )
              } // Ensure newValue is a number
              sx={{ mb: 0, pb: 0, color: "newDesignColors.eggplant900" }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 0,
                paddingTop: 0,
              }}
            >
              <span>2 min</span>
              <span>60+ min</span>
            </div>
          </Box>
          <Box
            sx={{
              m: 2,
              mt: 4,
              color: "textColors.primaryText",
            }}
          >
            <Typography variant="body2" sx={{ pb: 1 }}>
              During my trial I used JotPsych for{" "}
              <b>{patientEncounterPerct}%</b> of my patient encounters
            </Typography>
            <Slider
              value={patientEncounterPerct}
              min={0}
              max={100}
              aria-label="Default"
              valueLabelDisplay="auto"
              onChange={(e, newValue: number | number[]) =>
                setPatientEncounterPerct(
                  Array.isArray(newValue) ? newValue[0] : newValue
                )
              } // Ensure newValue is a number
              sx={{ mb: 0, pb: 0, color: "newDesignColors.eggplant900" }}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 0,
                paddingTop: 0,
              }}
            >
              <span>0%</span>
              <span>100%</span>
            </div>
          </Box>
          <Box sx={{ m: 2, my: 4 }}>
            <div
              className="rounded-lg p-4 text-center"
              style={{ backgroundColor: "rgb(28, 12, 84)" }}
            >
              <p className="text-3xl font-bold text-white">
                {calculateHoursSaved()} hrs per year
              </p>
              <p className="text-sm text-indigo-100">
                Estimated time saved per year with JotPsych
              </p>
            </div>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const ContactUsText = () => {
  const { brandLanguage } = useThemeContext();
  return (
    <div style={{ marginBottom: "20px" }}>
      If you believe you're seeing this message in error, or if you have
      questions or concerns, please{" "}
      <a
        href={`https://${brandLanguage.brandSite}/contact`}
        target="_blank"
        className="text-blue-500"
      >
        get in touch with us
      </a>
      .
    </div>
  );
};

interface BillingLinkButtonProps {
  stripeLink: string;
  message: string;
}
const BillingLinkButton: React.FC<BillingLinkButtonProps> = ({
  stripeLink,
  message,
}) => {
  return (
    <div>
      <a href={stripeLink} target="_blank" style={{ width: "100%" }}>
        <Button
          variant="contained"
          color="primary"
          style={{ width: "100%", margin: 4 }}
        >
          {message}
        </Button>
      </a>
    </div>
  );
};

interface LockoutHeaderTextProps {
  message: string;
}
const LockoutHeaderText: React.FC<LockoutHeaderTextProps> = ({ message }) => {
  return (
    <h3 style={{ fontWeight: "bold" }} className="mb-4 text-lg">
      Action Required: {message}
    </h3>
  );
};
