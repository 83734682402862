import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  Paper,
  Typography,
  useTheme,
  Chip,
  Button,
  Box,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridRowId,
  GridValueGetter,
  GridColumnVisibilityModel,
  GridRowModesModel,
  GridRowModes,
  GridActionsCellItem,
  GridEventListener,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

export interface Diagnosis {
  diagnosis_name: string;
  code: string;
  start_date: string | null;
  end_date: string | null;
  isActive: boolean;
  changes?: {
    values_changed: Record<string, { new_value: string; old_value: string }>;
  };
  new?: any;
  old?: any;
}

export interface DiagnosisChange {
  added: Diagnosis[];
  removed: Diagnosis[];
  changed: Diagnosis[];
  unchanged: Diagnosis[];
}

interface DiagnosesDataBlockProps {
  diagnoses?: Diagnosis[];
  diagnosisChanges: DiagnosisChange | null;
  onDiagnosesUpdate: (
    diagnoses: Diagnosis[],
    action: "accept" | "reject" | "edit"
  ) => void;
}

const DiagnosesDataBlock: React.FC<DiagnosesDataBlockProps> = ({
  diagnoses = [],
  diagnosisChanges,
  onDiagnosesUpdate,
}) => {
  const theme = useTheme();
  const [filter, setFilter] = useState<"all" | "active" | "inactive">("all");
  const [isDraftMode, setIsDraftMode] = useState(false);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});

  // Set isDraftMode based on whether there are changes
  useEffect(() => {
    setIsDraftMode(
      !!diagnosisChanges &&
        (diagnosisChanges.added.length > 0 ||
          diagnosisChanges.removed.length > 0 ||
          diagnosisChanges.changed.length > 0)
    );
  }, [diagnosisChanges]);

  const getRowClassName = useCallback(
    (params: { row: any }) => {
      if (!isDraftMode || !diagnosisChanges) return "";

      if (params.row.isAdded) return "added-row";
      if (params.row.isChanged) return "changed-row";
      if (
        diagnosisChanges.removed.some(
          (d) => d.diagnosis_name === params.row.diagnosis_name
        )
      ) {
        return "removed-row";
      }
      return "";
    },
    [diagnosisChanges, isDraftMode]
  );

  const renderCell = useCallback(
    (params: any) => {
      if (!isDraftMode) {
        return params.field === "start_date" || params.field === "end_date"
          ? formatDate(params.value)
          : params.value;
      }

      const changedDiagnosis = diagnosisChanges?.changed.find(
        (d) => d.diagnosis_name === params.id
      );

      const isChanged =
        changedDiagnosis?.changes?.values_changed?.[`root['${params.field}']`];

      let displayValue = params.value;

      if (isChanged && changedDiagnosis?.new) {
        displayValue = changedDiagnosis.new[params.field];
      }

      if (params.field === "start_date" || params.field === "end_date") {
        displayValue = formatDate(displayValue);
      }

      return (
        <Tooltip
          title={isChanged ? `Previous value: ${isChanged.old_value}` : ""}
        >
          <div
            style={{
              textDecoration: isChanged ? "underline wavy #1976d2" : "none",
              textUnderlineOffset: "3px",
            }}
          >
            {displayValue}
          </div>
        </Tooltip>
      );
    },
    [diagnosisChanges, isDraftMode]
  );

  const formatDate = (date: Date | string | null): string => {
    if (!date) return "";
    if (typeof date === "string") return date.split("T")[0];
    return date.toISOString().split("T")[0];
  };

  const handleRowEditStop = useCallback<GridEventListener<"rowEditStop">>(
    (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    },
    []
  );

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const updatedDiagnoses = diagnoses.filter(
      (diag) => diag.diagnosis_name !== id
    );
    onDiagnosesUpdate(updatedDiagnoses, "edit");
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = diagnoses.find((diag) => diag.diagnosis_name === id);
    if (editedRow && (editedRow as any).isNew) {
      const updatedDiagnoses = diagnoses.filter(
        (diag) => diag.diagnosis_name !== id
      );
      onDiagnosesUpdate(updatedDiagnoses, "edit");
    }
  };

  const processRowUpdate = useCallback(
    (newRow: GridRowModel) => {
      const updatedRow = { ...newRow, isNew: false } as unknown as Diagnosis;
      const updatedDiagnoses = diagnoses.map((diag) =>
        diag.diagnosis_name === newRow.id ? updatedRow : diag
      );
      onDiagnosesUpdate(updatedDiagnoses, "edit");
      return updatedRow;
    },
    [diagnoses, onDiagnosesUpdate]
  );

  const handleRowModesModelChange = useCallback(
    (newRowModesModel: GridRowModesModel) => {
      setRowModesModel(newRowModesModel);
    },
    []
  );

  const handleAcceptChanges = () => {
    if (!diagnosisChanges) return;

    const updatedDiagnoses = [
      ...diagnoses
        .filter(
          (diag) =>
            !diagnosisChanges.removed.some(
              (r) => r.diagnosis_name === diag.diagnosis_name
            )
        )
        .map((diag) => {
          const changedDiag = diagnosisChanges.changed.find(
            (c) => c.diagnosis_name === diag.diagnosis_name
          );
          return changedDiag ? { ...diag, ...changedDiag.new } : diag;
        }),
      ...diagnosisChanges.added,
      ...diagnosisChanges.changed
        .filter(
          (changedDiag) =>
            !diagnoses.some(
              (diag) => diag.diagnosis_name === changedDiag.diagnosis_name
            )
        )
        .map((changedDiag) => changedDiag.new),
    ];

    onDiagnosesUpdate(updatedDiagnoses, "accept");
    setIsDraftMode(false);
  };

  const handleRejectChanges = () => {
    onDiagnosesUpdate(diagnoses, "reject");
    setIsDraftMode(false);
  };

  const handleFilterChange = (
    event: SelectChangeEvent<"all" | "active" | "inactive">
  ) => {
    setFilter(event.target.value as "all" | "active" | "inactive");
  };

  const handleAddRow = () => {
    const newDiagnosis: Diagnosis = {
      diagnosis_name: `New Diagnosis ${diagnoses.length + 1}`,
      code: "",
      start_date: null,
      end_date: null,
      isActive: true,
    };
    onDiagnosesUpdate([...diagnoses, newDiagnosis], "edit");
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "diagnosis_name",
        headerName: "Diagnosis",
        flex: 2,
        editable: true,
        renderCell,
      },
      {
        field: "code",
        headerName: "Code",
        flex: 1,
        editable: true,
        renderCell,
      },
      {
        field: "start_date",
        headerName: "Start Date",
        flex: 1,
        editable: true,
        type: "date",
        valueGetter: (_, row) => {
          const value = row.start_date;
          return value ? new Date(value) : null;
        },
        valueFormatter: (_, row) => formatDate(row.start_date),
        renderCell,
      },
      {
        field: "end_date",
        headerName: "End Date",
        flex: 1,
        editable: true,
        type: "date",
        valueGetter: (_, row) => {
          const value = row.end_date;
          return value ? new Date(value) : null;
        },
        valueFormatter: (_, row) => formatDate(row.end_date),
        renderCell,
      },
      {
        field: "isActive",
        headerName: "Status",
        flex: 1,
        editable: true,
        type: "boolean",
        renderCell: (params) => {
          const isChanged =
            isDraftMode &&
            diagnosisChanges?.changed.some(
              (d) =>
                d.diagnosis_name === params.id &&
                d.changes?.values_changed?.[`root['isActive']`]
            );

          const changedDiagnosis = diagnosisChanges?.changed.find(
            (d) => d.diagnosis_name === params.id
          );

          const displayValue = isChanged
            ? changedDiagnosis?.new.isActive
            : params.value;

          const chip = (
            <Chip
              label={displayValue ? "Active" : "Inactive"}
              color={displayValue ? "primary" : "default"}
              size="small"
            />
          );

          if (isChanged) {
            const oldValue =
              changedDiagnosis?.changes?.values_changed[`root['isActive']`]
                .old_value;
            return (
              <Tooltip
                title={`Previous value: ${oldValue ? "Active" : "Inactive"}`}
              >
                <Box
                  sx={{
                    position: "relative",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      left: 0,
                      right: 0,
                      bottom: "-3px",
                      height: "2px",
                      background:
                        "repeating-linear-gradient(to right, #1976d2 0, #1976d2 4px, transparent 4px, transparent 8px)",
                    },
                  }}
                >
                  {chip}
                </Box>
              </Tooltip>
            );
          }

          return chip;
        },
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 100,
        cellClassName: "actions",
        getActions: ({ id }) => {
          if (isDraftMode) return [];

          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{ color: "primary.main" }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      },
    ],
    [
      renderCell,
      handleEditClick,
      handleSaveClick,
      handleDeleteClick,
      handleCancelClick,
      diagnosisChanges,
      isDraftMode,
    ]
  );

  const rows = useMemo(() => {
    if (!diagnosisChanges) {
      return diagnoses.map((diag) => ({ ...diag, id: diag.diagnosis_name }));
    }

    const allDiagnoses = diagnoses.map((diag) => {
      const changedDiag = diagnosisChanges.changed.find(
        (d) => d.diagnosis_name === diag.diagnosis_name
      );
      if (changedDiag) {
        return {
          ...diag,
          ...changedDiag.new,
          id: diag.diagnosis_name,
          isChanged: true,
        };
      }
      return { ...diag, id: diag.diagnosis_name };
    });

    // Add new diagnoses
    diagnosisChanges.added.forEach((diag) => {
      allDiagnoses.push({
        ...diag,
        id: diag.diagnosis_name,
        isAdded: true,
      });
    });

    // Filter out removed diagnoses
    return allDiagnoses.filter(
      (diag) =>
        !diagnosisChanges.removed.some(
          (r) => r.diagnosis_name === diag.diagnosis_name
        )
    );
  }, [diagnoses, diagnosisChanges]);

  const filteredRows = useMemo(() => {
    const filtered = rows.filter((row) => {
      switch (filter) {
        case "active":
          return row.isActive;
        case "inactive":
          return !row.isActive;
        default:
          return true;
      }
    });

    // Ensure each row has a unique id
    return filtered.map((row, index) => ({
      ...row,
      id: row.id || row.diagnosis_name || `row-${index}`,
    }));
  }, [rows, filter]);

  const initialColumnVisibilityModel = useMemo(() => {
    const visibilityModel: GridColumnVisibilityModel = {};
    columns.forEach((column) => {
      if (column.field === "actions") {
        visibilityModel[column.field] = true;
      } else {
        const hasValue = rows.some((row) => {
          const value = row[column.field as keyof typeof row];
          return value !== null && value !== undefined && value !== "";
        });
        visibilityModel[column.field] = hasValue;
      }
    });
    return visibilityModel;
  }, []);

  useEffect(() => {
    setColumnVisibilityModel(initialColumnVisibilityModel);
  }, [initialColumnVisibilityModel]);

  const handleColumnVisibilityModelChange = useCallback(
    (newModel: GridColumnVisibilityModel) => {
      setColumnVisibilityModel(newModel);
    },
    []
  );

  return (
    <Paper
      elevation={1}
      sx={{
        p: 2,
        mb: 2,
        backgroundColor: theme.palette.backgroundColors?.secondary,
        width: "100%",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h6">
          Diagnoses
          {isDraftMode && (
            <Chip label="Draft" color="warning" size="small" sx={{ ml: 1 }} />
          )}
        </Typography>
        <Box display="flex" alignItems="center">
          <Select
            value={filter}
            onChange={handleFilterChange}
            size="small"
            sx={{ minWidth: 120, mr: 2 }}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </Select>
          {!isDraftMode && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddRow}
            >
              Add Diagnosis
            </Button>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.background.default,
        }}
      >
        <DataGrid
          key={filteredRows.length}
          rows={filteredRows}
          columns={columns}
          autoHeight
          disableRowSelectionOnClick
          getRowClassName={getRowClassName}
          processRowUpdate={processRowUpdate}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          hideFooter
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          isRowSelectable={() => !isDraftMode}
          isCellEditable={() => !isDraftMode}
          sx={{
            "& .added-row": {
              backgroundColor: theme.palette.success.light + "80",
            },
            "& .removed-row": {
              backgroundColor: theme.palette.error.light + "80",
              opacity: 0.5,
            },
            "& .changed-row": {
              backgroundColor: theme.palette.warning.light + "80",
            },
            "& .MuiDataGrid-row:hover": {
              "&.added-row": {
                backgroundColor: `${theme.palette.success.light}CC`,
              },
              "&.removed-row": {
                backgroundColor: `${theme.palette.error.light}CC`,
                opacity: 0.7,
              },
              "&.changed-row": {
                backgroundColor: `${theme.palette.warning.light}CC`,
              },
            },
            "& .MuiDataGrid-root": {
              overflowX: "auto",
            },
            "& .MuiDataGrid-cell": {
              flex: 1,
            },
            "& .MuiDataGrid-columnHeader": {
              flex: 1,
            },
          }}
          getRowId={(row) => row.id}
        />
      </Box>
      {isDraftMode && diagnosisChanges && (
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="inherit"
            onClick={handleRejectChanges}
            sx={{ mr: 1, backgroundColor: "grey.300" }}
          >
            Reject Changes
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAcceptChanges}
          >
            Accept Changes
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default DiagnosesDataBlock;
