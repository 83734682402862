import React from "react";
import { AdminSection } from "../../types/types";
import ModulePicker from "./pickers/ModulePicker";

interface SectionEditorProps {
  section: AdminSection;
  onChange: (updatedSection: AdminSection) => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
  onRemoveSection: () => void;
  showUserModules?: boolean;
}

export const SectionEditor: React.FC<SectionEditorProps> = ({
  section,
  onChange,
  onMoveUp,
  onMoveDown,
  onRemoveSection,
  showUserModules = false,
}) => {
  const handleModuleSelect = (moduleId: string) => {
    onChange({
      ...section,
      module_id: moduleId,
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...section,
      is_summary_section: event.target.checked,
    });
  };

  const handleUpdatableCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onChange({
      ...section,
      updatable: event.target.checked,
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...section,
      [event.target.name]: event.target.value,
    });
  };

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...section,
      [event.target.name]: Number(event.target.value),
    });
  };

  return (
    <div className="my-4 bg-slate-100 p-2 rounded-md shadow">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-xl font-bold mb-2">{section.display_name}</h2>
        </div>
        <div className="flex items-center">
          <button
            onClick={onRemoveSection}
            className="px-2 py-1 mr-2 bg-red-500 text-white rounded hover:bg-gray-700 text-xs"
          >
            Delete
          </button>
          <button
            onClick={onMoveUp}
            className="px-2 py-1 mr-2 bg-gray-500 text-white rounded hover:bg-gray-700 text-xs"
          >
            Move Up
          </button>
          <button
            onClick={onMoveDown}
            className="px-2 py-1 bg-gray-500 text-white rounded hover:bg-gray-700 text-xs"
          >
            Move Down
          </button>
          <div className="mx-2 flex flex-col items-center">
            <div className=" text-gray-500 text-xs">Summary?</div>
            <input
              type="checkbox"
              checked={section.is_summary_section}
              onChange={handleCheckboxChange}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
          </div>
          <div className="mx-2 flex flex-col items-center">
            <div className=" text-gray-500 text-xs">Updatable?</div>
            <input
              type="checkbox"
              checked={section.updatable}
              onChange={handleUpdatableCheckboxChange}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-between space-x-4">
        <div className="mb-2 w-1/2">
          <ModulePicker
            onModuleSelect={handleModuleSelect}
            initialModuleId={section.module_id}
            showUserModules={showUserModules}
          />
        </div>

        <div className="mb-2 w-1/2">
          <label className="block text-sm text-gray-500" htmlFor="display_name">
            Display Name
          </label>
          <input
            type="text"
            name="display_name"
            id="display_name"
            value={section.display_name}
            onChange={handleInputChange}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div>

      <div className="flex justify-between space-x-4">
        <div className="w-1/3">
          <label className="block text-sm text-gray-500" htmlFor="model">
            Model
          </label>
          <input
            type="text"
            name="model"
            id="model"
            value={section.model}
            onChange={handleInputChange}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div className="w-1/3">
          <label className="block text-sm text-gray-500" htmlFor="temperature">
            Temperature
          </label>
          <input
            type="number"
            name="temperature"
            id="temperature"
            value={section.temperature}
            onChange={handleNumberChange}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div className="w-1/3">
          <label className="block text-sm text-gray-500" htmlFor="max_tokens">
            Max Tokens
          </label>
          <input
            type="number"
            name="max_tokens"
            id="max_tokens"
            value={section.max_tokens}
            onChange={handleNumberChange}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div>
    </div>
  );
};
