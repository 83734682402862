import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  Box,
  CardActionArea,
  Link,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";

export interface DismissableCardProps {
  headerTitle: string;
  bodyText: string[];
  tooltipMessage?: string;
  learnMoreLink?: string;
  learnMoreCallback?: () => void;
  actionButtonContent?: React.ReactNode;
  customStyles?: {
    bgcolor?: string;
    color?: string;
    padding?: string | number;
  };
  isVisible?: boolean;
}

const generateCardId = (props: DismissableCardProps): string => {
  return btoa(
    JSON.stringify({ headerTitle: props.headerTitle, bodyText: props.bodyText })
  );
};

const getVisibilityFromStorage = (cardId: string): boolean => {
  const visibilityFlags = JSON.parse(
    localStorage.getItem("cardVisibilityFlags") || "{}"
  );
  return visibilityFlags[cardId] !== false;
};

const setVisibilityInStorage = (cardId: string, isVisible: boolean): void => {
  const visibilityFlags = JSON.parse(
    localStorage.getItem("cardVisibilityFlags") || "{}"
  );
  visibilityFlags[cardId] = isVisible;
  localStorage.setItem("cardVisibilityFlags", JSON.stringify(visibilityFlags));
};

export const DismissableCard: React.FC<DismissableCardProps> = (props) => {
  const {
    headerTitle,
    bodyText,
    tooltipMessage,
    actionButtonContent,
    customStyles,
    learnMoreLink,
    learnMoreCallback,
    isVisible: initialIsVisible = true,
  } = props;
  const cardId = generateCardId(props);
  const [isVisible, setIsVisible] = useState<boolean | null>(initialIsVisible);

  useEffect(() => {
    const storedVisibility = getVisibilityFromStorage(cardId);
    if (!initialIsVisible) {
      setIsVisible(false);
    } else {
      setIsVisible(storedVisibility);
    }
  }, [cardId, initialIsVisible]);

  if (isVisible === null || !isVisible) {
    return <div data-visible="false" />; // Return an empty div with a data attribute instead of null
  }

  const handleDismiss = () => {
    setIsVisible(false);
    setVisibilityInStorage(cardId, false);
  };

  const handleLearnMoreClick = () => {
    if (learnMoreCallback) {
      learnMoreCallback();
    } else if (learnMoreLink) {
      window.open(learnMoreLink, "_blank", "noopener,noreferrer");
    }
  };

  const hasLearnMore = !!(learnMoreLink || learnMoreCallback);

  const conditionalStyles = {
    bgcolor: customStyles?.bgcolor || "backgroundColors.secondary",
    color: customStyles?.color || "inherit",
    padding: customStyles?.padding || "inherit",
  };

  return (
    <Card
      data-visible="true" // Add this data attribute
      sx={{
        borderRadius: 3,
        ...conditionalStyles,
        borderColor: "borderColors.primary",
        boxShadow: "none",
        border: "1px",
        width: "100%",
        minHeight: "fit-content",
        display: "flex",
        flexDirection: "column",
        flexShrink: 0, // Prevent the card from shrinking
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mx: 2,
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: "bold", my: 1 }}>
          {headerTitle}
        </Typography>
        <IconButton
          size="small"
          sx={{
            color: customStyles?.color || "inherit",
          }}
          onClick={handleDismiss}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <CardContent sx={{ pb: 0 }}>
        {bodyText.map((text, index) => (
          <Typography key={index} variant="body2" sx={{ mb: 2 }}>
            {text}
          </Typography>
        ))}
        {actionButtonContent && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {actionButtonContent}
          </Box>
        )}
      </CardContent>
      {(tooltipMessage || hasLearnMore) && (
        <Tooltip title={tooltipMessage || ""} arrow>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              m: 1,
              color: "textColors.disabled",
              cursor: hasLearnMore ? "pointer" : "default",
            }}
            onClick={hasLearnMore ? handleLearnMoreClick : undefined}
          >
            {tooltipMessage && !hasLearnMore && (
              <InfoOutlinedIcon fontSize="small" />
            )}
            {hasLearnMore && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <InfoOutlinedIcon fontSize="small" />
                <Typography variant="caption" sx={{ ml: 1 }}>
                  Learn More
                </Typography>
              </Box>
            )}
          </Box>
        </Tooltip>
      )}
    </Card>
  );
};
