/**
 * @file src/components/Shared/StartGroupSection.tsx
 * @description This file contains the StartGroupSection React component - used to start a new group.
 * 
 * Author: Soham Dave
 * Date: 2024-09-30
 */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Box,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useUser } from "../../context/user";
import APIService from "../../services/APIService";

interface StartGroupSectionProps {
  setToastOpen: (open: boolean) => void;
  setToastMessage: (message: string) => void;
  setShowShareSection: (show: boolean) => void;
}

const StartGroupSection: React.FC<StartGroupSectionProps> = ({
  setToastOpen,
  setToastMessage,
  setShowShareSection,
}) => {
  const [groupTitle, setGroupTitle] = useState<string>("");
  const { getAccessToken, userState } = useUser();
  const navigate = useNavigate();

  const handleStartGroup = async () => {
    try {
      if (!groupTitle.trim()) {
        setToastMessage("Please enter a group title");
        setToastOpen(true);
        return;
      }
      const newGroup = {
        group_info: {
          name: groupTitle,
        },
        billing_admin: userState?.user_id,
      };

      const accessToken = await getAccessToken();

      const response = await APIService.makeAPIPostRequest({
        requestString: "/group/createGroup",
        accessToken: accessToken,
        body: newGroup,
      });

      if (response.ok) {
        const data = await response.value;
        if (data.status == 201) {
          setToastMessage(data.message || "Group created successfully");
          setToastOpen(true);
          setShowShareSection(false);
          navigate("/group_administration?refresh=true");
        } else {
          setToastMessage("Failed to create group: " + data.message);
          setToastOpen(true);
        }
      } else {
        setToastMessage("Failed to create group: " + response.error);
        setToastOpen(true);
      }
    } catch (error) {
      setToastMessage("Failed to create group: " + error);
      setToastOpen(true);
    }
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0.5px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Box sx={style}>
      <Typography variant="h6" component="h2">
        Start a New Group
      </Typography>
      <IconButton
        onClick={() => setShowShareSection(false)}
        sx={{ position: "absolute", top: 8, right: 8 }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ mt: 2 }}>
        <TextField
          label="Group Title"
          variant="outlined"
          fullWidth
          value={groupTitle}
          onChange={(e) => setGroupTitle(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button
            variant="outlined"
            onClick={() => setShowShareSection(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleStartGroup}
            disabled={!groupTitle.trim()} 
          >
            Start
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default StartGroupSection;
