import { Box } from "@mui/material";
import React from "react";

interface NoteSectionDisplayBoxProps {
  ref?: React.Ref<unknown>;
  isNA?: boolean;
  children: React.ReactNode;
  fontSize?: string | number;
}

export const NoteSectionDisplayBox = ({
  ref,
  isNA,
  children,
  fontSize,
}: NoteSectionDisplayBoxProps) => {
  const formatContent = (content: React.ReactNode) => {
    if (typeof content === "string") {
      return content.split("\n").map((text, index) => (
        <p
          key={index}
          style={{ margin: 0, paddingTop: 2, paddingBottom: 2, fontSize }}
        >
          {text}
        </p>
      ));
    }
    return content;
  };

  return (
    <Box
      ref={ref}
      sx={{
        p: 4,
        border: 1,
        borderRadius: "0.75rem",
        color: isNA ? "textColors.disabled" : "textColors.darkText",
        borderColor: "borderColors.primary",
        bgcolor: "backgroundColors.primary",
        fontSize,
      }}
    >
      {formatContent(children)}
    </Box>
  );
};
