import React, { useEffect, useMemo, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Card,
  CardContent,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Modal,
  Box,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Tabs,
  Tab,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { useUser } from "../../context/user";
import APIService from "../../services/APIService";
import NoteProcessingSessionPicker, {
  NoteProcessingSession,
} from "../../components/Admin/pickers/NoteProcessingSessionPicker";
import { AdminModule } from "../../types/types";
import { ModulesLoader } from "../../loaders/ModulesLoader";

export interface ChunkDocumentBase {
  doc_id: string;
  content: string;
  summary: string;
  key_topics: string[];
  metadata: Record<string, any>;
  chunk_position: number;
  chunk_tokens: number;
  content_embedding: any;
  summary_topic_embedding: any;
  start_line: number;
  end_line: number;
}

interface TranscriptChunkDocument extends ChunkDocumentBase {
  speaker_line_dict: Record<string, number[]>;
}

interface AdditionalContextChunkDocument extends ChunkDocumentBase {}

export interface NoteSectionDocument {
  doc_id: string;
  section_name: string;
  content: string;
  tokens: number;
  content_embedding: any;
  section_name_embedding: any;
}

// Interfaces
export interface AnalysisSessionV2 {
  analysis_session_id: string;
  nodes: {
    transcript_docs?: any[];
    additional_context_docs?: any[];
    previous_note_docs?: any[];
  };
  node_map: {
    [key: string]: {
      transcript_results: [string, number][];
      additional_context_results: [string, number][];
      previous_note_results: [string, number][];
    };
  };
  start_timestamp?: number;
  end_timestamp?: number;
  errors?: Array<Record<string, any>>;
}

// Updated NodeCard component
const NodeCard: React.FC<{
  node: ChunkDocumentBase | NoteSectionDocument;
  score: number;
}> = ({ node, score }) => (
  <Card variant="outlined" sx={{ mb: 2 }}>
    <CardContent>
      <Typography variant="h6" gutterBottom>
        {
          //@ts-ignore
          node.section_name || "chunk" + node.chunk_position
        }
      </Typography>
      <Typography variant="body2">
        <strong>Score:</strong> {score}
      </Typography>
      <Typography variant="body2">
        <strong>Key Topics:</strong>{" "}
        {(node as ChunkDocumentBase).key_topics?.join(", ") || "N/A"}
      </Typography>
      <Typography variant="body2" gutterBottom>
        <strong>Summary:</strong> {(node as ChunkDocumentBase).summary || "N/A"}
      </Typography>
      <Typography variant="body2" gutterBottom>
        <strong>Content:</strong> {node.content}
      </Typography>
    </CardContent>
  </Card>
);

// Updated ResultView component
const ResultView: React.FC<{
  title: string;
  results: Record<string, [string, number][]>;
  nodes: (ChunkDocumentBase | NoteSectionDocument)[];
  modules: AdminModule[] | undefined;
}> = ({ title, results, nodes, modules }) => {
  const getModuleName = (key: string) => {
    const parts = key.split(":");
    const uuidPart = parts[parts.length - 2]; // UUID is always the second part from the right
    const module = modules?.find((m) => m.module_id === uuidPart);
    return module ? module.module_name : key;
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      {Object.entries(results).map(([key, docIds]) => (
        <Accordion key={key}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{getModuleName(key)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="subtitle2" gutterBottom>
              Key: {key}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Selected Nodes:
            </Typography>
            {docIds.map((docId) => {
              const node = nodes.find((n) => n.doc_id === docId[0]);
              return node ? (
                <NodeCard key={docId[0]} node={node} score={docId[1]} />
              ) : null;
            })}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

const AnalysisSessionInspector: React.FC = () => {
  const { getAccessToken } = useUser();
  const [analysisSession, setAnalysisSession] =
    useState<AnalysisSessionV2 | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [modules, setModules] = useState<AdminModule[]>();

  const fetchModulesData = async () => {
    const response = await ModulesLoader();
    if (response) {
      setModules(response);
    }
  };

  const fetchAnalysisSession = async (analysisSessionId: string) => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/noteProcessingSession/getAnalysisSession?analysis_session_id=${analysisSessionId}`,
        accessToken: accessToken,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch analysis session");
      }

      const result = response.value.analysis_session;
      setAnalysisSession(result);
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const handlePickerOpen = () => setIsPickerOpen(true);
  const handlePickerClose = () => setIsPickerOpen(false);

  const handleSessionSelect = (session: NoteProcessingSession) => {
    handlePickerClose();
    if (!session.analysis_session_id) {
      alert("No analysis session");
      return;
    }
    fetchAnalysisSession(session.analysis_session_id);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    fetchModulesData();
  }, []);

  const renderContent = () => {
    if (loading) {
      return <CircularProgress />;
    }

    if (error) {
      return <Typography color="error">{error}</Typography>;
    }

    if (!analysisSession) {
      return (
        <Typography>
          No analysis session selected. Please choose a session to inspect.
        </Typography>
      );
    }

    return (
      <>
        <Typography variant="subtitle1" gutterBottom>
          Session ID: {analysisSession.analysis_session_id}
        </Typography>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Transcript" />
          <Tab label="Additional Context" />
          <Tab label="Previous Note" />
        </Tabs>
        <Box sx={{ mt: 2 }}>
          {activeTab === 0 && (
            <ResultView
              title="Transcript Results"
              results={analysisSession.node_map.transcript_results}
              nodes={analysisSession.nodes.transcript_docs!}
              modules={modules}
            />
          )}
          {activeTab === 1 && (
            <ResultView
              title="Additional Context Results"
              results={analysisSession.node_map.additional_context_results}
              nodes={analysisSession.nodes.additional_context_docs!}
              modules={modules}
            />
          )}
          {activeTab === 2 && (
            <ResultView
              title="Previous Note Results"
              results={analysisSession.node_map.previous_note_results}
              nodes={analysisSession.nodes.previous_note_docs!}
              modules={modules}
            />
          )}
        </Box>
        {analysisSession.errors && analysisSession.errors.length > 0 && (
          <Card variant="outlined" sx={{ mt: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Errors
              </Typography>
              <ul>
                {analysisSession.errors.map((error, index) => (
                  <li key={index}>{JSON.stringify(error)}</li>
                ))}
              </ul>
            </CardContent>
          </Card>
        )}
      </>
    );
  };

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Analysis Session Inspector
          </Typography>
          <Button color="inherit" onClick={handlePickerOpen}>
            Select Session
          </Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
        {renderContent()}
      </Container>
      <Modal
        open={isPickerOpen}
        onClose={handlePickerClose}
        aria-labelledby="note-processing-session-picker-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <NoteProcessingSessionPicker onSessionSelect={handleSessionSelect} />
        </Box>
      </Modal>
    </>
  );
};

export default AnalysisSessionInspector;
