import React, { useState, useEffect, useCallback } from "react";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
  GridValueGetter,
} from "@mui/x-data-grid";
import {
  TextField,
  Box,
  InputAdornment,
  IconButton,
  Grid,
  Button,
  useMediaQuery,
  Theme,
} from "@mui/material";
import debounce from "lodash/debounce";
import { Patient } from "../../types/types";
import { useUser } from "../../context/user";
import APIService from "../../services/APIService";
import { ClearIcon } from "@mui/x-date-pickers";
import NewPatientModal from "./NewPatientModal";
import { useUIState } from "../../context/uiState";
import { useNavigate } from "react-router-dom";

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    valueGetter: (value, row) =>
      `${row.first_name || ""} ${row.middle_name || ""} ${row.last_name || ""}`,
    flex: 2,
  },
  { field: "identifier", headerName: "Patient ID", flex: 1 },
  { field: "dob", headerName: "DOB", flex: 1 },
  {
    field: "last_encounter",
    headerName: "Last Encounter",
    flex: 1,
    valueGetter: (value, row) => {
      const latestEncounter = row.latest_encounter;
      if (latestEncounter) {
        return new Date(latestEncounter.start_time).toLocaleString();
      }
    },
  },
];

interface PatientsResponse {
  patients: Patient[];
  total: number;
  page: number;
  perPage: number;
  totalPages: number;
}

const PatientsList: React.FC = () => {
  const { getAccessToken } = useUser();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const navigate = useNavigate();

  const [patients, setPatients] = useState<Patient[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const [totalRows, setTotalRows] = useState(0);

  const [isNewPatientModalOpen, setIsNewPatientModalOpen] = useState(false);

  const handleNewPatientClick = () => {
    setIsNewPatientModalOpen(true);
  };

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/patient/${params.id}`);
  };

  const handleSuccessfulPatientCreation = (patientId: string) => {
    // Refresh the patient list
    fetchPatients(searchTerm, paginationModel.page, paginationModel.pageSize);
    // Optionally, you could do something with the new patientId here
    console.log("New patient created with ID:", patientId);
  };

  const fetchPatients = useCallback(
    async (search: string = "", page: number, pageSize: number) => {
      setLoading(true);
      try {
        const endpoint = search
          ? `/patient/search?q=${encodeURIComponent(search)}&page=${
              page + 1
            }&per_page=${pageSize}`
          : `/patient/list?page=${page + 1}&per_page=${pageSize}`;

        const response = await APIService.makeAPIGetRequest({
          requestString: endpoint,
          accessToken: await getAccessToken(),
        });

        if (response.ok) {
          console.log(response.value);
          const data: PatientsResponse = await response.value;
          setPatients(data.patients);
          setTotalRows(data.total);
        } else {
          throw new Error("Failed to fetch patients");
        }
      } catch (error) {
        console.error("Error fetching patients:", error);
      } finally {
        setLoading(false);
      }
    },
    [getAccessToken]
  );

  const debouncedFetch = useCallback(
    debounce(
      (search: string, page: number, pageSize: number) =>
        fetchPatients(search, page, pageSize),
      300
    ),
    [fetchPatients]
  );

  useEffect(() => {
    fetchPatients("", paginationModel.page, paginationModel.pageSize);
  }, [fetchPatients, paginationModel.page, paginationModel.pageSize]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    debouncedFetch(
      event.target.value,
      paginationModel.page,
      paginationModel.pageSize
    );
  };

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    setPaginationModel(newModel);
    fetchPatients(searchTerm, newModel.page, newModel.pageSize);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    fetchPatients("", paginationModel.page, paginationModel.pageSize);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <TextField
          label="Search Patients"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <IconButton onClick={handleClearSearch} edge="end">
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ flexShrink: 0 }}
          onClick={handleNewPatientClick}
        >
          New Patient
        </Button>
      </Box>
      <DataGrid
        rows={patients}
        columns={columns}
        columnVisibilityModel={{ dob: !isMobile, identifier: !isMobile }}
        loading={loading}
        getRowId={(row) => row.patient_id}
        paginationMode="server"
        rowCount={totalRows}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={[10, 20, 50]}
        onRowClick={handleRowClick}
        sx={{ height: "70vh" }}
      />
      <NewPatientModal
        simplifiedView={isMobile}
        open={isNewPatientModalOpen}
        onClose={() => setIsNewPatientModalOpen(false)}
        onSuccessfulSubmission={handleSuccessfulPatientCreation}
      />
    </Box>
  );
};

export default PatientsList;
