import { FC, ReactNode, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useCardContext } from "./CollapsibleContentCard";

interface BodyProps {
  children: ReactNode;
}

export const Body: FC<BodyProps> = ({ children }) => {
  const { isCollapsed } = useCardContext();
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey((prev) => prev + 1);
  }, [children]);

  return (
    <Box
      sx={{
        maxHeight: isCollapsed ? 0 : "500px",
        transition: "max-height 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
        overflowY: "scroll",
        py: isCollapsed ? 0 : 2,
        px: 2,
      }}
    >
      <Box
        key={key}
        sx={{
          overflowY: "auto",
          pr: 1,
          opacity: isCollapsed ? 0 : 1,
          transform: isCollapsed ? "translateY(-30px)" : "translateY(0)",
          transition: `
            opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1),
            transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)
          `,
          animation: "fadeIn 0.3s ease-out",
          "@keyframes fadeIn": {
            from: {
              opacity: 0,
              transform: "translateY(10px)",
            },
            to: {
              opacity: 1,
              transform: "translateY(0)",
            },
          },
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#bbb",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#999",
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
