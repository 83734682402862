import { AdminNote } from "../../types/types";
import { NoteRow } from "./NoteRow";

interface NotesListProps {
  notesList: AdminNote[];
  handleSortChange?: (field: string) => void;
}

export const NotesList: React.FC<NotesListProps> = ({
  notesList,
  handleSortChange,
}) => {
  const handleSort = (field: string) => {
    if (handleSortChange) {
      handleSortChange(field);
    }
  };

  return (
    <table className="table-auto w-full border-collapse border-2 border-gray-500">
      <thead className="sticky top-0">
        <tr className="bg-blue-200">
          <th
            className="px-4 py-2 border-2 border-gray-400"
            onClick={() => handleSort("note_id")}
          >
            Note ID
          </th>
          <th
            className="px-4 py-2 border-2 border-gray-400"
            onClick={() => handleSort("status")}
          >
            Status
          </th>
          <th
            className="px-4 py-2 border-2 border-gray-400"
            onClick={() => handleSort("user_id")}
          >
            Issuing User
          </th>
          <th
            className="px-4 py-2 border-2 border-gray-400"
            onClick={() => handleSort("time_uploaded")}
          >
            Time Uploaded
          </th>
          <th
            className="px-4 py-2 border-2 border-gray-400"
            onClick={() => handleSort("has_feedback")}
          >
            Feedback?
          </th>
        </tr>
      </thead>
      <tbody>
        {notesList.map((note) => (
          <NoteRow key={note.note_id} note={note} />
        ))}
      </tbody>
    </table>
  );
};
