import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  Paper,
  Typography,
  useTheme,
  Chip,
  Button,
  Box,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridRowId,
  GridToolbar,
  GridValueGetter,
  GridColumnVisibilityModel,
  GridRowModesModel,
  GridRowModes,
  GridActionsCellItem,
  GridEventListener,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

export interface Medication {
  medication_name: string;
  generic_name: string | null;
  formulation: string | null;
  strength: string | null;
  route_of_administration: string | null;
  dosage_amount: string | null;
  frequency: string | null;
  timing: string | null;
  start_date: string | null;
  end_date: string | null;
  indication: string | null;
  isActive: boolean;
  changes?: {
    values_changed: Record<string, { new_value: string; old_value: string }>;
  };
  new?: any;
  old?: any;
}

export interface MedicationChange {
  added: Medication[];
  removed: Medication[];
  changed: Medication[];
  unchanged: Medication[];
}

interface MedicationsDataBlockProps {
  medications?: Medication[];
  medicationChanges: MedicationChange | null;
  onMedicationsUpdate: (
    medications: Medication[],
    action: "accept" | "reject" | "edit"
  ) => void;
}

const MedicationsDataBlock: React.FC<MedicationsDataBlockProps> = ({
  medications = [],
  medicationChanges,
  onMedicationsUpdate,
}) => {
  const theme = useTheme();
  const [filter, setFilter] = useState<"all" | "active" | "inactive">("all");
  const [isDraftMode, setIsDraftMode] = useState(false);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});

  // Set isDraftMode based on whether there are changes
  useEffect(() => {
    setIsDraftMode(
      !!medicationChanges &&
        (medicationChanges.added.length > 0 ||
          medicationChanges.removed.length > 0 ||
          medicationChanges.changed.length > 0)
    );
  }, [medicationChanges]);

  const getRowClassName = useCallback(
    (params: { row: any }) => {
      if (!isDraftMode || !medicationChanges) return "";

      if (params.row.isAdded) return "added-row";
      if (params.row.isChanged) return "changed-row";
      if (
        medicationChanges.removed.some(
          (m) => m.medication_name === params.row.medication_name
        )
      ) {
        return "removed-row";
      }
      return "";
    },
    [medicationChanges, isDraftMode]
  );

  const renderCell = useCallback(
    (params: any) => {
      if (!isDraftMode) {
        return params.field === "start_date" || params.field === "end_date"
          ? formatDate(params.value)
          : params.value;
      }

      const changedMedication = medicationChanges?.changed.find(
        (m) => m.medication_name === params.id
      );

      const isChanged =
        changedMedication?.changes?.values_changed?.[`root['${params.field}']`];

      let displayValue = params.value;

      if (isChanged && changedMedication?.new) {
        displayValue = changedMedication.new[params.field];
      }

      if (params.field === "start_date" || params.field === "end_date") {
        displayValue = formatDate(displayValue);
      }

      return (
        <Tooltip
          title={isChanged ? `Previous value: ${isChanged.old_value}` : ""}
        >
          <div
            style={{
              textDecoration: isChanged ? "underline wavy #1976d2" : "none",
              textUnderlineOffset: "3px",
            }}
          >
            {displayValue}
          </div>
        </Tooltip>
      );
    },
    [medicationChanges, isDraftMode]
  );

  const formatDate = (date: Date | string | null): string => {
    if (!date) return "";
    if (typeof date === "string") return date.split("T")[0];
    return date.toISOString().split("T")[0];
  };

  const handleRowEditStop = useCallback<GridEventListener<"rowEditStop">>(
    (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    },
    []
  );

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const updatedMedications = medications.filter(
      (med) => med.medication_name !== id
    );
    onMedicationsUpdate(updatedMedications, "edit");
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = medications.find((med) => med.medication_name === id);
    if (editedRow && (editedRow as any).isNew) {
      const updatedMedications = medications.filter(
        (med) => med.medication_name !== id
      );
      onMedicationsUpdate(updatedMedications, "edit");
    }
  };

  const processRowUpdate = useCallback(
    (newRow: GridRowModel) => {
      const updatedRow = { ...newRow, isNew: false } as unknown as Medication;
      const updatedMedications = medications.map((med) =>
        med.medication_name === newRow.id ? updatedRow : med
      );
      onMedicationsUpdate(updatedMedications, "edit");
      return updatedRow;
    },
    [medications, onMedicationsUpdate]
  );

  const handleRowModesModelChange = useCallback(
    (newRowModesModel: GridRowModesModel) => {
      setRowModesModel(newRowModesModel);
    },
    []
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "medication_name",
        headerName: "Medication",
        flex: 1,
        editable: true,
        renderCell,
      },
      {
        field: "generic_name",
        headerName: "Generic Name",
        flex: 1,
        editable: true,
        renderCell,
      },
      {
        field: "formulation",
        headerName: "Formulation",
        flex: 1,
        editable: true,
        renderCell,
      },
      {
        field: "strength",
        headerName: "Strength",
        flex: 1,
        editable: true,
        renderCell,
      },
      {
        field: "route_of_administration",
        headerName: "Route",
        flex: 1,
        editable: true,
        renderCell,
      },
      {
        field: "dosage_amount",
        headerName: "Dosage",
        flex: 1,
        editable: true,
        renderCell,
      },
      {
        field: "frequency",
        headerName: "Frequency",
        flex: 1,
        editable: true,
        renderCell,
      },
      {
        field: "timing",
        headerName: "Timing",
        flex: 1,
        editable: true,
        renderCell,
      },
      {
        field: "start_date",
        headerName: "Start Date",
        flex: 1,
        editable: true,
        type: "date",
        valueGetter: (_, row) => {
          const value = row.start_date;
          return value ? new Date(value) : null;
        },
        valueFormatter: (_, row) => formatDate(row.start_date),
        renderCell,
      },
      {
        field: "end_date",
        headerName: "End Date",
        flex: 1,
        editable: true,
        type: "date",
        valueGetter: (_, row) => {
          const value = row.end_date;
          return value ? new Date(value) : null;
        },
        valueFormatter: (_, row) => formatDate(row.end_date),
        renderCell,
      },
      {
        field: "indication",
        headerName: "Indication",
        flex: 2,
        editable: true,
        renderCell,
      },
      {
        field: "isActive",
        headerName: "Status",
        flex: 1,
        editable: true,
        type: "boolean",
        renderCell: (params) => {
          const isChanged =
            isDraftMode &&
            medicationChanges?.changed.some(
              (m) =>
                m.medication_name === params.id &&
                m.changes?.values_changed?.[`root['isActive']`]
            );

          const changedMedication = medicationChanges?.changed.find(
            (m) => m.medication_name === params.id
          );

          const displayValue = isChanged
            ? changedMedication?.new.isActive
            : params.value;

          const chip = (
            <Chip
              label={displayValue ? "Active" : "Inactive"}
              color={displayValue ? "primary" : "default"}
              size="small"
            />
          );

          if (isChanged) {
            const oldValue =
              changedMedication?.changes?.values_changed[`root['isActive']`]
                .old_value;
            return (
              <Tooltip
                title={`Previous value: ${oldValue ? "Active" : "Inactive"}`}
              >
                <Box
                  sx={{
                    position: "relative",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      left: 0,
                      right: 0,
                      bottom: "-3px",
                      height: "2px",
                      background:
                        "repeating-linear-gradient(to right, #1976d2 0, #1976d2 4px, transparent 4px, transparent 8px)",
                    },
                  }}
                >
                  {chip}
                </Box>
              </Tooltip>
            );
          }

          return chip;
        },
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 100,
        cellClassName: "actions",
        getActions: ({ id }) => {
          // Only show actions when not in draft mode
          if (isDraftMode) return [];

          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{ color: "primary.main" }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      },
    ],
    [
      renderCell,
      handleEditClick,
      handleSaveClick,
      handleDeleteClick,
      handleCancelClick,
      medicationChanges,
      isDraftMode,
    ]
  );

  const rows = useMemo(() => {
    if (!medicationChanges) {
      return medications.map((med) => ({ ...med, id: med.medication_name }));
    }

    const allMedications = medications.map((med) => {
      const changedMed = medicationChanges.changed.find(
        (m) => m.medication_name === med.medication_name
      );
      if (changedMed) {
        return {
          ...med,
          ...changedMed.new,
          id: med.medication_name,
          isChanged: true,
        };
      }
      return { ...med, id: med.medication_name };
    });

    // Add new medications
    medicationChanges.added.forEach((med) => {
      allMedications.push({
        ...med,
        id: med.medication_name,
        isAdded: true,
      });
    });

    // Filter out removed medications
    return allMedications.filter(
      (med) =>
        !medicationChanges.removed.some(
          (r) => r.medication_name === med.medication_name
        )
    );
  }, [medications, medicationChanges]);

  const filteredRows = useMemo(() => {
    switch (filter) {
      case "active":
        return rows.filter((row) => row.isActive);
      case "inactive":
        return rows.filter((row) => !row.isActive);
      default:
        return rows;
    }
  }, [rows, filter]);

  const handleAcceptChanges = () => {
    if (!medicationChanges) return;

    const updatedMedications = [
      ...medications
        .filter(
          (med) =>
            !medicationChanges.removed.some(
              (r) => r.medication_name === med.medication_name
            )
        )
        .map((med) => {
          const changedMed = medicationChanges.changed.find(
            (c) => c.medication_name === med.medication_name
          );
          return changedMed ? { ...med, ...changedMed.new } : med;
        }),
      ...medicationChanges.added,
      ...medicationChanges.changed
        .filter(
          (changedMed) =>
            !medications.some(
              (med) => med.medication_name === changedMed.medication_name
            )
        )
        .map((changedMed) => changedMed.new),
    ];

    onMedicationsUpdate(updatedMedications, "accept");
    setIsDraftMode(false);
  };

  const handleRejectChanges = () => {
    onMedicationsUpdate(medications, "reject");
    setIsDraftMode(false);
  };

  const handleFilterChange = (
    event: SelectChangeEvent<"all" | "active" | "inactive">
  ) => {
    setFilter(event.target.value as "all" | "active" | "inactive");
  };

  const handleAddRow = () => {
    const newMedication: Medication = {
      medication_name: `New Medication ${medications.length + 1}`,
      generic_name: null,
      formulation: null,
      strength: null,
      route_of_administration: null,
      dosage_amount: null,
      frequency: null,
      timing: null,
      start_date: null,
      end_date: null,
      indication: null,
      isActive: true,
    };
    onMedicationsUpdate([...medications, newMedication], "edit");
  };

  const initialColumnVisibilityModel = useMemo(() => {
    const visibilityModel: GridColumnVisibilityModel = {};
    columns.forEach((column) => {
      if (column.field === "actions") {
        visibilityModel[column.field] = true;
      } else {
        const hasValue = rows.some((row) => {
          const value = row[column.field as keyof typeof row];
          return value !== null && value !== undefined && value !== "";
        });
        visibilityModel[column.field] = hasValue;
      }
    });
    return visibilityModel;
  }, []);

  useEffect(() => {
    setColumnVisibilityModel(initialColumnVisibilityModel);
  }, [initialColumnVisibilityModel]);

  const handleColumnVisibilityModelChange = useCallback(
    (newModel: GridColumnVisibilityModel) => {
      setColumnVisibilityModel(newModel);
    },
    []
  );

  return (
    <Paper
      elevation={1}
      sx={{
        p: 2,
        mb: 2,
        backgroundColor: theme.palette.backgroundColors?.secondary,
        width: "100%",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h6">
          Medications
          {isDraftMode && (
            <Chip label="Draft" color="warning" size="small" sx={{ ml: 1 }} />
          )}
        </Typography>
        <Box display="flex" alignItems="center">
          <Select
            value={filter}
            onChange={handleFilterChange}
            size="small"
            sx={{ minWidth: 120, mr: 2 }}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </Select>
          {!isDraftMode && ( // Only show Add Medication button when not in draft mode
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddRow}
            >
              Add Medication
            </Button>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.background.default,
        }}
      >
        <DataGrid
          key={filteredRows.length}
          rows={filteredRows}
          columns={columns}
          autoHeight
          disableRowSelectionOnClick
          getRowClassName={getRowClassName}
          processRowUpdate={processRowUpdate}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          hideFooter
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          isRowSelectable={() => !isDraftMode} // Disable row selection in draft mode
          isCellEditable={() => !isDraftMode} // Disable cell editing in draft mode
          sx={{
            "& .added-row": {
              backgroundColor: theme.palette.success.light + "80",
            },
            "& .removed-row": {
              backgroundColor: theme.palette.error.light + "80",
              opacity: 0.5,
            },
            "& .changed-row": {
              backgroundColor: theme.palette.warning.light + "80",
            },
            "& .MuiDataGrid-row:hover": {
              "&.added-row": {
                backgroundColor: `${theme.palette.success.light}CC`,
              },
              "&.removed-row": {
                backgroundColor: `${theme.palette.error.light}CC`,
                opacity: 0.7,
              },
              "&.changed-row": {
                backgroundColor: `${theme.palette.warning.light}CC`,
              },
            },
            "& .MuiDataGrid-root": {
              overflowX: "auto",
            },
            "& .MuiDataGrid-cell": {
              flex: 1,
            },
            "& .MuiDataGrid-columnHeader": {
              flex: 1,
            },
          }}
        />
      </Box>
      {isDraftMode && medicationChanges && (
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="inherit"
            onClick={handleRejectChanges}
            sx={{ mr: 1, backgroundColor: "grey.300" }}
          >
            Reject Changes
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAcceptChanges}
          >
            Accept Changes
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default MedicationsDataBlock;
