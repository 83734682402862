import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  CircularProgress,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Tooltip,
  styled,
} from "@mui/material";
import { ProviderBillingInfo } from "../../types/types";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";
import InfoIcon from "@mui/icons-material/Info";

// Define required fields for eligibility and billing
const requiredEligibilityFields = ["npi_number", "tax_id"];
const requiredBillingFields = [
  "first_name",
  "last_name",
  "facility_address",
  // "facility_address2",
  "city",
  "state",
  "zip",
  "phone",
  "tax_id",
  "accept_insurance_payment_as_full",
  "tax_id_type",
  "place_of_service",
];

// Function to check if a field is required for eligibility or billing
const isFieldRequired = (field: string) => {
  if (requiredEligibilityFields.includes(field)) return "*"; // Eligibility field
  if (requiredBillingFields.includes(field)) return "**"; // Billing/claims field
  return "";
};

interface ProviderInformationViewProps {
  highlightedFields?: string[];
  onProviderSave?: () => void;
}

const HighlightedTextField = styled(TextField)<{ ishighlighted: string }>(
  ({ theme, ishighlighted }) => ({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor:
          ishighlighted === "true" ? theme.palette.primary.main : "inherit",
        borderWidth: 2,
      },
    },
  })
);

const ProviderInformationView: React.FC<ProviderInformationViewProps> = ({
  highlightedFields = [],
  onProviderSave,
}) => {
  const [loading, setLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });

  const [providerInfo, setProviderInfo] = useState<ProviderBillingInfo>({
    first_name: "",
    last_name: "",
    npi_number: "",
    billing_npi_number: "",
    facility_address: "",
    facility_address2: "",
    city: "",
    state: "",
    tax_id: "",
    zip: "",
    phone: "",
    tax_id_type: "",
    accept_insurance_payment_as_full: "",
    place_of_service: "",
  });

  const { getAccessToken } = useUser();

  useEffect(() => {
    fetchProviderInfo();
  }, [getAccessToken]);

  const fetchProviderInfo = async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/user/getProviderBillingInfo`,
        accessToken,
      });

      console.log("Provider Info Response:", response);
      if (response.ok) {
        const data = response.value.provider_billing_info || {};
        setProviderInfo((prev) => ({
          ...prev, // Ensure all fields are maintained even if some are missing from the response
          ...data,
        }));
      } else {
        throw new Error("Failed to fetch provider information");
      }
    } catch (error) {
      console.error("Error fetching provider information:", error);
      setSnackbar({
        open: true,
        message: "Failed to load provider data",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target as
      | HTMLInputElement
      | { name: string; value: string };
    setProviderInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
    setHasChanges(true);
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setProviderInfo((prev) => ({
      ...prev,
      [name]: value as string,
    }));
    setHasChanges(true);
  };

  const handleSave = async () => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/user/updateProviderBillingInfo",
        accessToken,
        body: {
          updated_fields: providerInfo,
        },
      });

      if (response.ok) {
        setSnackbar({
          open: true,
          message: "Provider information updated successfully",
          severity: "success",
        });
        setHasChanges(false);
        if (onProviderSave) onProviderSave();
      } else {
        throw new Error("Failed to update provider information");
      }
    } catch (error) {
      console.error("Error updating provider information:", error);
      setSnackbar({
        open: true,
        message: "Failed to update provider information",
        severity: "error",
      });
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper elevation={1} sx={{ p: 3, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        Provider Information
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {[
          { label: "First Name", name: "first_name", type: "text" },
          { label: "Last Name", name: "last_name", type: "text" },
          { label: "Rendering NPI Number", name: "npi_number", type: "number" },
          {
            label: "Billing NPI Number",
            name: "billing_npi_number",
            type: "number",
          },
          { label: "Facility Address", name: "facility_address", type: "text" },
          {
            label: "Facility Address 2",
            name: "facility_address2",
            type: "text",
          },
          { label: "City", name: "city", type: "text" },
          { label: "State", name: "state", type: "text" },
          { label: "Tax ID", name: "tax_id", type: "text" },
          { label: "Zip", name: "zip", type: "text" },
          { label: "Phone", name: "phone", type: "text" },
        ].map((field) => (
          <Box key={field.name} sx={{ flexGrow: 1, minWidth: "150px" }}>
            <Box
              sx={{ height: "1.5em", display: "flex", alignItems: "center" }}
            >
              <Typography variant="caption" color="red">
                {isFieldRequired(field.name)}
              </Typography>
            </Box>
            <HighlightedTextField
              label={field.label}
              name={field.name}
              value={providerInfo[field.name as keyof ProviderBillingInfo]}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
              fullWidth
              ishighlighted={highlightedFields.includes(field.name).toString()}
            />
          </Box>
        ))}

        {/* Tax ID Type */}
        <Box sx={{ flexGrow: 1, minWidth: "150px" }}>
          <Typography variant="caption" color="red">
            {isFieldRequired("tax_id_type")}
          </Typography>
          <FormControl
            fullWidth
            sx={{
              ...(highlightedFields.includes("tax_id_type") && {
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "primary.main",
                    borderWidth: 2,
                  },
                },
              }),
            }}
          >
            <InputLabel id="tax-id-type-label">Tax ID Type</InputLabel>
            <Select
              labelId="tax-id-type-label"
              label="Tax ID Type"
              name="tax_id_type"
              value={providerInfo.tax_id_type}
              onChange={handleSelectChange}
              variant="outlined"
              size="small"
            >
              <MenuItem value="EIN">EIN</MenuItem>
              <MenuItem value="SSN">SSN</MenuItem>
            </Select>
          </FormControl>
          <Tooltip
            title="EIN (Employer Identification Number) is for businesses, while SSN (Social Security Number) is for individuals."
            placement="top"
          >
            <InfoIcon sx={{ fontSize: 20, color: "action.active" }} />
          </Tooltip>
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {/* Accept Insurance Payment as Full */}
        <Box sx={{ flexGrow: 1, minWidth: "150px" }}>
          <Typography variant="caption" color="red">
            {isFieldRequired("accept_insurance_payment_as_full")}
          </Typography>
          <FormControl
            fullWidth
            sx={{
              ...(highlightedFields.includes(
                "accept_insurance_payment_as_full"
              ) && {
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "primary.main",
                    borderWidth: 2,
                  },
                },
              }),
            }}
          >
            <InputLabel id="accept-insurance-payment-label">
              Accept Insurance Payment as Full
            </InputLabel>
            <Select
              labelId="accept-insurance-payment-label"
              label="Accept Insurance Payment as Full"
              name="accept_insurance_payment_as_full"
              value={providerInfo.accept_insurance_payment_as_full}
              onChange={handleSelectChange}
              variant="outlined"
              size="small"
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
          <Tooltip
            title="Indicate whether you accept the insurance company's approved amount (also known as the allowed amount) as full payment for services provided."
            placement="top"
          >
            <InfoIcon sx={{ fontSize: 20, color: "action.active" }} />
          </Tooltip>
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {/* Accept Insurance Payment as Full */}
        <Box sx={{ flexGrow: 1, minWidth: "150px" }}>
          <Typography variant="caption" color="red">
            {isFieldRequired("place_of_service")}
          </Typography>
          <FormControl
            fullWidth
            sx={{
              ...(highlightedFields.includes(
                "accept_insurance_payment_as_full"
              ) && {
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "primary.main",
                    borderWidth: 2,
                  },
                },
              }),
            }}
          >
            <InputLabel id="place-of-service-label">
              Place of Service
            </InputLabel>
            <Select
              labelId="place-of-service-label"
              label="Place of Service"
              name="place_of_service"
              value={providerInfo.place_of_service}
              onChange={handleSelectChange}
              variant="outlined"
              size="small"
            >
              <MenuItem value="11">Office</MenuItem>
              <MenuItem value="02">
                Telehealth Provided Other than in Patient’s Home
              </MenuItem>
              <MenuItem value="10">
                Telehealth Provided in Patient’s Home
              </MenuItem>
              <MenuItem value="12">Home</MenuItem>
              <MenuItem value="22">Outpatient Hospital</MenuItem>
              <MenuItem value="53">
                Community Mental Health Center (CMHC)
              </MenuItem>
              <MenuItem value="31">Skilled Nursing Facility</MenuItem>
            </Select>
          </FormControl>
          <Tooltip
            title="Most common place of service is 11 (Office)"
            placement="top"
          >
            <InfoIcon sx={{ fontSize: 20, color: "action.active" }} />
          </Tooltip>
        </Box>
      </Box>

      {/* Explanation for asterisks */}
      <Typography variant="body2" sx={{ mt: 2 }}>
        <span style={{ color: "red" }}>*</span> Required for eligibility |{" "}
        <span style={{ color: "red" }}>**</span> Required for claims
      </Typography>

      {hasChanges && (
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Box>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default ProviderInformationView;