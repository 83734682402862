import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useUser } from "../context/user";
import APIService from "../services/APIService";
import { ErrorPage } from "./Shared/ErrorPage";

export type NoteStatusType = {
  note_id: string;
  job_status: "completed" | "processing" | "errored";
  note_title: string;
  time_uploaded: number;
  processing_tasks: {
    name: string;
    status: string;
    progress?: number;
  }[];
  errors: {
    error: string;
  }[];
  error_message: string;
};

export const NoteProcessingView = () => {
  const { getAccessToken } = useUser();
  const { noteId } = useParams();
  const navigate = useNavigate();
  const [processingStatus, setProcessingStatus] = useState<NoteStatusType>();
  const [errorMessage, setErrorMessage] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [currentTask, setCurrentTask] = useState("");
  const [isError, setIsError] = useState(false);

  const updateProcessingStatus = (data: NoteStatusType) => {
    const transcriptionTask = data.processing_tasks.find(
      (task) => task.name === "transcription"
    );
    const transcriptionProgress = transcriptionTask
      ? (transcriptionTask.progress as number)
      : 0;

    // Cap the progress at 95% when transcription is complete
    const cappedProgress = Math.min(transcriptionProgress * 0.95, 95);
    setPercentage(cappedProgress);

    const nextTask = data.processing_tasks.find(
      (task) => task.status !== "completed"
    );
    setCurrentTask(nextTask ? nextTask.name : "");

    // Check if analysis is completed and navigate to the note
    const analysisTask = data.processing_tasks.find(
      (task) => task.name === "analysis"
    );
    if (analysisTask && analysisTask.status === "completed") {
      navigate(`/note/${noteId}`);
    }
  };

  const fetchNoteProcessingStatus = async () => {
    console.log("fetching note status");
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIGetRequest({
      requestString: `/noteProcessingSession/status/${noteId}`,
      accessToken: accessToken,
    });

    if (response.ok) {
      const data = response.value as NoteStatusType;
      if (data.job_status === "completed") {
        navigate(`/note/${noteId}`);
      } else if (data.job_status === "errored") {
        setIsError(true);
        if (data.error_message === "NO_SPOKEN_WORDS") {
          setErrorMessage(
            "No spoken words detected in audio. Please check your microphone settings before your next recording."
          );
        } else if (data.error_message === "ANALYSIS_ERROR") {
          setErrorMessage("An unexpected error encountered.");
        }
      }

      // update note processing status info
      setProcessingStatus(data);
      updateProcessingStatus(data);
    }
  };

  useEffect(() => {
    fetchNoteProcessingStatus();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isError) {
        fetchNoteProcessingStatus();
      } else {
        clearInterval(intervalId);
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [isError]);

  return (
    <div>
      {processingStatus && (
        <div
          className={
            "flex flex-col items-center mx-auto " +
            (!isError ? "h-[50vh] w-[50vw]" : "h-full w-[65vw]")
          }
        >
          <div className="flex items-center justify-center p-4">
            <div className="flex-8 text-center">
              <h1 className="text-2xl font-bold mb-2">
                {processingStatus.note_title}
              </h1>
            </div>
          </div>
          {!isError ? (
            <div className="flex-1 flex items-center justify-center">
              <div className="p-8 bg-neutral1 border-2 border-neutral2 border-dashed rounded-lg flex flex-col items-center justify-center h-full w-full">
                <div className="w-48 h-48 flex items-center justify-center">
                  <CircularProgressbar
                    value={percentage}
                    text={`${Math.round(percentage)}%`}
                    styles={buildStyles({
                      strokeLinecap: "round",
                      pathColor: "#3b5998",
                      textColor: "black",
                      trailColor: "#e6e6e6",
                    })}
                  />
                </div>
                <p className="mt-8 text-lg font-bold capitalize">
                  {currentTask === "transcription"
                    ? "Transcribing..."
                    : currentTask === "analysis"
                    ? "Analyzing transcript..."
                    : `Generating ${currentTask}...`}
                </p>
              </div>
            </div>
          ) : (
            <>
              <div className="flex-1 flex items-center justify-center">
                <div className="p-8 bg-neutral1 border-2 border-neutral2 border-dashed rounded-lg flex flex-col items-center justify-center h-full w-full">
                  <div className="w-48 h-48 flex items-center justify-center">
                    <CircularProgressbar
                      value={percentage}
                      text={processingStatus?.job_status}
                      styles={buildStyles({
                        strokeLinecap: "round",
                        pathColor: "#b91c1b",
                        textColor: "black",
                        trailColor: "#e6e6e6",
                      })}
                    />
                  </div>
                </div>
              </div>
              <ErrorPage
                errors={processingStatus?.errors}
                error_message={
                  "There was an error processing your note. " + errorMessage
                }
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};
