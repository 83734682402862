import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Paper } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import APIService from "../services/APIService";
import { useUser } from "../context/user";
import { AnimatedCheckmark } from "../components/SVGIcon";
import { trackEvent } from "../utils/analytics_utils";

const CheckoutSuccess: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [sessionData, setSessionData] = useState<any>(null);

  const { getAccessToken } = useUser();

  useEffect(() => {
    const fetchSessionData = async () => {
      const searchParams = new URLSearchParams(location.search);
      const sessionId = searchParams.get("session_id");

      if (sessionId) {
        try {
          const accessToken = await getAccessToken();
          const response = await APIService.makeAPIGetRequest({
            requestString: `/user/getCheckoutSession?session_id=${sessionId}`,
            accessToken: accessToken,
          });

          if (response.ok) {
            setSessionData(response.value.session);
            trackEvent({
              event: "WebApp_CheckoutSuccess",
              totalAmount: response.value.session.amount_total / 100,
            });
          } else {
            console.error(
              "Failed to retrieve checkout session data:",
              response
            );
          }
        } catch (error) {
          console.error("Error fetching checkout session data:", error);
        }
      }
    };

    fetchSessionData();
  }, [location.search, getAccessToken]);

  const handleGoToDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <Box sx={{ backgroundColor: "#f4f6f8", minHeight: "100vh", pt: 4 }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
      >
        <Paper
          sx={{
            p: 4,
            width: "500px",
            borderRadius: "10px",
            boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
          }}
        >
          <AnimatedCheckmark />
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontWeight: "bold", color: "#3f51b5" }}
          >
            Checkout Successful!
          </Typography>
          <Typography variant="body1" sx={{ mb: 4, color: "textSecondary" }}>
            Thank you for your purchase. Your transaction has been completed
            successfully.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleGoToDashboard}
            sx={{
              textTransform: "none",
              borderRadius: "25px",
              px: 4,
              minWidth: "200px",
            }}
          >
            Go to Dashboard
          </Button>
        </Paper>
      </Box>
    </Box>
  );
};

export default CheckoutSuccess;
