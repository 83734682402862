import React, { useState, useEffect, useCallback } from "react";
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
  TablePagination,
  Skeleton,
} from "@mui/material";
import { Encounter } from "../../types/types";
import { useUser } from "../../context/user";
import APIService, { API_BASE_URL } from "../../services/APIService";
import { formatDateString } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import FullScreenLoader from "../FullScreenLoader";

interface PatientEncountersBlockProps {
  patientId: string;
}

const PatientEncountersBlock: React.FC<PatientEncountersBlockProps> = ({
  patientId,
}) => {
  const { getAccessToken } = useUser();
  const navigate = useNavigate();
  const theme = useTheme();

  const [encounters, setEncounters] = useState<Encounter[]>([]);
  const [loading, setLoading] = useState(true);
  const [noteLoading, setNoteLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [encounterBillingCodes, setEncounterBillingCodes] = useState<{
    [key: string]: {
      billingCode: string | null;
      billingClaimStatus: string | null;
    };
  }>({});

  const fetchEncounters = useCallback(
    async (page: number, rowsPerPage: number) => {
      setLoading(true);
      try {
        const accessToken = await getAccessToken();
        const response = await APIService.makeAPIGetRequest({
          requestString: `/encounter/list?patient_id=${patientId}&page=${
            page + 1
          }&per_page=${rowsPerPage}`,
          accessToken,
        });

        if (response.ok) {
          const data = await response.value;
          setEncounters(data.encounters);
          setTotalRows(data.total);
        } else {
          throw new Error("Failed to fetch encounters");
        }
      } catch (error) {
        console.error("Error fetching encounters:", error);
      } finally {
        setLoading(false);
      }
    },
    [getAccessToken, patientId]
  );

  useEffect(() => {
    fetchEncounters(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (noteId: string) => {
    setNoteLoading(true);
    navigate(`/note/${noteId}`);
  };

  const fetchBillingCode = async (noteId: string) => {
    const accessToken = await getAccessToken();

    try {
      const response = await fetch(`${API_BASE_URL}/notes/${noteId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch billing code");
      }

      const data = await response.json();
      const extras = data.content?.extras;
      console.log(extras);
      if (!extras || extras.length < 1) {
        return { billingCode: null, billingClaimStatus: null };
      }

      return {
        billingCode: extras[0]?.data?.billing_code?.top_code || null,
        billingClaimStatus: extras[1]?.status || null,
      };
    } catch (error) {
      console.error("Error fetching billing code:", error);
      return { billingCode: null, billingClaimStatus: null };
    }
  };

  useEffect(() => {
    const getBillingCodes = async () => {
      const newBillingCodes: {
        [key: string]: {
          billingCode: string | null;
          billingClaimStatus: string | null;
        };
      } = {};
      for (const encounter of encounters) {
        const result = await fetchBillingCode(encounter.note_id);
        newBillingCodes[encounter.encounter_id] = {
          billingCode: result?.billingCode || null,
          billingClaimStatus:
            result?.billingClaimStatus === "completed"
              ? "Submitted"
              : "Ready to submit",
        };
      }
      setEncounterBillingCodes(newBillingCodes);
      console.log(newBillingCodes);
    };

    if (encounters.length > 0) {
      getBillingCodes();
    }
  }, [encounters]);

  return (
    <>
      <Paper
        elevation={1}
        sx={{
          p: 2,
          mb: 2,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Patient Encounters
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date/Time</TableCell>
              <TableCell>Encounter Type</TableCell>
              <TableCell>Billing CPT Code</TableCell>
              <TableCell>Billing Claim Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? Array.from(new Array(rowsPerPage)).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" />
                    </TableCell>
                  </TableRow>
                ))
              : encounters.map((encounter) => (
                  <TableRow
                    key={encounter.encounter_id}
                    hover
                    onClick={() => handleRowClick(encounter.note_id)}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell>
                      {formatDateString(encounter.start_time)}
                    </TableCell>
                    <TableCell>{encounter.encounter_type}</TableCell>
                    <TableCell>
                      {encounterBillingCodes[encounter.encounter_id] ===
                      undefined
                        ? "Loading..."
                        : encounterBillingCodes[encounter.encounter_id] === null
                        ? "N/A"
                        : encounterBillingCodes[encounter.encounter_id]
                            .billingCode}
                    </TableCell>
                    <TableCell>
                      {encounterBillingCodes[encounter.encounter_id] ===
                      undefined
                        ? "Loading..."
                        : encounterBillingCodes[encounter.encounter_id] === null
                        ? "N/A"
                        : encounterBillingCodes[encounter.encounter_id]
                            .billingClaimStatus}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalRows}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </Paper>
      {noteLoading && <FullScreenLoader />}
    </>
  );
};

export default React.memo(PatientEncountersBlock);
